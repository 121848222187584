import React from 'react';
import classnames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

export default function ContactAddress({ contact }) {
  const classes = useStyles();

  return (
    <div className={classnames('contact-card', classes.root)}>
      <div className={classnames('description', classes.selected)}>{contact.description}</div>
      {contact.email && <span className={classnames('email', classes.subtitle)}>{contact.email}</span>}
      <address className={classes.address}>{contact.address_1}</address>
      {contact.address_2 && <address className={classes.address}>{contact.address_2} </address>}
      {contact.city && (contact.state || contact.postal_code) && (
        <div className={classnames('city', classes.address)}>
          {contact.city}, {contact.state} {contact.postal_code}
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles({
  root: { fontWeight: 'normal !important' },

  selected: {
    fontSize: '.8em',
  },

  subtitle: {
    color: '#0C2D48',
    fontSize: '1em',
    paddingLeft: '1.2rem',
  },

  address: {
    paddingLeft: '1.2rem',
    lineHeight: '1.7rem',
  },
});
