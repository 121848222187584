import React from 'react';

import { Grid, List } from '@material-ui/core';
import ProductListItem from 'components/ProductListItem';

import { makeStyles } from '@material-ui/core/styles';

export default function DeletedProductsList({ order_items, currencyCodeISO }) {
  const classes = useStyles();

  return (
    <Grid item container xs={12} className={classes.deletedSection}>
      <Grid item xs={12}>
        <legend className="title">awaiting deletion</legend>
      </Grid>
      <List classes={{ root: classes.list }}>
        {order_items.map((order_item) => (
          <ProductListItem
            order_item={order_item}
            key={order_item.id}
            currencyCodeISO={currencyCodeISO}
          />
        ))}
      </List>
    </Grid>
  );
}

const useStyles = makeStyles({
  deletedSection: {
    background: '#ffdede',
    borderRadius: '.7rem',

    '& .title': {
      color: '#8d3636',
      fontWeight: 'bold',
      padding: '.6rem',
      paddingRight: '10px',
    },

    // fontSize: '.8rem',
  },

  list: {
    paddingLeft: '10px',
  },
});
