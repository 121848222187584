import React, { useReducer, useMemo } from 'react';
import _ from 'lodash';
import { blankOrder } from 'lib/blank_factories';

import valid from 'card-validator';

// TODO: expose the collected errors to the interface in another iteration
function validateCard(card) {
  let errors = [];
  let validNumber = valid.number(card.number);
  if (!validNumber.isValid) {
    errors.push('number');
  }

  if (!valid.expirationMonth(`${card.exp_month}`).isValid) {
    errors.push('expiration month');
  }

  if (!valid.expirationYear(`${card.exp_year}`).isValid) {
    errors.push('expiration year');
  }

  if (!valid.cvv(card.cvc).isValid) {
    errors.push('cvv');
  }

  if (errors.length) {
    let error = new Error('Invalid card details');
    error.data = { validation_errors: errors };
    throw error;
  }

  return true;
}

function readyToCapture(state) {
  let { card_validation } = state;
  return {
    ...state,
    funding_state:
      card_validation.isValid && !_.isEmpty(state.payment_group)
        ? 'awaiting-capture'
        : 'awaiting-funding-details',
  };
}

function mergeContact(type, state, contact) {
  return {
    ...state,
    order: {
      ...state.order,
      [`${type}_contact`]: _.omitBy(contact, _.isNil),
      [`${type}_contact_id`]: contact.id,
    },
  };
}

// FIXME: this is getting large. Need to break it up when we get breathing room
function reducer(state, action) {
  let order;
  let order_item;
  let order_items;

  switch (action.type) {
    // TODO: Normalize all these actions to use a capitalized form.
    case 'SET-CONTACTS':
      return { ...state, contacts: action.contacts };

    case 'SAVE-CONTACT':
      return {
        ...state,
        [`display_${action.contact_type}_modal`]: false,
        display_billing_modal: false,
        display_shipping_modal: false,
        order: {
          ...state.order,
          [`${action.contact_type}_contact`]: action.contact,
          customer: {
            ...state.order.customer,
            contacts: [
              ..._.filter(state.order.customer.contacts, (c) => c.id !== action.contact),
              action.contact,
            ],
          },
        },
      };

    case 'SET-CUSTOMER':
      return {
        ...state,
        order: { ...state.order, customer: action.customer },
        contacts: action.customer.contacts ?? [],
      };

    case 'SET-ORDER':
      return { ...state, order: action.order };

    case 'SET-ORDER-CURRENCY':
      order = { ...state.order, currency_iso: action.currency_iso };
      // REVIEW@order-items: If the currency changes, we need to clear the
      // existing order items because they are linked to catalog items that
      // may no longer be available in the new currency.
      order.order_items = [];
      return { ...state, order };

    case 'ADD-ORDER-ITEM':
      order = { ...state.order };
      order.order_items = [...order.order_items, action.order_item];
      return { ...state, order };

    case 'REMOVE-ORDER-ITEM':
      order = { ...state.order };
      order_item = { ...action.order_item };
      order_items = order.order_items.filter((oi) => oi != action.order_item);
      if (order_item.id) {
        order_item.action = 'remove';
        order.order_items = order_items.concat(order_item);
      } else {
        order.order_items = order_items;
      }

      return { ...state, order };

    // NOTE: used to toggle funding attachment on order items during removal on the EditOrder screen
    case 'SET-DETACH-FUNDING':
      order = { ...state.order };
      order_item = { ...action.order_item };
      order_items = order.order_items.filter((oi) => oi != action.order_item);
      order.order_items = order_items.concat(order_item);

      if (order_item?.meta?.funding_detached) {
        delete order_item.meta.funding_detached;
      } else {
        order_item.meta.funding_detached = !order_item?.meta?.funding_detached;
      }

      return { ...state, order };

    case 'SET-ORDER-ITEM-QUANTITY':
      order = { ...state.order };
      order_items = [...order.order_items];
      order_item = _.find(order_items, (oi) => oi === action.order_item);
      order_items = _.difference(order_items, [order_item]);
      order_item = { ...order_item, quantity: +action.quantity };
      if (order_item.id) {
        order_item.action = 'patch';
        order_item.meta = { ...order_item.meta, quantity_changed: true };
      }
      order.order_items = [...order_items, order_item];

      return { ...state, order };

    case 'SET-ORDER-ITEM-PRICE':
      order = { ...state.order };
      order_items = [...order.order_items];
      order_item = _.find(order_items, (oi) => oi === action.order_item);
      order_items = _.difference(order_items, [order_item]);
      order_item = { ...order_item, price: +action.price };
      if (order_item.id) {
        order_item.action = 'patch';
        order_item.meta = { ...order_item.meta, price_changed: true };
      }
      order.order_items = [...order_items, order_item];

      return { ...state, order };

    // REVIEW: think about making this a child reducer
    case 'open-shipping-modal':
      return { ...state, display_shipping_modal: true };
    case 'open-billing-modal':
      return { ...state, display_billing_modal: true };

    case 'close-shipping-modal':
      return { ...state, display_shipping_modal: false };

    case 'close-billing-modal':
      return { ...state, display_billing_modal: false };

    case 'SET-BILLING-CONTACT':
      return mergeContact('billing', state, action.contact);

    case 'SET-SHIPPING-CONTACT':
      return mergeContact('shipping', state, action.contact);

    // Used on OrdersListPage typically
    case 'SET-ORDERS':
      return {
        ...state,
        orders: action.orders,
      };

    case 'CLOSE-REFUND-MODAL':
      return { ...state, display_refund_modal: false };

    case 'OPEN-REFUND-MODAL':
      return {
        ...state,
        display_refund_modal: true,
        default_strategy: action.default_strategy,
        initialValues: action.initialValues,
      };

    case 'BEGIN-CHARGEBACK-SUBMISSION':
      return {
        ...state,
        chargeback_state: 'user-input',
        chargebackTransaction: action.chargebackTransaction,
      };

    case 'CLOSE-CHARGEBACK-MODAL':
      return { ...state, chargeback_state: action.state, chargebackTransaction: undefined };

    case 'SET-FUNDING-STATE':
      return { ...state, funding_state: action.funding_state };

    case 'SET-PAYMENT-GROUPS':
      return { ...state, payment_groups: action.payment_groups };

    case 'SET-PAYMENT-GROUP':
      return readyToCapture({ ...state, payment_group: action.payment_group });

    case 'SET-PAYMENT-SOURCE':
      return readyToCapture(state);

    case 'SET-PAYMENT-SOURCE-NEW':
      return {
        ...state,
        payment_source_new: action.value,
        card: action.value === 'new' ? blankCard() : state.card,
      };

    case 'CC-UPDATE':
      if (state.payment_source_new === 'existing') {
        return { ...state, card_validation: { isValid: true }, card: blankCard() };
      }

      let card_validation = { isValid: false };
      let isValid;
      try {
        isValid = validateCard(action.card);
        card_validation = { ...card_validation, isValid };
      } catch (error) {
        let validation_errors = error.data.validation_errors;
        card_validation = { ...card_validation, errors: validation_errors };
      }

      return readyToCapture({
        ...state,
        card: action.card,
        card_validation,
        // funding_state: isValid && isPaymentGroup ? 'awaiting-capture' : 'awaiting-funding-details',
      });

    case 'CC-CLEAR':
      return { ...state, card: blankCard() };

    case 'ADD-PAYMENT-TRANSACTION':
      order = { ...state.order };
      order.payment_transactions = [...order.payment_transactions, action.payment_transaction];
      return { ...state, order };

    case 'CLOSE-fund-form-MODAL':
      return { ...state, card: blankCard(), funding_state: 'default' };

    case 'RESET-ORDER':
      return { ...state, order: blankOrder() };

    case 'ORDER-STATE-TRANSITION':
      let old_order = state.old_order;
      if (action.state === 'EDIT') {
        old_order = state.order;
      }

      order = state.order;
      if (action.state === 'VIEW' && action.order) {
        order = action.order;
      }

      return { ...state, ui_state: { state: action.state }, old_order: old_order, order };

    case 'REFRESH':
      return { ...state, refresh: !state.refresh };

    // NOTE: used for cleaning up the context
    case 'RESET-ORDER-STATE':
      return { ...state, ui_state: { state: 'VIEW' }, old_order: undefined };

    default:
      throw new Error(`Dispatch ${action.type} not handled in order reducer`);
  }
}

const OrderContext = React.createContext();

// NOTE: I don't actually use this variable, but I wanted a quick reference in the code to the various states
// of funding. Will probably formalize this in near future.
const funding_states = [
  'awaiting-payment',
  'awaiting-funding-details',
  'awaiting-capture',
  'capture-in-flight',
  'no-balance',
];

const blankCard = () => ({
  cvc: '',
  number: '',
  exp_month: '',
  exp_year: '',
});

function initialValues() {
  return {
    contacts: [],
    display_shipping_modal: false,
    display_billing_modal: false,
    order: blankOrder(),
    orders: { total: 0, data: [] },
    display_refund_modal: false,
    chargeback_state: 'default',
    funding_state: '',
    payment_source: {},
    payment_group: {},
    payment_groups: [],
    payment_source_new: 'new',
    card: blankCard(),
    card_validation: { isValid: '' },
    features: {
      show_payment_source_selector: false,
    },
    ui_state: {
      state: 'VIEW',
    },
  };
}

function OrderProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialValues());
  const value = useMemo(() => [state, dispatch], [state]);

  return <OrderContext.Provider value={value} {...props} />;
}

// REVIEW: moving to strictly a reducer as the Provider may not be needed
// Once we are sure that we can remove the code here, let's refactor out of the order context and move the reducer to an aptly named module
function useOrder() {
  const context = React.useContext(OrderContext);
  if (!context) {
    throw new Error('useOrder must be used within an OrderProvider');
  }

  const [state, dispatch] = context;

  return {
    state,
    dispatch,
  };
}

function useOrderReducer() {
  return useReducer(reducer, initialValues());
}

export { OrderProvider, useOrder, reducer, useOrderReducer };
