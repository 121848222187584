import { useCallback } from 'react';
import _ from 'lodash';
import { flatten as flattenKeys } from 'flat';
import { trimDeep } from 'lib/form_helpers';

// Components
import { Grid } from '@material-ui/core';
import Button from 'components/Button';
import { TextField } from 'formik-material-ui';
import { Formik, Form, Field } from 'formik';
import FilterFormHOC from 'components/form/FilterFormHOC';

// Hooks
import { makeStyles } from '@material-ui/core/styles';

export default function OrderFilterFormik({ filterFn }) {
  const classes = useStyles();

  const handleSubmit = useCallback(
    (values) => {
      const { users, main_contact, orders } = values;

      const filterData = {
        ...flattenKeys({ users, main_contact, orders }),
      };

      return filterFn(trimDeep(filterData));
    },
    [filterFn]
  );

  return (
    <FilterFormHOC>
      <Formik
        initialValues={{
          users: {
            name: '',
            email: '',
          },
          main_contact: {
            phone_number: '',
            company: '',
          },
          orders: {
            slug: '',
          },
        }}
        onReset={handleSubmit}
        onSubmit={handleSubmit}
        validateOnChange={true}
      >
        {({ dirty, touched, handleReset, isSubmitting }) => {
          return (
            <Form>
              <Grid container item xs={12} spacing={1}>
                <Grid
                  container
                  item
                  xs={11}
                  direction="row"
                  alignContent="flex-start"
                  style={{ padding: '22px' }}
                >
                  <Grid item xs>
                    <Field component={TextField} label="order id" name="orders.slug" />
                  </Grid>
                  <Grid item xs>
                    <Field
                      component={TextField}
                      label="name"
                      name="users.name"
                      autoComplete="new-password"
                    />
                  </Grid>
                  <Grid item xs>
                    <Field
                      component={TextField}
                      label="email"
                      name="users.email"
                      autoComplete="new-password"
                    />
                  </Grid>
                  <Grid item xs>
                    <Field
                      component={TextField}
                      label="company"
                      name="main_contact.company"
                      autoComplete="new-password"
                    />
                  </Grid>
                  <Grid item xs>
                    <Field
                      component={TextField}
                      label="phone number"
                      name="main_contact.phone_number"
                      autoComplete="new-password"
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={1} alignContent="flex-end" alignItems="flex-end">
                  <Grid item xs>
                    <Button
                      type="submit"
                      className={classes.filterButton}
                      disabled={isSubmitting || !dirty}
                    >
                      apply
                    </Button>
                  </Grid>
                  <Grid item xs>
                    <Button
                      type="reset"
                      color="secondary"
                      className={classes.filterButton}
                      onClick={handleReset}
                    >
                      reset
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </FilterFormHOC>
  );
}

const useStyles = makeStyles({
  pageSection: {
    marginBottom: '8px',
  },
  filterButton: {
    margin: '4px',
  },
  order_status: {
    minWidth: '100%',
  },
});
