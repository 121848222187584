import PropTypes from 'prop-types';

// Components
import Button from 'components/Button';

// Hooks
import { useFormikContext } from 'formik';

/**
 * FormButtons
 * typical submit and reset buttons using the current formik context
 *
 * @param {object} props
 * @param {string|{import('react').Component}} props.reset_children - The text or component for the reset button
 * @param {string|{import('react').Component}} props.submit_children - The text or component for the submit button
 * @returns
 */
function FormButtons({ reset_children = `reset`, submit_children = `save`, ...props }) {
  const { resetForm, isSubmitting } = useFormikContext();

  // REVIEW: The problem with disabling the submit button if the form doesn't
  // validate is that without attempting to submit the form, Formik doesn't
  // offer any way to display the required fields. So the button never gets
  // enabled unless the user has filled the required fields, but the user
  // doesn't know beforehand which fields are required.
  // REVIEW: disabled state
  // const disabled = !dirty || isSubmitting || !isValid;
  const disabled = isSubmitting;
  return (
    <>
      <Button
        onClick={() => {
          resetForm();
        }}
        type="reset"
        color="secondary"
        disabled={disabled}
      >
        {reset_children}
      </Button>
      <Button type="submit" {...props} disabled={disabled}>
        {submit_children}
      </Button>
    </>
  );
}

FormButtons.propTypes = {
  submit_children: PropTypes.node,
  reset_children: PropTypes.node,
};

export default FormButtons;
