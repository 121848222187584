import { useCallback, useState } from 'react';
import { formatCurrency } from 'lib/helpers';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import * as Yup from 'yup';

// Components
import { Form, Formik, Field } from 'formik';
import { CheckboxWithLabel, RadioGroup, TextField } from 'formik-material-ui';
import { Typography, Grid, Divider, Radio, FormControlLabel } from '@material-ui/core';
import Button from 'components/Button';
import Currency from 'components/Currency';

// Hooks
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from 'hooks/useAuth';

function genIdempotentKey() {
  return `refund-form-${uuid()}`;
}

export default function RefundForm({
  handleClose,
  handleRefundSuccess,
  order = {},
  initialValues = { checked_order_items: [], amount: 0, refund_type: 'order_items' },
}) {
  const { client } = useAuth();
  const classes = useStyles();

  const defaultRefundAmount =
    order.outstanding_balance_amount < 0 ? -order.outstanding_balance_amount : 0;

  const [idempotentKey, setIdempotentKey] = useState(genIdempotentKey());

  const refundOrderByAmount = useCallback(
    async function (amount) {
      try {
        let { data } = await client.post(`/orders/${order.id}/refunds`, {
          amount,
          idempotent_key: `admin-refund-${idempotentKey}`,
          store_id: order.store_id,
        });

        return data;
      } catch (error) {
        handleClose();
        throw error;
      }
    },
    [client, order.id, order.store_id, idempotentKey, handleClose]
  );

  /**
   * sumOrderItems
   * calculates the sum of order items taking into account the quantity
   *
   * @param {Object[]} order_items - order items
   * @returns {number}
   */
  function sumOrderItems(order_item_ids) {
    let order_items = order.order_items.filter((order_item) => {
      return _.includes(order_item_ids, order_item.id);
    });
    // TODO@refund: need to calculate tax in next iteration when storing the information
    // in a more accessible spot
    return order_items.reduce((total, order_item) => {
      return total + order_item.total_amount;
    }, 0);
  }

  const formValidationSchema = Yup.object().shape({
    refund_type: Yup.string().oneOf(['amount', 'order_items']),
    amount: Yup.number().when('refund_type', {
      is: 'amount',
      then: Yup.number().required('Amount is required').positive().max(order.credit_balance_amount),
    }),
    order_items: Yup.array().when('refund_type', {
      is: 'order_items',
      then: Yup.array().of(Yup.string()),
    }),
  });

  return (
    <Formik
      initialValues={{ ...initialValues, amount: defaultRefundAmount }}
      validationSchema={formValidationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        let { amount, refund_type } = values;
        if (refund_type === 'amount') {
          try {
            let refundResult = await refundOrderByAmount(amount);
            handleRefundSuccess(refundResult);
          } catch (error) {
            setSubmitting(false);
          } finally {
            setIdempotentKey(genIdempotentKey());
          }
        } else if (refund_type === 'order_items') {
          try {
            let { data } = await client.post(`/orders/${order?.id}/refunds`, {
              strategy: refund_type,
              order_items: values.checked_order_items.map((order_item_id) => ({ id: order_item_id })),
              idempotent_key: idempotentKey,
              store_id: order.store_id,
            });
            handleRefundSuccess(data);
          } catch (error) {
            // TODO@error-handling: need to recover gracefully here.
            // TODO@error-handling: display known errors
            console.error('refund order item error', error);
          } finally {
            handleClose();
            setIdempotentKey(genIdempotentKey());
          }
        }
      }}
    >
      {({ isSubmitting, values }) => {
        let refundableOrderItems = order?.order_items.filter(
          (order_item) =>
            order_item.is_funded &&
            !order_item.is_refunded &&
            !order_item?.funding_transaction?.is_charged_back
        );
        return (
          <Form className="refund-form">
            <Grid container>
              {isSubmitting && <Typography variant="h6">Refunding...</Typography>}
              <Grid item xs={12}>
                <Field component={RadioGroup} name="refund_type">
                  <FormControlLabel value="amount" control={<Radio />} label="Amount" />
                  <FormControlLabel
                    value="order_items"
                    control={<Radio />}
                    label="Individual Order Items"
                  />
                </Field>
                <Divider />
              </Grid>
              {values.refund_type === 'amount' ? (
                <Grid item xs={12}>
                  <Field component={TextField} name="amount" label="Amount $" />
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} classes={{ root: 'refund-order_items' }}>
                    <h4 className="form-section-title">Refundable Items</h4>
                    {_.isEmpty(refundableOrderItems) ? (
                      <>No order items available for refund</>
                    ) : (
                      refundableOrderItems.map((order_item) => (
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name="checked_order_items"
                          key={order_item.id}
                          value={order_item.id}
                          className={`order_item-${order_item.id} variant-${order_item.variant_id} catalog-item-${order_item.catalog_item_id}`}
                          Label={{
                            label: `(${order_item.quantity}x) ${
                              order_item.variant.name
                            } ${formatCurrency(+order_item.total_amount, order.currency_iso)}`,
                          }}
                        />
                      ))
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Currency
                      amount={sumOrderItems(values.checked_order_items)}
                      currencyCodeISO={order.currency_iso}
                    />
                    &nbsp; selected to be refunded.
                  </Grid>
                </>
              )}
              <Grid item xs={12} className={classes.actions}>
                <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                  Refund
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}

const useStyles = makeStyles({
  actions: {
    paddingTop: '1rem',
  },
});
