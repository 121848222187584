import S from 'lib/stringomatic';
import { formatILike } from 'lib/helpers';
import _ from 'lodash';

/**
 *  requiredMessage
 *  exists to format Yup validated error input name to a friendlier string
 *
 * @param.path {string} - object path to validation error
 * @returns {string} - error message containing input error
 */
export function requiredMessage({ path }) {
  return `${S.humanize(path)} is Required`;
}

export function isEmptyString(value) {
  return _.trim(value) === '';
}

/**
 * Given an object with filter values, return a feathers query object
 * @param {object} filterFormData Data from filter form
 * @returns {object} Feathers query params
 */
export function getQueryParamsFromFilterFormData(filterFormData) {
  return _.reduce(
    _.omitBy(filterFormData, isEmptyString),
    (acc, value, propertyName) => {
      const param = _.isString(value) ? formatILike(propertyName, value) : { [propertyName]: value };
      return { ...acc, ...param };
    },
    {}
  );
}

/**
 * Recursively trim all the strings in a given object or array
 * @param {(object|array|string)} values A given form values object, array, or string
 * @returns {*} A new object or array with all strings trimmed
 */
export function trimDeep(values) {
  if (typeof values === 'string') {
    return values.trim();
  }

  if (Array.isArray(values)) {
    return values.map(trimDeep);
  }

  if (typeof values === 'object' && values.constructor === Object) {
    return Object.keys(values).reduce((acc, key) => {
      acc[key] = trimDeep(values[key]);
      return acc;
    }, {});
  }

  return values;
}
