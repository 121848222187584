import _ from 'lodash';
import { formatCurrency, formatDateTime, formatPercentage } from './helpers';
import { GRID_COL_INDEX_ATTRIBUTE } from '@progress/kendo-react-grid';
import { useTableKeyboardNavigation } from '@progress/kendo-react-data-tools';
import { Link } from 'react-router-dom';

/**
 *
 * @typedef { import('@progress/kendo-react-grid').GridCellProps } GridCellProps
 */

export function cellCurrencyFn(props, _placeholder, currencyCol) {
  const { column } = props;
  const { field } = column;
  const value = _.get(props, field);
  const currencyIso = _.get(props, currencyCol);
  return formatCurrency(value, currencyIso);
}

export function cellDateTime(props) {
  const { column } = props;
  const { field } = column;
  const value = _.get(props, field);
  return value ? formatDateTime(value) : false;
}

/**
 * A base component for formatted cells
 * @param {GridCellProps} props Kendo Grid Cell props
 * @param {*} props.formatted The cell value to display
 * @returns {JSX.Element} A formatted grid cell
 */
export function FormattedCell(props) {
  const { id, formatted, colSpan, ariaColumnIndex, isSelected, style } = props;
  const navigationAttributes = useTableKeyboardNavigation(id);
  return (
    <td
      style={style}
      colSpan={colSpan}
      role={'gridcell'}
      aria-colindex={ariaColumnIndex}
      aria-selected={isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
      }}
      {...navigationAttributes}
    >
      {formatted}
    </td>
  );
}

/**
 * Build and return a formatted cell for currency values
 * @param {GridCellProps} props Kendo Grid Cell props
 * @param {string} props.currencyColumn The currency code column name
 * @returns {JSX.Element} A formatted grid cell
 */
export function CurrencyCell(props) {
  const { field, dataItem, currencyColumn } = props;
  const value = _.get(dataItem, field);
  const currencyIso = _.get(dataItem, currencyColumn);
  const formatted = formatCurrency(value, currencyIso);
  if (value === null || value === undefined) return null;
  return (
    <FormattedCell {...props} formatted={formatted} style={{ color: value >= 0 ? 'green' : 'red' }} />
  );
}

/**
 * Build and return a formatted cell for date time values
 * @param {GridCellProps} props Kendo Grid Cell props
 * @param {string} props.dateFormat The moment date format
 * @returns {JSX.Element} A formatted grid cell
 */
export function DateTimeCell(props) {
  const { field, dataItem, dateFormat } = props;
  const value = _.get(dataItem, field);
  const formatted = formatDateTime(value, dateFormat);
  if (value === null || value === undefined) return null;
  return <FormattedCell {...props} formatted={formatted} />;
}

/**
 * Build and return a formatted cell for links
 * @param {GridCellProps} props Kendo Grid Cell props
 * @param {string} props.url The url to navigate to
 * @param {string} props.text The text to display if children are not provided
 * @param {{Import('react').ReactNode}} props.children The children to display
 * @returns {JSX.Element} A formatted grid cell
 */
export function LinkCell(props) {
  const { url, text, style, children } = props;
  return (
    <FormattedCell
      formatted={
        <Link to={url} style={{ color: '#5a7a94', ...style }}>
          {children ? children : text}
        </Link>
      }
      {...props}
    />
  );
}

/**
 * Build and return a formatted cell for percentage values
 * @param {GridCellProps} props Kendo Grid Cell props
 * @returns {JSX.Element} A formatted grid cell
 */
export function PercentageCell(props) {
  const { field, dataItem } = props;
  const value = _.get(dataItem, field);
  const formatted = formatPercentage(value);
  if (value === null || value === undefined) return null;
  return <FormattedCell {...props} formatted={formatted} />;
}
