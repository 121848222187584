import { useState } from 'react';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';

// Components
import { TextField, Grid, Typography } from '@material-ui/core';
import Button from 'components/Button';

// Hooks
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  actionButton: {
    marginBottom: '5px',
  },
});

function blankVariant() {
  return {
    name: '',
    description: '',
    sku: '',
    options: {},
    cost: '',
    weight_grams: '',
    price: '',
  };
}

export default function VariantForm({ cancelAddVariantFn, saveFn, product, ...props }) {
  const [draftVariant, setDraftVariant] = useState(blankVariant());

  function handleSubmitNewVariant(event) {
    event.preventDefault();
    saveFn(draftVariant);
    setDraftVariant(blankVariant());
  }

  const inputChange = (property) => {
    return (event) => {
      let { value } = event.target;

      setDraftVariant({ ...draftVariant, [property]: value });
    };
  };

  let sku = draftVariant.sku;
  // TODO@formik: convert this to formik
  return (
    <form onSubmit={handleSubmitNewVariant} {...props}>
      <Grid container item xs={12}>
        <Grid container spacing={1}>
          <Grid item sm={4} xs={12}>
            <TextField label="name" value={draftVariant.name} onChange={inputChange('name')} />
            <TextField label="sku" value={sku} onChange={inputChange('sku')} />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField label="price" onChange={inputChange('price')} value={draftVariant.price} />
            <TextField label="cost" onChange={inputChange('cost')} value={draftVariant.cost} />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              label="weight (grams)"
              onChange={inputChange('weight_grams')}
              value={draftVariant.weight_grams}
            />
          </Grid>

          <Grid container item xs={12} alignItems="flex-end" spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Options</Typography>
            </Grid>
            <Grid container item xs={12}>
              {_.map(product.options, (option, key) => {
                return (
                  <Grid item md={2} sm={3} xs={12} key={`${key}-variant-option-${option.id}`}>
                    <TextField
                      label={key}
                      value={_.get(draftVariant.options, `${key}.value`, '')}
                      onChange={(event) => {
                        let { value } = event.target;
                        let newOptions = {
                          ...draftVariant.options,
                          [key]: { name: key, value, id: uuid() },
                        };
                        setDraftVariant({ ...draftVariant, options: newOptions });
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>

            <Grid item sm={6} xs={12}>
              <TextField
                label="description"
                multiline
                rows={5}
                variant="outlined"
                fullWidth={true}
                value={draftVariant.description}
                onChange={inputChange('description')}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                color="secondary"
                onClick={() => {
                  setDraftVariant(blankVariant());
                  cancelAddVariantFn();
                }}
              >
                Cancel
              </Button>
              <Button type="submit">Add Variant</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
