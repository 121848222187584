import { AppBar, Grid, Tabs, Tab, Paper } from '@material-ui/core';
import ContactCard from 'components/ContactCard';
import FulfillmentsDG2 from 'components/DataGrids/FulfillmentsDG2';
import Header from 'components/Header';
import OrdersDG2 from 'components/DataGrids/OrdersDG2';
import PageSection from 'components/PageSection';
import PaymentTransactionsDG2 from 'components/DataGrids/PaymentTransactionsDG2';
import SaveContactModal from 'components/modals/SaveContactModal';
import SessionGrid from 'components/SessionGrid';
import TabPanel from '../../components/TabPanel';

// hooks
import { useState, useEffect } from 'react';
import { useAuth } from 'hooks/useAuth';
import { makeStyles } from '@material-ui/core/styles';
import { useCustomer } from 'contexts/customer.context';
import { useParams } from 'react-router-dom';

export default function CustomersShowPage(props) {
  let { id } = useParams();
  const [orderTabValue, setOrderTabValue] = useState(0);
  let [isLoading, setIsLoading] = useState(true);
  const auth = useAuth();
  const { client } = auth;

  const { state, dispatch } = useCustomer();

  const classes = useStyles();

  useEffect(() => {
    const controller = new AbortController();

    (async () => {
      let { data } = await client.get(`/admin/customers/${id}`, {
        signal: controller.signal,
        params: {
          $eager: `[
              fulfillments.[
                order,
                order_items.[
                  variant
                ],
                shipping_contact
              ],
              contacts,
              orders.[
                payment_transactions,
                fulfillments.[
                  shipping_contact,
                  billing_contact,
                  order_items.[
                    variant
                  ]
                ],
                customer,
                shipping_contact,
                session
              ],
              sessions(getLatest)
            ]`,
        },
      });
      dispatch({ type: 'SET-CUSTOMER', customer: data });

      setIsLoading(false);
    })();

    return () => {
      controller.abort();
    };
  }, [id, client, dispatch]);
  let { customer: user } = state;

  if (isLoading || !user) {
    return <div>Loading</div>;
  }

  let { contacts, orders, latest_session } = user;
  let payment_transactions = orders.reduce((transactions, order) => {
    return transactions.concat(order.payment_transactions);
  }, []);

  return (
    <Grid item container xs={12} className={classes.pageRoot} alignContent="flex-start">
      <Header heading="Customer Detail" />
      <PageSection header="Addresses" className={classes.pageSection}>
        <Grid container>
          {contacts
            .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
            .map((c) => (
              <ContactCard contact={c} dispatch={dispatch} key={c.id} />
            ))}
        </Grid>
        <SaveContactModal
          id="EDIT-CONTACT"
          dispatch={dispatch}
          state={state}
          open={state.editContactModalOpen}
          title="Edit Contact"
        >
          Contact Form Here
        </SaveContactModal>
      </PageSection>

      <PageSection header="Session" className={classes.pageSection}>
        <SessionGrid session={latest_session} dispatch={dispatch} columns={2}></SessionGrid>
      </PageSection>

      <PageSection header="Purchase History" className={classes.pageSection}>
        <Grid item xs={12}>
          <AppBar position="static">
            <Tabs
              value={orderTabValue}
              onChange={(event, newValue) => {
                setOrderTabValue(newValue);
              }}
            >
              <Tab label="Orders" {...a11yProps(0)} />
              <Tab label="Transactions" {...a11yProps(1)} />
              <Tab label="Fullfillments" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <TabPanel value={orderTabValue} index={0}>
            <Paper>
              <OrdersDG2 rows={state.customer.orders} />
            </Paper>
          </TabPanel>
          <TabPanel value={orderTabValue} index={1}>
            <Paper>
              <PaymentTransactionsDG2
                rows={payment_transactions}
                setSelectedTransaction={() => {}}
                hideColumns={['Action']}
              />
            </Paper>
          </TabPanel>
          <TabPanel value={orderTabValue} index={2}>
            <Paper>
              <FulfillmentsDG2
                rows={state.customer.fulfillments.map((f) => ({
                  ...f,
                  customer: state.customer,
                  order_slug: f.order.slug,
                }))}
                hideColumns={['Name']}
              />
            </Paper>
          </TabPanel>
        </Grid>
      </PageSection>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    height: '95vh',
    overflow: 'auto',
  },

  pageSection: {
    flexGrow: 1,
    marginBottom: '8px',
    padding: '4px',
    minHeight: 0,
    overflow: 'auto',
    width: '80vh',
  },

  address: {
    display: 'inline-block',
  },

  appBar: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },

  addressCard: {
    padding: '10px',
    margin: '4px',
    fontWeight: 400,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
