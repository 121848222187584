import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import handleBadApi from '../lib/handle_bad_api';
import moment from 'moment';

// components
import { Grid } from '@material-ui/core';
import Header from '../components/Header';
import DataGrid from 'components/DataGrid';
import { useHistory, Link } from 'react-router-dom';
import PageSection from 'components/PageSection';
import AppBarButton from 'components/AppBarButton';

// hooks
import { useAuth } from 'hooks/useAuth';

function formatForGrid(codes) {
  if (_.isEmpty(codes)) {
    return [];
  }
  const dateFormat = 'MM-DD-YYYY : HH:mm';

  return codes.map((d) => {
    return {
      id: d.id,
      code: <Link to={`/discount_codes/${d.id}`}>{d.code}</Link>,
      type: d.type,
      customer_eligibility: d.customer_eligibility,
      minimum_requirements: d.minimum_requirements,
      limit_one_per_customer: `${d.limit_one_per_customer}`,
      limit_n_times: d.limit_n_times,
      start_at: moment(d.start_at).format(dateFormat),
      end_at: d.end_at ? moment(d.end_at).format(dateFormat) : 'never',
    };
  });
}

export default function DiscountCodePage() {
  const [discountCodes, setDiscountCodes] = useState([]);

  const history = useHistory();
  const auth = useAuth();
  const { client } = auth;

  useEffect(() => {
    (async () => {
      // fetch discount codes
      try {
        let { data: discountCodes } = await client.get('/discount_codes');
        setDiscountCodes(discountCodes);
      } catch (error) {
        handleBadApi(error, history);
      }
    })();
  }, [client, history]);

  const gridDiscountCodes = formatForGrid(discountCodes);

  return (
    <Grid container item xs={12}>
      <Header heading="Manage Discount Codes">
        <AppBarButton
          onClick={(event) => {
            history.push('/discount_codes/new');
          }}
        >
          New Discount Code
        </AppBarButton>
      </Header>
      <PageSection>
        <DataGrid
          data={gridDiscountCodes}
          columns={[
            'code',
            'type',
            'Eligibility',
            'Min Reqs',
            '1 per cust',
            'usage limit',
            'Start time',
            'End Time',
          ]}
        />
      </PageSection>
    </Grid>
  );
}
