import React from 'react';
import { v4 as uuid } from 'uuid';

// components
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@material-ui/core';
import Button from 'components/Button';
import CreditCardForm from 'components/form/CreditCardForm';
import GeneralModal from 'components/modals/GeneralModal';

// hooks
import { makeStyles } from '@material-ui/core/styles';

export default function FundModal({
  state,
  dispatch,
  saveOrder,
  refreshOrder,
  order,
  captureKey,
  setCapturekey,
  ...props
}) {
  const classes = useStyles();

  return (
    <GeneralModal dispatch={dispatch} maxWidth="sm" title="Fund your order" {...props}>
      <Grid item xs={12}>
        {/* TODO: figure out how to properly pass in a variant native element to use */}
        <form
          onSubmit={async (event) => {
            event.preventDefault();
            dispatch({ type: 'SET-FUNDING-STATE', funding_state: 'capture-in-flight' });
            let { data } = await saveOrder(order, 'capture', captureKey);
            refreshOrder();
            setCapturekey(`capture-${uuid()}`);
            dispatch({
              type: 'SET-FUNDING-STATE',
              funding_state: 'no-balance',
            });
            dispatch({
              type: 'ADD-PAYMENT-TRANSACTION',
              payment_transaction: data.payment_transaction,
            });
            dispatch({
              type: 'CC-CLEAR',
            });
          }}
          className={classes.form}
        >
          <Grid item xs={12}>
            <FormControl classes={{ root: classes.select }}>
              <InputLabel id="select-payment-group-label">Select Payment Group</InputLabel>
              <Select
                labelId="select-payment-group-label"
                id="select-payment-group"
                value={state.payment_group}
                onChange={(event) => {
                  let { target } = event;
                  dispatch({ type: 'SET-PAYMENT-GROUP', payment_group: target.value });
                }}
              >
                {state.payment_groups.map((group) => {
                  return (
                    <MenuItem value={group} key={`pg-group-${group.id}`}>
                      {group.name} ({group.slug})
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          {state.features.show_payment_source_selector && (
            <FormControl component="fieldset">
              <RadioGroup
                row
                name="existing-payment-source-or-new"
                value={state.payment_source_new}
                onChange={(event) => {
                  let {
                    target: { value },
                  } = event;
                  dispatch({ type: 'SET-PAYMENT-SOURCE-NEW', value });
                }}
              >
                <FormControlLabel value="new" control={<Radio />} label="New?" />
                <FormControlLabel value="existing" control={<Radio />} label="Existing?" />
              </RadioGroup>
            </FormControl>
          )}

          {state.payment_source_new === 'new' ? (
            <CreditCardForm dispatch={dispatch} card={state.card} />
          ) : (
            <FormControl classes={{ root: classes.select }}>
              <InputLabel id="select-payment-source-label">Select Payment Source</InputLabel>
              <Select
                labelId="select-payment-source-label"
                id="select-payment-source"
                value={state.payment_source}
              >
                <MenuItem value="10">10</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          )}
          <Grid item xs={12} className="actions">
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={
                state.funding_state === 'awaiting-payment' ||
                state.funding_state === 'awaiting-funding-details' ||
                state.funding_state === 'capture-in-flight'
              }
            >
              Fund Order
            </Button>
          </Grid>
        </form>
      </Grid>
    </GeneralModal>
  );
}

// Styles
const useStyles = makeStyles({
  orderItemsGrid: {
    marginTop: '10px',
  },
  productPickerName: {
    fontWeight: 'bold',
    padding: '0px 5px 0px 5px',
  },
  productPickerPrice: {
    fontWeight: 'bold',
    padding: '0px 5px 0px 5px',
  },

  productPrice: {
    display: 'inline-block',
    marginRight: '1rem',
  },
  productName: {},

  fixPaperRoot: {
    flexGrow: 1,
  },

  orderSections: {
    margin: '0 2px 5px 0',
    padding: '1.5rem',
  },

  saveOrderButtonContainer: {
    textAlign: 'right',
    padding: '7px',
    '& > button': {
      marginLeft: '6px',
    },
  },

  sidebarActions: {
    '& > button': {
      display: 'block',
      marginBottom: '4px',
      width: '100%',
    },
  },

  buttonIcon: {
    position: 'relative',
    top: '4px',
    height: '18px',
  },

  button: {
    marginRight: '5px',
  },

  select: {
    minWidth: '160px',
  },

  form: {
    '& .MuiFormControl-root': {
      marginRight: '4px',
    },

    '& .actions': {
      padding: '10px 0',
      textAlign: 'center',
    },
  },

  formContainer: {
    width: '395px',
    margin: '0 auto',
  },
});
