import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { rekeyDataStateFields } from 'lib/datagrid_helpers';
import _ from 'lodash';

// components
import { CurrencyCell, PercentageCell } from 'lib/datagrid_formatters';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';

const initDataState = {
  skip: 0,
  take: 10,
  group: [],
};

/**
 * A grid component for displaying an order summary report.
 * @param {object} props
 * @param {object[]} props.rows The data to display in the grid
 * @param {function} [props.handleDataState] The function to call when the grid data state changes
 * @param {string[]} props.groupBy The grouping columns to display in the grid
 * @returns {JSX.Element} A grid component
 */
export default function ProductSummaryDG({ rows, handleDataState, groupBy, ...props }) {
  const gridRef = useRef(null);
  const [dataState, setDataState] = useState(initDataState);
  const onDataStateChange = useCallback(
    (event) => {
      const { dataState } = event;
      setDataState(dataState);
      // If handleDataState is defined, pass the dataState to it using
      // the transformer function to ensure that that column names are prefixed
      // with the relation name.
      if (handleDataState) {
        handleDataState(rekeyDataStateFields(event));
      }
    },
    [handleDataState]
  );

  const groupCols = useMemo(() => {
    // When we group by a field or fields, the columns need to be prefixed with
    // the relation name. But here, since we will be referencing the output of
    // a grouping operation, the column prefixes need to be stripped off to
    // ensure that the column names are the same as the field names and that
    // sorting will work as expected.
    return groupBy.split(',').map((col) => {
      return col.split('.').reverse()[0];
    });
  }, [groupBy]);

  useEffect(() => {
    // Since we are rendering the grouping columns dynamically, when they
    // change, we need to reset the data state. Otherwise we may end up
    // sorted on columns that are no longer part of the query results.
    setDataState((oldState) => {
      return { ...oldState, sort: groupCols.map((col) => ({ field: col, dir: 'asc' })) };
    });
  }, [groupCols]);

  // When the grid data changes, auto-fit the column widths to the data.
  useEffect(() => {
    const { current: grid } = gridRef;
    if (grid) {
      grid.fitColumns(grid.columns.map((col) => col.id));
    }
  }, [rows]);

  const gridProps = {
    resizable: true,
    sortable: {
      allowUnsort: true,
      mode: 'multiple',
    },
    pageable: {
      pageSizes: [10, 25, 50, 100, 200, 400],
    },
    ...props,
  };

  return (
    <Grid
      {...gridProps}
      data={rows.data}
      total={rows.total}
      onDataStateChange={onDataStateChange}
      {...dataState}
      dataItemKey="id"
      ref={gridRef}
    >
      {/* <Column field="id" dbField="id" title="Id" width="150px" /> */}
      {groupCols.map((col) => (
        <Column key={col} field={col} dbField={col} title={_.startCase(col)} width="120px" />
      ))}
      <Column field="quantity" dbField="quantity" title="Quantity" width="120px" />
      <Column
        field="avg_price"
        dbField="avg_price"
        title="Avg. Price"
        width="120px"
        cell={(props) => <CurrencyCell {...props} currencyColumn="base_currency_iso" />}
      />
      <Column
        field="subtotal_amount"
        dbField="subtotal_amount"
        title="Subtotal Amount"
        width="120px"
        cell={(props) => <CurrencyCell {...props} currencyColumn="base_currency_iso" />}
      />
      <Column
        field="sales_tax_amount"
        dbField="sales_tax_amount"
        title="Sales Tax"
        width="120px"
        cell={(props) => <CurrencyCell {...props} currencyColumn="base_currency_iso" />}
      />
      <Column
        field="total_amount"
        dbField="total_amount"
        title="Total Amount"
        width="120px"
        cell={(props) => <CurrencyCell {...props} currencyColumn="base_currency_iso" />}
      />
      <Column field="order_count" dbField="order_count" title="Orders" width="120px" />
      <Column field="avg_quantity" dbField="avg_quantity" title="Avg. Qty/Order" width="120px" />
      <Column
        field="order_total_ratio"
        dbField="order_total_ratio"
        title="% of Total Amount"
        width="120px"
        cell={PercentageCell}
      />
    </Grid>
  );
}
