// Components
import { Grid, Toolbar, AppBar, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  title: {
    flexGrow: 1,
  },
}));
export default function Header({ heading, children, ...props }) {
  const classes = useStyles();

  return (
    <Grid container>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="h5" className={classes.title}>
                {heading}
              </Typography>
            </Grid>
            {children && (
              <>
                <Grid item></Grid>
                <Grid item>{children}</Grid>
              </>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </Grid>
  );
}
