// uncomment for configuration css, dark theme
// import 'react-json-pretty/themes/monikai.css';

import React, { useState, useEffect, Fragment } from 'react';
import * as yup from 'yup';
import qs from 'qs';
import _ from 'lodash';
import { requiredMessage } from 'lib/form_helpers';
import { formatNumber } from 'lib/helpers';

import OTextField from 'components/form/OTextField';
import { Formik, Form } from 'formik';

import Loader from 'components/Loader';
import { Grid } from '@material-ui/core';
import PageSection from 'components/PageSection';
import Header from 'components/Header';
import EditCatalogItemModal from 'components/modals/EditCatalogItemModal';
import ConfirmModal from 'components/modals/ConfirmModal';
import Button from 'components/Button';

import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';

// hooks
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

const catalogSchema = yup.object().shape({
  name: yup.string().required(requiredMessage),
});

export default function CatalogsEditPage(props) {
  let { id } = useParams();
  const { client } = useAuth();

  // states
  const [forceRefresh, setForceRefresh] = useState(false);
  const [catalog, setCatalog] = useState({
    name: '',
  });
  const [catalogItems, setCatalogItems] = useState([]);
  const [activeCatalogItem, setActiveCatalogItem] = useState(null);
  const [activeDelete, setActiveDelete] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const refresh = () => setForceRefresh(!forceRefresh);

  useEffect(() => {
    (async () => {
      let { data: catalogData } = await client.get(`/admin/catalogs/${id}`, {
        params: {},
        paramsSerializer: (params) => qs.stringify(params, { encodeValuesOnly: true }),
      });

      let { data: catalogItemsData } = await client.get(`/admin/catalog_items`, {
        params: {
          catalog_id: catalogData.id,
        },
        paramsSerializer: (params) => qs.stringify(params, { encodeValuesOnly: true }),
      });

      setCatalog(catalogData);
      setCatalogItems(catalogItemsData);
      setIsLoaded(true);
    })();
  }, [id, client, forceRefresh]);

  const classes = useStyles();

  function ActionsCell({ className = 'actions-cell', ...props }) {
    return (
      <div className={className}>
        <span
          className="link"
          onClick={() => {
            setActiveCatalogItem({ ...props });
          }}
        >
          Edit
        </span>
        <span
          className="link"
          onClick={() => {
            setActiveDelete(props);
          }}
        >
          Delete
        </span>
      </div>
    );
  }

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <Grid container>
      <Header heading={`Edit ${catalog?.name}`}></Header>

      <Grid container item xs={12}>
        <PageSection>
          <Formik
            initialValues={catalogSchema.cast(catalog)}
            validationSchema={catalogSchema}
            onSubmit={async (values) => {
              const { id, name } = values;
              let requestData = { name };
              await client.patch(`/admin/catalogs/${id}`, requestData);
              refresh();
            }}
          >
            {({ isSubmitting }) => {
              return (
                <Form>
                  <Grid container className={classes.formRoot}>
                    <Grid item xs={12}>
                      <OTextField name="name" label="name" />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                      Save
                    </Button>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </PageSection>
      </Grid>

      <Grid container item xs={12}>
        <PageSection header="Catalog Items">
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setActiveCatalogItem({});
              }}
            >
              Add Catalog Item
            </Button>
          </Grid>
          <GridComponent dataSource={catalogItems}>
            <ColumnsDirective>
              <ColumnDirective field="name" headerText="Name" />
              <ColumnDirective field="currency_iso" headerText="Currency" />
              <ColumnDirective
                field="price"
                headerText="Price"
                template={(props) => <Fragment>{formatNumber(props.price, 2)}</Fragment>}
              />
              <ColumnDirective template={ActionsCell} headerText="Actions" />
            </ColumnsDirective>
          </GridComponent>
        </PageSection>
      </Grid>

      <EditCatalogItemModal
        open={Boolean(activeCatalogItem)}
        catalogItem={activeCatalogItem}
        onSave={async (values, formType) => {
          const { id, product_id, variant_id, name, description, sku, price, currency_iso } = values;
          let requestData;
          if (formType === 'new') {
            requestData = {
              catalog_id: catalog.id,
              product_id,
              variant_id,
              name,
              description,
              sku,
              price,
              currency_iso,
            };
            await client.post('/admin/catalog_items', requestData);
          } else {
            requestData = {
              name,
              description,
              price,
            };
            await client.patch(`/admin/catalog_items/${id}`, requestData);
          }
          setActiveCatalogItem(null);
          refresh();
        }}
        dispatch={({ type }) => {
          if (type === `CLOSE-edit-catalog-item-modal-MODAL`) {
            setActiveCatalogItem(null);
          } else {
            throw new Error(`${type} not implemented`);
          }
        }}
      />

      <ConfirmModal
        open={Boolean(activeDelete)}
        handleConfirm={async () => {
          await client.delete(`/admin/catalog_items/${activeDelete.id}`);
          refresh();
          setActiveDelete(null);
        }}
        handleClose={() => {
          setActiveDelete(null);
        }}
        dispatch={_.noop}
        title={activeDelete && `Delete Catalog Item, "${activeDelete?.name}"?`}
        maxWidth="xs"
      />
    </Grid>
  );
}

// Component contained styles
const useStyles = makeStyles((theme) => ({
  selectProcessor: { width: '10em' },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  sectionHeader: {
    fontSize: '14px',
    clear: 'both',
  },

  formRoot: {
    '& .MuiFormControl-root': {
      margin: '0 4px 2px 0',
    },
  },
}));
