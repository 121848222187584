import React from 'react';
import { useAuth } from '../hooks/useAuth';
import { Redirect, Route } from 'react-router-dom';

export default function PrivateRoute({ children, authorize, ...rest }) {
  const { validateToken, ability } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location, component }) => {
        if (
          validateToken() &&
          (authorize === undefined || ability.can(authorize.access, authorize.subject))
        ) {
          return children;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
}
