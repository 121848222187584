/**
 * creates a blank slate to work from
 * Needs to be duplicated until this is removed and DRY'ed up in order.context.js
 */
export function blankOrder() {
  return {
    intent: 'build',
    shipping_contact: {},
    billing_contact: {},
    billing_same_as_shipping: false,
    customer: {},
    order_items: [],
    payment_transactions: [],
    tax_is_included: false,
    is_test: false,
    currency_iso: 'USD',
    fulfillments: [],
    outstanding_balance_amount: 0.0,
    credit_balance_amount: 0.0,
    refunded_amount: 0.0,
  };
}
