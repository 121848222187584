import { useCallback } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { flatten as flattenKeys } from 'flat';
import { trimDeep } from 'lib/form_helpers';

// Components
import { Grid } from '@material-ui/core';
import Button from 'components/Button';
import { TextField } from 'formik-material-ui';
import { Formik, Form, Field } from 'formik';
import FormikDateRangePicker from 'components/form/FormikDateRangePicker';
import FormikSimpleSelect from './FormikSimpleSelect';
import FilterFormHoc from 'components/form/FilterFormHOC';

// Hooks
import { makeStyles } from '@material-ui/core/styles';

export default function OrderFilterFormik({ filterFn }) {
  const classes = useStyles();

  const handleSubmit = useCallback(
    (values) => {
      const { created_at, orders, session } = values;

      const filterData = {
        ...flattenKeys({ orders, session }),
      };

      const [dateFrom, dateTo] = created_at;

      if (dateTo) {
        filterData['orders.created_at[$lte]'] = dateTo;
      }

      if (dateFrom) {
        filterData['orders.created_at[$gte]'] = dateFrom;
      }

      return filterFn(trimDeep(filterData));
    },
    [filterFn]
  );

  return (
    <FilterFormHoc>
      <Formik
        initialValues={{
          created_at: [moment().startOf('month').toDate(), moment().endOf('day').toDate()],
          orders: {
            status: '',
            processor_type: '',
            payment_method: '',
          },
          session: {
            utm_campaign: '',
            utm_source: '',
            utm_medium: '',
            utm_term: '',
            utm_content: '',
            aff_id: '',
            subid_1: '',
            subid_2: '',
            subid_3: '',
            subid_4: '',
          },
        }}
        onReset={handleSubmit}
        onSubmit={handleSubmit}
        validateOnChange={true}
      >
        {({ dirty, touched, handleReset, isSubmitting }) => {
          return (
            <Form>
              <Grid container item xs={12}>
                <Grid
                  container
                  item
                  xs={2}
                  direction="column"
                  alignContent="flex-start"
                  style={{ padding: '4px 4px 13px 4px' }}
                >
                  <Grid item container alignContent="center">
                    <FormikDateRangePicker fieldName="created_at" />
                  </Grid>
                  <Grid item xs>
                    <FormikSimpleSelect
                      items={[
                        { value: '', label: 'deselect' },
                        'sale',
                        'partial',
                        'declined',
                        'cancelled',
                      ]}
                      label="order status"
                      name="orders.status"
                      labelId="order-status-select-label"
                      id="order-status"
                      classes={{ root: classes.order_status }}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={9} direction="column" alignContent="flex-start">
                  <Grid container item xs>
                    <Grid item xs>
                      <Field component={TextField} label="utm campaign" name="session.utm_campaign" />
                    </Grid>
                    <Grid item xs>
                      <Field component={TextField} label="utm source" name="session.utm_source" />
                    </Grid>
                    <Grid item xs>
                      <Field component={TextField} label="utm medium" name="session.utm_medium" />
                    </Grid>
                    <Grid item xs>
                      <Field component={TextField} label="utm term" name="session.utm_term" />
                    </Grid>
                    <Grid item xs>
                      <Field component={TextField} label="utm content" name="session.utm_content" />
                    </Grid>
                  </Grid>
                  <Grid container item xs>
                    <Grid item xs>
                      <Field component={TextField} label="affId" name="session.aff_id" />
                    </Grid>
                    <Grid item xs>
                      <Field component={TextField} label="subid_1" name="session.subid_1" />
                    </Grid>
                    <Grid item xs>
                      <Field component={TextField} label="subid_2" name="session.subid_2" />
                    </Grid>
                    <Grid item xs>
                      <Field component={TextField} label="subid_3" name="session.subid_3" />
                    </Grid>
                    <Grid item xs>
                      <Field component={TextField} label="subid_4" name="session.subid_4" />
                    </Grid>
                  </Grid>
                  <Grid container item xs>
                    <Grid item xs={3}>
                      <FormikSimpleSelect
                        items={[{ value: '', label: 'deselect' }, 'nmi', 'overhub', 'paypal', 'stripe']}
                        label="processor type"
                        name="orders.processor_type"
                        labelId="order-status-select-label"
                        id="processor-type"
                        classes={{ root: classes.order_status }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormikSimpleSelect
                        items={[{ value: '', label: 'deselect' }, 'paypal', 'credit_card']}
                        label="payment method"
                        name="orders.payment_method"
                        labelId="order-status-select-label"
                        id="payment-method"
                        classes={{ root: classes.order_status }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={1}
                  alignContent="flex-end"
                  alignItems="center"
                  classes={{ root: classes.actionsSection }}
                >
                  <Grid item xs>
                    <Button
                      type="submit"
                      className={classes.filterButton}
                      disabled={isSubmitting || !dirty}
                    >
                      apply
                    </Button>
                  </Grid>
                  <Grid item xs>
                    <Button
                      type="reset"
                      color="secondary"
                      className={classes.filterButton}
                      onClick={handleReset}
                    >
                      reset
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </FilterFormHoc>
  );
}

const useStyles = makeStyles({
  pageSection: {
    marginBottom: '8px',
  },
  filterButton: {
    margin: '4px',
  },
  order_status: {
    minWidth: '100%',
  },
  actionsSection: {
    paddingBottom: '17px',
  },
});
