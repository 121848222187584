import React from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';

import { Field } from 'formik';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';

export default function FormikDateRangePicker({ fieldName, ...props }) {
  return (
    <Field name={fieldName} id={fieldName} type="date">
      {({ field: { value }, form }) => {
        const { setFieldValue, setFieldTouched } = form;
        const [start, end] = value;

        return (
          <DateRangePicker
            value={{ start, end }}
            onChange={(event) => {
              const { start, end } = event.value;
              let realstart = _.isNull(start) ? null : moment(start).startOf('day').toDate();
              let realend = _.isNull(end) ? null : moment(end).endOf('day').toDate();
              setFieldValue(fieldName, [realstart, realend]);
              setFieldTouched(fieldName, true);
            }}
            endDateInputSettings={{
              max: moment().endOf('month').toDate(),
              maxTime: moment().endOf('month').toDate(),
            }}
          />
        );
      }}
    </Field>
  );
}
