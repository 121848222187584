import PropTypes from 'prop-types';

// Components
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import 'components/Editors/css/json-editor-dark-theme.css';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/monokai';

// Hooks
import { useCallback, useEffect, useState } from 'react';

/**
 * A JSON editor component, customized with a dark theme.
 * When the JSON editor is updated, the JSON is validated and the validation
 * result is passed to the onValidate callback.
 *
 * @param {object} props
 * @param {string} [props.id='json-editor'] The DOM id of the editor.
 * @param {function} [props.onValidate] The function to call when the editor is validated.
 * @param {object} [props.value={}] The POJO object to display in the editor as JSON
 * @returns {JSX.Element} A JSON editor component
 *
 * @example
 * <JsonEditor
 *  id="some-json-editor"
 *  value={{test: 1}}
 *  onValidate={({ data, errors, isValid })) => {
 *    if (isValid) {
 *      // do something with the validated data
 *    } else {
 *      // do something with the errors
 *    }
 *   }
 * />
 */
export default function JsonEditor({ id, onValidate, value, ...props }) {
  let [validation, setValidation] = useState({
    data: {},
    errors: [],
    isValid: true,
  });

  const handleValidate = useCallback((editorData) => {
    setValidation({
      data: editorData,
      errors: [],
      isValid: true,
    });
  }, []);

  const handleError = useCallback((errors) => {
    if (errors.length > 0) {
      setValidation((prevState) => ({
        ...prevState,
        errors,
        isValid: false,
      }));
    }
  }, []);

  useEffect(() => {
    if (onValidate) {
      onValidate(validation);
    }
  }, [validation, onValidate]);

  return (
    <Editor
      htmlElementProps={{
        id: id || 'json-editor',
      }}
      value={value ?? {}}
      mode="code"
      ace={ace}
      theme="ace/theme/monokai"
      enableSort={false}
      enableTransform={false}
      onValidate={handleValidate}
      onValidationError={handleError}
      {...props}
    />
  );
}

JsonEditor.propTypes = {
  id: PropTypes.string,
  onValidate: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
