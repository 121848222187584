import _ from 'lodash';

// Components
import { Grid } from '@material-ui/core';

// Hooks
import { useOrder } from 'contexts/order.context';
import { makeStyles } from '@material-ui/core/styles';

function OrderStatusBar() {
  const { state } = useOrder();

  const classes = useStyles();
  let { order } = state;
  if (!order) {
    return null;
  }

  // REVIEW: Make sure this is the correct precedence
  let fundingStatus;
  if (order.has_chargeback) {
    fundingStatus = 'has chargeback';
  } else if (order.refunded_amount === order.total_amount) {
    fundingStatus = 'fully refunded';
  } else if (order.refunded_amount > 0) {
    fundingStatus = 'partially refunded';
  } else if (order.outstanding_balance_amount === 0) {
    fundingStatus = 'paid';
  } else if (_.some(order.payment_transactions, { status: 'pending' })) {
    fundingStatus = 'pending';
  } else {
    fundingStatus = 'unpaid';
  }

  return (
    <Grid container alignItems="center" classes={{ root: classes.statusBarRoot }}>
      <Grid item>
        <span className={classes.status}>
          <label>funding status:</label>
          {fundingStatus}
        </span>
        <span className={classes.status}>
          <label>status:</label>
          {order.status}
        </span>
        <span className={classes.status}>
          <label> ship status:</label>
          {order.ship_status}
        </span>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  status: {
    color: theme.palette.text.primary,
    fontSize: '1.1rem',
    marginRight: '0.5rem',
    borderRight: `1px solid ${theme.palette.text.secondary}`,
    paddingRight: '0.5rem',

    '& label': {
      color: theme.palette.text.secondary,
      fontSize: '1.2rem',
      fontWeight: 'bold',
    },
  },

  statusBarRoot: {
    height: '100%',
  },
}));

export default OrderStatusBar;
