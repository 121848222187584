import React from 'react';
import RefundForm from 'components/RefundForm';
import GeneralModal from './GeneralModal';

import { makeStyles } from '@material-ui/core/styles';

export default function RefundOrderModal({
  open,
  handleRefundSuccess,
  dispatch,
  order,
  formProps,
  ...props
}) {
  const classes = useStyles();

  return (
    <GeneralModal
      open={open}
      id="REFUND"
      maxWidth="sm"
      title="Refund"
      classes={{ paper: classes.paper, root: 'refund-order-modal' }}
      {...props}
    >
      <RefundForm
        handleRefundSuccess={handleRefundSuccess}
        order={order}
        {...formProps}
        handleClose={props.handleClose}
      />
    </GeneralModal>
  );
}

const useStyles = makeStyles({
  paper: {
    width: 'auto',
  },
});
