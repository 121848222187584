import { useState, Fragment } from 'react';

import ajaxErrorHandler from 'lib/ajax_error_handler';
import _ from 'lodash';

// Components
import {
  Grid,
  Card,
  CardContent,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Checkbox,
} from '@material-ui/core';
import Header from 'components/Header';
import DatePicker from 'components/DatePicker';
import TimePicker from 'components/TimePicker';
import RadioList from 'components/RadioList';
import Button from 'components/Button';

// Hooks
import { useHistory } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

function FormSection({ title, children }) {
  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Typography variant="h4">{title}</Typography>
          <FormControl>{children}</FormControl>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default function NewDiscountCodePage() {
  const history = useHistory();
  const [useDateTo, setUseDateTo] = useState(false);
  const [showNoOfTimes, setShowNoOfTimes] = useState(false);
  const [discountCode, setDiscountCode] = useState({
    code: '',
    type: '',
    value_applies_to: '',
    dateFrom: new Date(),
    requirements: '',
    customer_eligibility: '',
    usageNoTimes: '',
    usageOnePerCustomer: false,
  });
  const auth = useAuth();
  const { client } = auth;

  async function saveDiscountCode(discountCode) {
    // munge the discountCode into REST post'able form
    let {
      code,
      customer_eligibility,
      dateFrom: start_at,
      dateTo: end_at,
      requirements: minimum_requirements,
      value,
      type,
      usageNoTimes: limit_n_times,
      usageOnePerCustomer: limit_one_per_customer,
    } = discountCode;

    let discountCodeData = {
      code,
      type,
      value,
      minimum_requirements,
      customer_eligibility,
      start_at,
      end_at,
      limit_n_times: _.isEmpty(limit_n_times) ? null : limit_n_times,
      limit_one_per_customer,
    };
    let result = await client.post('/discount_codes', discountCodeData);
    return result;
  }

  function inputChange(property) {
    return function (event) {
      let { value } = event.target;
      let newDiscountCode = { ...discountCode, [property]: value };

      if (_.isEqual(newDiscountCode[property], discountCode[property])) {
        return;
      }
      setDiscountCode(newDiscountCode);
    };
  }

  function setDate(property) {
    return function (date) {
      setDiscountCode({ ...discountCode, [property]: date });
    };
  }

  return (
    <Grid container item xs={12}>
      <Header heading="Add Discount Code"></Header>
      <Grid container item xs={12}>
        <FormSection title="Discount Code">
          <TextField
            label="Discount Code"
            helperText="This is the code your customers will enter at checkout"
            onChange={inputChange('code')}
            value={discountCode.code}
          />
        </FormSection>
        <FormSection title="Types">
          <RadioGroup name="discount-code-type" value={discountCode.type} onChange={inputChange('type')}>
            <FormControlLabel value="percentage" control={<Radio />} label="Percentage %" />
            <FormControlLabel value="fixed" control={<Radio />} label="Fixed Amount" />
            {/* <FormControlLabel value="free_shipping" control={<Radio />} label="Free Shipping" /> */}
            {/* <FormControlLabel value="buy_x_get_y" control={<Radio />} label="Buy X get Y" /> */}
          </RadioGroup>
        </FormSection>

        <FormSection title="Value">
          <TextField helperText="Discount Value" onChange={inputChange('value')} />
          <Typography variant="h6">Applies To</Typography>
          <RadioGroup
            name="value_applies_to"
            value={discountCode.value_applies_to}
            onChange={inputChange('value_applies_to')}
          >
            <RadioList
              list={[
                { value: 'entire_order', label: 'Entire Order' },
                { value: 'specific_collections', label: 'Specific Collections' },
                { value: 'specific_products', label: 'Specific Products' },
              ]}
            />
          </RadioGroup>
        </FormSection>

        {/* <FormSection title="Minimum Requirements">
          <RadioGroup value={discountCode.requirements} onChange={inputChange('requirements')}>
            <RadioList
              list={[
                { label: 'None', value: 'none' },
                { label: 'Minimum purchase amount($)', value: 'minimum_purchase_amount' },
                { label: 'Specific Customers', value: 'specific_customers' },
              ]}
            />
          </RadioGroup>
        </FormSection> */}

        <FormSection title="Customer Eligibility">
          <RadioGroup
            name="customer_eligibility"
            value={discountCode.customer_eligibility}
            onChange={inputChange('customer_eligibility')}
          >
            <RadioList
              list={[
                { label: 'Everyone', value: 'everyone' },
                { label: 'Specific Groups of Customers', value: 'specific_groups' },
                { label: 'Specific Customers', value: 'specific_customers' },
              ]}
            />
          </RadioGroup>
        </FormSection>
        <FormSection title="Usage Limits">
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event) => {
                  let { checked } = event.target;
                  if (!checked) {
                    setDiscountCode({ ...discountCode, usageNoTimes: null });
                  }
                  setShowNoOfTimes(checked);
                }}
                checked={showNoOfTimes}
                name="show-usage-no-times"
              />
            }
            label="Limit number of times the discount can be used in total."
          />
          {showNoOfTimes ? (
            <TextField
              label="Max usage limit"
              helperText="The number of times the code can be used across all customers"
              onChange={inputChange('usageNoTimes')}
              value={discountCode.usageNoTimes}
              name="usage-no-times"
            />
          ) : (
            ''
          )}
          {/* <FormControlLabel
            control={
              <Checkbox
                onChange={toggleCheckbox('usageOnePerCustomer')}
                checked={discountCode.usageOnePerCustomer}
                name="usage-one-per-customer"
              />
            }
            label="Limit to one use per customer"
          /> */}
        </FormSection>

        <FormSection title="Active dates">
          <DatePicker value={discountCode.dateFrom} setDate={setDate('dateFrom')} label="Start Date" />
          <TimePicker saveTime={setDate('dateFrom')} value={discountCode.dateFrom} label="start time" />
          <FormControlLabel
            label="Use End Date?"
            control={
              <Checkbox
                value={useDateTo}
                onChange={(event) => {
                  let useEndDate = event.target.checked;
                  setUseDateTo(useEndDate);

                  if (!useEndDate) {
                    setDiscountCode({ ...discountCode, dateTo: '' });
                  }
                }}
              />
            }
          />
          {useDateTo ? (
            <Fragment>
              <DatePicker value={discountCode.dateTo} setDate={setDate('dateTo')} label="End Date" />
              <TimePicker saveTime={setDate('dateTo')} value={discountCode.dateTo} label="end time" />
            </Fragment>
          ) : (
            ''
          )}
        </FormSection>
        <Grid item xs={12}>
          <Button
            onClick={(event) => {
              event.preventDefault();
              saveDiscountCode(discountCode)
                .then(({ data }) => {
                  history.push('/discount_codes');
                })
                .catch(ajaxErrorHandler('Save Discount Code'));
            }}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
