/**
 * This is a basic component template that makes it faster to get started
 * with basic component. We should keep it updated to reflect our best
 * practices.
 */
import React, { useState } from 'react';
import { FormControl, FormControlLabel, FormGroup, FormLabel, makeStyles } from '@material-ui/core';
import { Field } from 'formik';
import { Switch } from 'formik-material-ui';

const useStyles = makeStyles({
  labelRoot: {
    marginBottom: '2px',
    borderBottom: '1px dotted #ccc',
  },
});

export default function RoleCheckGroup({
  label = 'Label',
  keyColumn = 'id',
  valueColumn = 'id',
  labelColumn = 'name',
  dataName = 'switchGroup',
  items = [],
  values = [],
  allSwitch = false,
  ...props
}) {
  const [checkedAll, setCheckedAll] = useState(values.includes('*'));
  const classes = useStyles();

  return (
    <FormControl component="fieldset" variant="standard">
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        {allSwitch && (
          <FormControlLabel
            key={`${dataName}-all`}
            control={
              <Field
                component={Switch}
                name={dataName}
                type="checkbox"
                value={'*'}
                onClick={(e) => {
                  setCheckedAll(e.target.checked);
                }}
                color="secondary"
              />
            }
            label={`All`}
            classes={{ root: classes.labelRoot }}
          />
        )}
        {items.map((item) => {
          return (
            <FormControlLabel
              key={item[keyColumn]}
              control={
                <Field
                  component={Switch}
                  name={dataName}
                  type="checkbox"
                  value={checkedAll ? false : item[valueColumn]}
                  disabled={checkedAll}
                  color="primary"
                />
              }
              label={item[labelColumn]}
            />
          );
        })}
      </FormGroup>
      {/* <FormHelperText>Helper Text Goes Here</FormHelperText> */}
    </FormControl>
  );
}
