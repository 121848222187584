import React, { useEffect, useState } from 'react';
import SwitchGroup from 'components/form/SwitchGroup';

import * as yup from 'yup';
import S from 'lib/stringomatic';
import qs from 'qs';

// Components
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  InputLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { Select } from 'formik-material-ui';
import Header from 'components/Header';
import OTextField from 'components/form/OTextField';
import PageSection from 'components/PageSection';

import PageLoading from 'components/Loading/PageLoading';

// Hooks
import { useParams, useHistory } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

// exists to format the input name to a friendlier string
const requiredMessage = ({ path }) => {
  return `${S.humanize(path)} is Required`;
};

const formSchema = yup.object().shape({
  name: yup.string().required(requiredMessage),
  company_name: yup
    .string()
    .nullable(true)
    .transform((v, o) => (v === null ? '' : v)),
  email: yup.string().required(requiredMessage),
  password: yup.string().default(''),
  type: yup.string().required(requiredMessage),
  activeRoles: yup.array().default([]),
});

export default function UsersEditPage() {
  const { client } = useAuth();
  const { user_id } = useParams();
  const history = useHistory();

  const [user, setUser] = useState(null);
  const [changePassword, setChangePassword] = useState(false);

  useEffect(() => {
    (async () => {
      let { data } = await client.get(`/admin/users/${user_id}`);

      // NOTE@yup: We can cast the data we get from the server here to ensure it
      // adheres to the schema we have defined.
      data = formSchema.cast(data);

      let { id: userId } = data;
      let { data: roles } = await client.get('/admin/roles', {
        params: {
          $eager: '[users]',
          $modify: {
            for_user: [userId],
          },
        },
        paramsSerializer: (params) => qs.stringify(params, { encodeValuesOnly: true }),
      });

      const activeRoles = roles.filter((role) => role.users.length > 0).map((role) => role.id);
      setUser({ ...data, roles, activeRoles });
    })();
  }, [user_id, client]);

  if (!user) {
    return <PageLoading />;
  }

  return (
    <Grid container>
      <Header heading="Edit User" />
      <PageSection>
        <Grid item container>
          <Formik
            initialValues={user}
            onSubmit={async (values) => {
              let { id, name, company_name, type, email, password, activeRoles } = values;

              let userData = {
                company_name,
                email,
                name,
                password: password === '' ? undefined : password,
                type,
                $graph: {
                  $relate: {
                    roles: {
                      relation: 'roles',
                      replace: true,
                      data: activeRoles.map((roleId) => ({ id: roleId })),
                    },
                  },
                },
              };
              await client.patch(`/admin/users/${id}`, userData);
              history.push('/admin/users');
            }}
            validationSchema={formSchema}
          >
            {({ isSubmitting }) => {
              return (
                <Form>
                  <Grid container>
                    <Grid item xs={12}>
                      <OTextField name="name" label="Name" />
                    </Grid>
                    <Grid item xs={12}>
                      <OTextField name="email" label="Email" />
                    </Grid>
                    <Grid item xs={12}>
                      <OTextField name="company_name" label="Company Name" />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl>
                        <InputLabel id="type-label">Type</InputLabel>
                        <Field component={Select} id="type" name="type">
                          <MenuItem value="user">User</MenuItem>
                          <MenuItem value="admin">Admin</MenuItem>
                          <MenuItem value="application">Application</MenuItem>
                        </Field>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={changePassword}
                            onChange={(e, value) => setChangePassword(value)}
                          />
                        }
                        label="Change Password"
                      />
                    </FormGroup>

                    <OTextField
                      id="password"
                      name="password"
                      label="Password"
                      disabled={!changePassword}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <h3>Roles</h3>
                    <SwitchGroup
                      label="Assign active roles:"
                      dataName="activeRoles"
                      items={user.roles}
                    />
                  </Grid>

                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                    Save
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </PageSection>
    </Grid>
  );
}
