import React from 'react';
import _ from 'lodash';
import moment from 'moment';

// components
import { Grid, TextField } from '@material-ui/core';
import SimpleSelect from 'components/form/SimpleSelect';

// hooks
import { makeStyles } from '@material-ui/core/styles';
import { useFormStyle } from 'styles/form';

export default function CreditCardForm({ card, dispatch }) {
  const classes = useStyles();

  const formClasses = useFormStyle();

  return (
    <div
      onSubmit={(event) => {
        event.preventDefault();
      }}
      className={formClasses.formRoot}
    >
      <Grid container>
        {/* FIXME: If we set an input to type number, we can't limit its length. We need to create or import a custom component to do that. */}
        <Grid item xs={12}>
          <TextField
            label="card number"
            value={card.number}
            onChange={(event) => {
              dispatch({ type: 'CC-UPDATE', card: { ...card, number: event.target.value } });
            }}
            inputProps={{
              maxLength: 16,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <SimpleSelect
            items={_.range(1, 13)}
            value={card.exp_month}
            onChange={(event) => {
              dispatch({ type: 'CC-UPDATE', card: { ...card, exp_month: event.target.value } });
            }}
            label="exp month"
            classes={{ root: classes.exp_month }}
          />

          <SimpleSelect
            items={_.range(moment().startOf('year').year(), moment().add(10, 'years').year())}
            label="exp year"
            value={card.exp_year}
            onChange={(event) => {
              dispatch({ type: 'CC-UPDATE', card: { ...card, exp_year: event.target.value } });
            }}
            classes={{ root: classes.exp_year }}
          />

          <TextField
            label="cvv"
            classes={{ root: classes.cvv }}
            required={true}
            value={card.cvc}
            onChange={(event) => {
              dispatch({ type: 'CC-UPDATE', card: { ...card, cvc: event.target.value } });
            }}
            inputProps={{
              maxLength: 4,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles({
  cvv: {
    width: '8rem',
  },

  exp_month: {
    minWidth: '10rem',
  },

  exp_year: {
    minWidth: '8.2rem',
  },
});
