import { Button } from '@material-ui/core';

// Hooks
import { makeStyles } from '@material-ui/core/styles';

export default function AppBarButton({ children, classes = {}, ...props }) {
  const componentClasses = useStyles();
  return (
    <Button
      variant="contained"
      color="primary"
      {...props}
      classes={{ root: componentClasses.root, ...classes }}
    >
      {children}
    </Button>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '3px 10px',
    fontSize: '1.1rem',
  },
}));
