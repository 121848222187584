import React, { useEffect, useState } from 'react';

import * as yup from 'yup';
import S from 'lib/stringomatic';
import _ from 'lodash';

// Components
import PageLoading from 'components/Loading/PageLoading';
import { Button, FormControl, Grid, MenuItem } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { Select } from 'formik-material-ui';
import Header from 'components/Header';
import OTextField from 'components/form/OTextField';
import PageSection from 'components/PageSection';
import { OFormikCheckBox } from 'components/form/OCheckBox';

// Hooks
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

// exists to format the input name to a friendlier string
const requiredMessage = ({ path }) => {
  return `${S.humanize(path)} is Required`;
};

const formSchema = yup.object().shape({
  name: yup.string().required(requiredMessage),
  configuration: yup.object().shape({
    vendor_store_id: yup.string().required(requiredMessage),
    min_age: yup.number().required(requiredMessage),
    max_age: yup.number().required(requiredMessage),
    api_rate_limit: yup.number().required(requiredMessage),
    batch_size: yup.number().required(requiredMessage),
  }),
});

function InputRow({ name, label }) {
  return (
    <Grid item xs={12}>
      <OTextField name={name} label={label} />
    </Grid>
  );
}

export default function FulfillmentProvidersEditPage() {
  const { client } = useAuth();
  const { fulfillment_provider_id } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch(`/store/:store_id`);

  const [fulfillmentProvider, setFulfillmentProvider] = useState(null);

  useEffect(() => {
    (async () => {
      let { data } = await client.get(`/admin/fulfillment_providers/${fulfillment_provider_id}`);
      setFulfillmentProvider(_.omit(data, 'created_at', 'updated_at', 'id', 'is_deleted', 'deleted_at'));
    })();
  }, [fulfillment_provider_id, client]);

  if (!fulfillmentProvider) {
    return <PageLoading />;
  }

  return (
    <Grid container>
      <Header heading="Edit Fulfillment Provider" />
      <PageSection>
        <Grid item container>
          <Formik
            initialValues={fulfillmentProvider}
            onSubmit={async (values) => {
              await client.patch(
                `/admin/fulfillment_providers/${fulfillment_provider_id}`,
                formSchema.cast(values)
              );
              history.push(`${url}/fulfillment_providers`);
            }}
            validationSchema={formSchema}
          >
            {({ isSubmitting }) => {
              return (
                <Form>
                  <Grid container>
                    <Grid item xs={12}>
                      <OTextField name="name" label="Name" />
                    </Grid>
                    <Grid item xs={12}>
                      <OFormikCheckBox name="is_default" id="is_default" label="Default?" />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl>
                        <Field component={Select} id="type" name="type" label="Type">
                          <MenuItem value="shipstation">Shipstation</MenuItem>
                        </Field>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <h3>Authentication</h3>
                    <div className="hint">
                      You cannot change your api secret and/or key after creation.
                    </div>
                  </Grid>

                  <Grid container item xs={12}>
                    <h3>Shipstation Config</h3>
                    <InputRow
                      name="configuration.vendor_store_id"
                      label="Shipstation Store ID"
                      help="The ID of the Shipstation Store Into which fulfillments will be populated"
                    />
                    <InputRow name="configuration.min_age" label="Min. Age (secs)" />
                    <InputRow name="configuration.max_age" label="Max Age (secs)" />
                    <InputRow name="configuration.batch_size" label="Batch Size" />
                    <InputRow name="configuration.api_rate_limit" label="API Rate Limit" />
                  </Grid>
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                    Save
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </PageSection>
    </Grid>
  );
}
