// Components
import { Divider, Typography } from '@material-ui/core';

// Hooks
import { makeStyles } from '@material-ui/core/styles';

export default function LinkGroup({ title, children, showDivider = true, ...props }) {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h6" classes={{ root: classes.title }}>
        {title}
      </Typography>
      {children}
      {showDivider && <Divider />}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.4rem',
  },
}));
