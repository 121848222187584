import Order from 'lib/order';

// Components
import { Delete, Refresh } from '@material-ui/icons';
import { Grid } from '@material-ui/core';
import Currency from 'components/Currency';
import DeletedProductsList from './DeletedProductsList';
import OrderTotalLine from './OrderTotalLine';
import PageSection from './PageSection';
import ProductList from 'components/ProductList';
import ProductRemovedList from './ProductRemovedList';

// hooks
import { makeStyles } from '@material-ui/core/styles';
import { useOrder } from 'contexts/order.context';

export default function OrderTotalSummary({ order, activeOrderItems, handleRemoveOrderItem }) {
  const classes = useStyles();
  const { dispatch } = useOrder();

  // TODO: stop relying on objects that are hard to compare and require mutating
  const myOrder = new Order(order);

  const orderItemsToDelete = order.order_items.filter((order_item) => order_item.action === 'remove');

  return (
    <PageSection
      header={
        <>
          Order Details
          {order.id && ` : # ${order.slug}`}
          <span className={classes.refreshBtn}>
            <Refresh
              onClick={() => {
                dispatch({ type: 'REFRESH' });
              }}
            />
          </span>
        </>
      }
    >
      <Grid item container xs={12}>
        <Grid container item xs={12} spacing={1} alignItems="center">
          <Grid item xs={3}>
            Product
          </Grid>
          <Grid item xs={2}>
            Quantity
          </Grid>
          <Grid item xs={2}>
            Price
          </Grid>
          <Grid item xs={2}>
            Amount
          </Grid>
          <Grid item xs={1}></Grid>

          <Grid item xs={1}>
            <Delete className={classes.removeProductIcon} color="disabled" />
          </Grid>
          <Grid item xs={1}>
            funding
          </Grid>
        </Grid>
        <ProductList
          order_items={activeOrderItems.filter((oi) => oi.is_removed !== true)}
          handleRemoveOrderItem={handleRemoveOrderItem}
          order={order}
        />
        {!!orderItemsToDelete.length && (
          <DeletedProductsList order_items={orderItemsToDelete} currencyCodeISO={order.currency_iso} />
        )}

        <ProductRemovedList
          order_items={activeOrderItems.filter((oi) => oi.is_removed === true)}
          order={order}
        />

        <Grid container classes={{ root: classes.totals }}>
          {/* <OrderTotalLine label="Discount" value="" /> */}
          <OrderTotalLine
            label="Subtotal"
            value={<Currency amount={myOrder.getSubtotal()} currencyCodeISO={order.currency_iso} />}
          />
          <OrderTotalLine
            label="Shipping"
            value={
              <Currency amount={myOrder.getShippingAmount()} currencyCodeISO={order.currency_iso} />
            }
          />
          <OrderTotalLine
            label="Taxes"
            value={<Currency amount={myOrder.getTaxes()} currencyCodeISO={order.currency_iso} />}
          />
          <OrderTotalLine
            label="Total"
            value={<Currency amount={myOrder.getTotal()} currencyCodeISO={order.currency_iso} />}
          />
          <OrderTotalLine
            label="Outstanding Balance"
            value={
              <Currency
                amount={myOrder.canCancel() ? order.outstanding_balance_amount : 0.0}
                currencyCodeISO={order.currency_iso}
              />
            }
          />
          {myOrder.id && (
            <>
              <OrderTotalLine
                label="Credit Balance"
                value={
                  <Currency amount={order.credit_balance_amount} currencyCodeISO={order.currency_iso} />
                }
              />
              <OrderTotalLine
                label="Refunded Amount"
                value={<Currency amount={order.refunded_amount} currencyCodeISO={order.currency_iso} />}
              />
            </>
          )}
          <OrderTotalLine label="Settlement Currency" value={order.currency_iso} />
        </Grid>
      </Grid>
    </PageSection>
  );
}

const useStyles = makeStyles((theme) => ({
  totals: {
    padding: '2rem 40px',
  },

  refreshBtn: {
    height: '12px',
    marginLeft: '.7em',
    position: 'relative',
    top: '4px',
    cursor: 'pointer',
    color: theme.palette.primary.main,

    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}));
