import React from 'react';
import * as yup from 'yup';
import { requiredMessage } from 'lib/form_helpers';

// Components
import { Button, Grid } from '@material-ui/core';
import Header from '../../components/Header';
import OTextField from '../../components/form/OTextField';
import PageSection from '../../components/PageSection';
import { Formik, Form } from 'formik';

// Hooks
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

const formSchema = yup.object().shape({
  name: yup.string().required(requiredMessage),
});
export default function CatalogsNewPage(props) {
  const { client } = useAuth();
  const history = useHistory();

  const { url } = useRouteMatch(`/store/:store_id`);
  const { store_id } = useParams();

  return (
    <Grid container>
      <Header heading="New Catalog"></Header>
      <PageSection>
        <Formik
          initialValues={{
            name: '',
          }}
          validationSchema={formSchema}
          onSubmit={async (values) => {
            let requestData = { ...values, store_id };
            let { data } = await client.post(`/admin/catalogs`, requestData);
            history.push(`${url}/catalogs/${data.id}`);
          }}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <Grid item xs={12}>
                  <OTextField name="name" label="Name" />
                </Grid>

                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                    Save
                  </Button>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </PageSection>
    </Grid>
  );
}
