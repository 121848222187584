import React, { useEffect, useState } from 'react';
import qs from 'qs';
import _ from 'lodash';
import classnames from 'classnames';

// components
import { Grid, Radio, FormControlLabel, Divider } from '@material-ui/core';
import Button from 'components/Button';
import { Formik, Field, Form } from 'formik';
import { RadioGroup } from 'formik-material-ui';
import Currency from 'components/Currency';
import GeneralModal from 'components/modals/GeneralModal';

// hooks
import { useAuth } from 'hooks/useAuth';
import { makeStyles } from '@material-ui/core/styles';
import { useOrder } from 'contexts/order.context';
import { useApplication } from 'contexts/application.context';

export default function SelectFundingTransactionModal({ closeModal, order_item, order_id, ...props }) {
  const { client } = useAuth();

  const [order, setOrder] = useState({ payment_transactions: [] });
  const { dispatch } = useOrder();
  const { dispatch: app_dispatch } = useApplication();

  const classes = useStyles();

  // need to fetch the available payment transactions
  useEffect(() => {
    if (!order_item || !order_id) return;
    (async () => {
      try {
        let { data } = await client.get(`/admin/orders/${order_id}`, {
          params: {
            $joinEager: `[payment_transactions]`,
            unallocated_balance: { $gte: order_item.total_amount },
          },
          // REVIEW: we should probably make this the default serializer
          paramsSerializer: (params) => qs.stringify(params, { encodeValuesOnly: true }),
        });
        setOrder(data);
      } catch (error) {
        let { status } = error?.response;
        if (status !== 404) {
          throw error;
        }

        // 404 means it could not find eligible payment transactions for this amount or at all
        // clear the global application error message
        setOrder(null);
        app_dispatch({ type: 'CLEAR_ERROR' });
      }
    })();
  }, [order_item, client, app_dispatch, order_id]);

  const hasAvailableTranasctions = !_.isEmpty(order?.payment_transactions);

  return (
    <GeneralModal
      maxWidth="sm"
      dispatch={() => {
        closeModal();
      }}
      content_text="Please select a transaction to attach as the funding transaction for this item."
      {...props}
    >
      <Grid container>
        <Grid item xs={12}>
          {order_item && (
            <>
              <Currency amount={order_item.total_amount} currencyCodeISO={order.currency_iso} />
              &nbsp;
              {order_item.name}
            </>
          )}
        </Grid>
      </Grid>
      <Divider />
      <Formik
        initialValues={{
          selected_payment_transaction: '',
        }}
        onSubmit={async (values) => {
          await client.post(
            `/admin/order_items/funding_transaction`,
            {
              funding_transaction_id: values.selected_payment_transaction,
              order_item_id: order_item.id,
            },
            {
              paramsSerializer: (params) => qs.stringify(params, { encodeValuesOnly: true }),
            }
          );
          dispatch({ type: 'REFRESH' });
          closeModal();

          // NOTE: we take it out of the edit state to avoid extra button clicks, but
          // more importantly, so that we don't end up with a bad state if we detach then cancel the edit
          dispatch({ type: 'ORDER-STATE-TRANSITION', state: 'VIEW' });
        }}
      >
        {({ handleSubmit, isSubmitting, values }) => {
          return (
            <Form>
              <Grid container>
                <Grid container item xs={12}>
                  <Grid item xs={1} />
                  <Grid item xs={2}>
                    Processor
                  </Grid>
                  <Grid item xs={3}>
                    Unallocated Balance
                  </Grid>
                  <Grid item xs={4}>
                    Total Amount
                  </Grid>
                </Grid>

                <Field
                  component={RadioGroup}
                  name="selected_payment_transaction"
                  classes={{ root: classes.radioGroup }}
                >
                  {_.isEmpty(order?.payment_transactions) && (
                    <div>
                      There are not any payment transactions available or any with a large enough
                      unallocated balance.
                    </div>
                  )}
                  {order?.payment_transactions &&
                    order_item &&
                    order.payment_transactions.map((payment_transaction) => {
                      let { unallocated_balance, processor_type, amount, id } = payment_transaction;
                      return (
                        <Grid
                          container
                          item
                          xs={12}
                          key={`pt-row-${id}`}
                          classes={{
                            root: classnames({
                              [classes.radioRow]: true,
                              [classes.exactMatch]:
                                payment_transaction.unallocated_balance === order_item.total_amount,
                            }),
                          }}
                        >
                          <Grid item xs={1}>
                            <FormControlLabel
                              value={id}
                              control={<Radio disabled={isSubmitting} />}
                              disabled={isSubmitting}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            {processor_type}
                          </Grid>
                          <Grid item xs={3}>
                            <span style={{ fontWeight: 'bold' }}>
                              <Currency
                                amount={unallocated_balance}
                                currencyCodeISO={order.currency_iso}
                              />
                            </span>
                          </Grid>
                          <Grid item xs={4}>
                            <Currency amount={amount} currencyCodeISO={order.currency_iso} />
                          </Grid>
                        </Grid>
                      );
                    })}
                </Field>

                <Grid item xs={12} classes={{ root: 'action-container' }}>
                  <Button variant="contained" onClick={closeModal}>
                    cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting || !hasAvailableTranasctions}
                  >
                    submit
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </GeneralModal>
  );
}

const useStyles = makeStyles({
  radioGroup: {
    flexGrow: 1,
    flexWrap: 'nowrap',
  },

  exactMatch: {
    background: '#f9ffbf',
    padding: '3px',
  },
});
