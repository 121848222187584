const { makeStyles } = require('@material-ui/core/styles');

export const useFormStyle = makeStyles({
  form: {
    '& .MuiTextField-root': {
      marginRight: '4px',
    },
  },

  '& .MuiFormControl-root': {
    marginRight: '4px',
  },

  container: {
    '& .MuiTextField-root': {
      marginRight: '4px',
    },
  },
});
