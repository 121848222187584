import React from 'react';

import { Grid, Dialog, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import NewContactForm from 'components/form/NewContactForm';

export default function NewContactModal({ modal_display, dispatch, userId, type }) {
  const classes = useStyles();

  function closeDialog() {
    dispatch({ type: `close-${type}-modal` });
  }

  return (
    <Dialog
      open={modal_display}
      fullWidth={true}
      maxWidth="md"
      classes={{ root: classes.dialogRoot, paper: classes.paper }}
    >
      <DialogTitle className={classes.titleBar}>
        Create New Contact
        <Cancel onClick={closeDialog} className={classes.closeButton} />
      </DialogTitle>

      <DialogContent>
        <DialogContentText></DialogContentText>
      </DialogContent>
      <Grid container>
        <Grid item style={{ margin: '0 20px' }}>
          <NewContactForm dispatch={dispatch} userId={userId} type={type} />
        </Grid>
      </Grid>
    </Dialog>
  );
}

const useStyles = makeStyles({
  closeButton: {
    position: 'absolute',
    top: '2px',
    right: '2px',
    cursor: 'pointer',
  },
  titleBar: {
    position: 'relative',
  },

  transactionField: {
    margin: '3px 10px',
  },

  paper: {
    maxWidth: '650px',
  },
});
