import React, { useState, Fragment } from 'react';
import { formatCurrency, sortDateDesc } from 'lib/helpers';
import classnames from 'classnames';

import { ListItem, Grid, ListItemText, List, Typography, Popover } from '@material-ui/core';
import Button from 'components/Button';
import { MoneyOff } from '@material-ui/icons';
import Currency from 'components/Currency';
import DialogConfirm from 'components/DialogConfirm';
import OrderItemToggleFundingLink from 'components/OrderItemToggleFundingLink';
import SelectFundingTransactionModal from 'components/modals/SelectFundingTransactionModal';

import { makeStyles } from '@material-ui/core/styles';
import { useOrder } from 'contexts/order.context';
import { useAuth } from 'hooks/useAuth';

export default function ProductRemovedList({ order_items, classOverrides = {} }) {
  const { client } = useAuth();
  const classes = useStyles();
  const { dispatch, state } = useOrder();

  const [targetDetachOrderItem, setTargetDetachOrderItem] = useState(null);
  const [detachPopoverAnchor, setDetachPopoverAnchor] = useState(null);
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [activeOrderItem, setActiveOrderItem] = useState(null);

  const fundOrderItemModalOpen = Boolean(activeOrderItem);

  const handleDetachPopover = (event) => {
    setDetachPopoverAnchor(event.target);
  };
  const handleDetachPopoverClose = () => {
    setDetachPopoverAnchor(null);
  };

  const handlePopover = (event) => {
    setPopoverAnchor(event.target);
  };

  const handlePopoverClose = () => {
    setPopoverAnchor(null);
  };

  let ui_state = state.ui_state.state;
  const canEdit = (order_item) => {
    return ui_state === 'EDIT';
  };

  return (
    <Fragment>
      <List
        aria-label="draft order products"
        classes={Object.assign({ root: `${classes.fixPaperRoot}` }, classOverrides)}
      >
        <Typography variant="h6" className={classes.title}>
          Previously removed order items
        </Typography>
        {order_items.sort(sortDateDesc).map((order_item, i) => {
          return (
            <ListItem
              disableGutters={true}
              key={`order-item-${order_item.id ?? i}`}
              classes={{ root: classnames({ refunded: order_item.is_refunded }) }}
            >
              <ListItemText>
                <Grid container item xs={12} spacing={1} alignItems="center">
                  <Grid item xs={4}>
                    {order_item.is_refunded && (
                      <span title="Refunded">
                        <MoneyOff classes={{ root: classes.rowAdornment }} />
                      </span>
                    )}
                    <span className={classes.productName}>
                      {order_item.name || order_item.variant.name}
                    </span>
                  </Grid>
                  <Grid item xs={2}>
                    {order_item.quantity}
                  </Grid>
                  <Grid item xs={2}>
                    {order_item.price}
                  </Grid>
                  <Grid item xs={2}>
                    <span className={classes.productPrice}>
                      <Currency
                        amount={+order_item.price * order_item.quantity}
                        currencyCodeISO={state.order.currency_iso}
                      />
                    </span>
                  </Grid>
                  <Grid item xs={2}>
                    <OrderItemToggleFundingLink
                      order_item={order_item}
                      setActiveOrderItem={setActiveOrderItem}
                      setTargetDetachOrderItem={setTargetDetachOrderItem}
                      handleDetachPopover={handleDetachPopover}
                      handleDetachPopoverClose={handleDetachPopoverClose}
                      handlePopoverClose={handlePopoverClose}
                      handlePopover={handlePopover}
                      canEdit={canEdit(order_item)}
                    />

                    {!order_item.is_refunded && order_item.is_funded && (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          dispatch({
                            type: 'OPEN-REFUND-MODAL',
                            default_strategy: 'order_items',
                            initialValues: { checked_order_items: [order_item.id] },
                          });
                        }}
                      >
                        refund
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </ListItemText>
            </ListItem>
          );
        })}
      </List>

      <DialogConfirm
        cancelFn={() => {
          setTargetDetachOrderItem(null);
        }}
        confirmFn={async () => {
          await client.delete(`/admin/order_items/funding_transaction/${targetDetachOrderItem.id}`);
          dispatch({ type: 'REFRESH' });
          setTargetDetachOrderItem(null);

          // NOTE: we take it out of the edit state to avoid extra button clicks, but
          // more importantly, so that we don't end up with a bad state if we detach then cancel the edit
          dispatch({ type: 'ORDER-STATE-TRANSITION', state: 'VIEW' });
        }}
        open={Boolean(targetDetachOrderItem)}
        question={`Are you sure you want to detach funding from order item "${formatCurrency(
          targetDetachOrderItem?.total_amount,
          state.order.currency_iso
        )} ${targetDetachOrderItem?.variant?.name}"?`}
        confirmText="Yes, Detach Funding"
      />

      <SelectFundingTransactionModal
        open={Boolean(fundOrderItemModalOpen)}
        closeModal={() => setActiveOrderItem(null)}
        order_item={activeOrderItem}
        order_id={state.order.id}
      />

      {/* Popover for detaching funding */}
      <Popover
        id="popover-helper-text-no-funding-removed-items"
        anchorEl={detachPopoverAnchor}
        onClose={() => {
          setDetachPopoverAnchor(null);
        }}
        open={Boolean(detachPopoverAnchor)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{ paper: classes.popoverPaper }}
        className={classes.popover}
        disableRestoreFocus
      >
        Detach the current funding source from this order item.
      </Popover>

      {/* popover for attaching funding */}
      <Popover
        id="popover-helper-text-no-funding-removed-items"
        anchorEl={popoverAnchor}
        onClose={handlePopoverClose}
        open={Boolean(popoverAnchor)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{ paper: classes.popoverPaper }}
        className={classes.popover}
        disableRestoreFocus
      >
        This order item is not yet attached to a funding transaction. Click to attach a transaction.
      </Popover>
    </Fragment>
  );
}

const useStyles = makeStyles({
  fixPaperRoot: {
    flexGrow: 1,
    background: '#EEEDE7',
    color: '#868B8E',
    paddingLeft: '.4rem',
    paddingRight: '.4rem',
  },
  productName: {},

  removeProductIcon: {
    cursor: 'pointer',
    color: '#5A7A94',

    '&:hover': {
      color: '#346a97',
    },
  },

  title: {
    color: '#626464',
  },

  rowAdornment: {
    fontSize: '1.8rem',
    paddingTop: '4px',
  },
});
