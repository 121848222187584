import React from 'react';

import Header from 'components/Header';
import PageSection from 'components/PageSection';

export default function DashboardPage() {
  return (
    <div id="dashboard-page">
      <Header heading="Welcome to Overhub"></Header>

      <PageSection>
        <h2>Getting Started</h2>
        <ol>
          <li>
            <p>&#8598; Select a store to work with in the top left corner</p>
          </li>
          <li>&larr; Choose from the menu options </li>
        </ol>
      </PageSection>
    </div>
  );
}
