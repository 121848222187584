import { forwardRef } from 'react';

import { Button as MaterialButton } from '@material-ui/core';
import classnames from 'classnames';

const Button = forwardRef(({ children, size = 'small', classes = {}, ...props }, ref) => {
  const buttonClasses = { ...classes };
  buttonClasses.root = classnames(classes.root || '', 'overhub-button');

  return (
    <MaterialButton
      size={size}
      variant="contained"
      color="primary"
      classes={buttonClasses}
      ref={ref}
      {...props}
    >
      {children}
    </MaterialButton>
  );
});

export default Button;
