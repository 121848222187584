import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import qs from 'qs';
import { requiredMessage } from 'lib/form_helpers';
import { currencyCodes } from 'lib/helpers';

// Components
import GeneralModal from './GeneralModal';
import { Formik, Form } from 'formik';
import { Grid } from '@material-ui/core';
import Button from 'components/Button';
import PageSection from 'components/PageSection';
import FormikSimpleSelect from 'components/form/FormikSimpleSelect';
import OTextField from 'components/form/OTextField';

// Hooks
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from 'hooks/useAuth';

const catalogItemSchema = yup.object().shape({
  id: yup.string(),
  name: yup.string().required(requiredMessage),
  product_id: yup.string().min(1).required(requiredMessage),
  variant_id: yup.string().required(requiredMessage),
  description: yup.string().default(''),
  sku: yup.string().default(''),
  price: yup.number().min(0.0).required(requiredMessage),
  currency_iso: yup.string().length(3).required(requiredMessage),
});

export default function EditCatalogItemModal({ catalogItem, onSave, ...props }) {
  const classes = useStyles();
  const { client } = useAuth();
  const [products, setProducts] = useState([]);
  const [variants, setVariants] = useState([]);
  const [activeProduct, setActiveProduct] = useState(catalogItem?.product_id);
  const formType = catalogItem?.id ? 'edit' : 'new';

  const initialValues = {
    id: catalogItem?.id,
    currency_iso: catalogItem?.currency_iso || 'USD',
    description: catalogItem?.description || '',
    name: catalogItem?.name || '',
    price: catalogItem?.price || 0.0,
    product_id: catalogItem?.product_id || '',
    sku: catalogItem?.sku || '',
    variant_id: catalogItem?.variant_id || '',
  };

  useEffect(() => {
    (async () => {
      let { data: productData } = await client.get('/products', {
        params: {},
        paramsSerializer: (params) => qs.stringify(params, { encodeValuesOnly: true }),
      });

      setProducts(productData);
    })();
  }, [client]);

  useEffect(() => {
    (async () => {
      let { data: variantData } = await client.get('/variants', {
        params: {
          product_id: activeProduct,
        },
        paramsSerializer: (params) => qs.stringify(params, { encodeValuesOnly: true }),
      });

      setVariants(variantData);
    })();
  }, [client, activeProduct]);

  return (
    <GeneralModal
      id="edit-catalog-item-modal"
      content_text={`${formType === 'new' ? 'New' : 'Edit'} Catalog Item`}
      {...props}
    >
      <PageSection>
        <Formik
          initialValues={catalogItemSchema.cast(initialValues)}
          validationSchema={catalogItemSchema}
          onSubmit={async (values) => {
            await onSave(values, formType);
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => {
            return (
              <Form>
                <Grid container className={classes.formRoot}>
                  <Grid item xs={6}>
                    <FormikSimpleSelect
                      id="product-select"
                      name="product_id"
                      label="Product"
                      items={products.map((product) => ({
                        label: product.name,
                        value: product.id,
                      }))}
                      classes={{ root: classes.selectProduct }}
                      onChange={({ target }) => {
                        setActiveProduct(target.value);

                        setFieldValue('product_id', target.value);
                      }}
                      disabled={formType === 'edit'}
                    />
                    <FormikSimpleSelect
                      id="variant-select"
                      name="variant_id"
                      label="Variant"
                      items={variants.map((variant) => ({
                        label: variant.name,
                        value: variant.id,
                      }))}
                      classes={{ root: classes.selectVariant }}
                      onChange={({ target }) => {
                        // NOTE@formik: Anytime you set an handler on a formik
                        // field, you need to set the field value manually or
                        // else the formik may not update the field. But this
                        // also allows us to set any field value on the form
                        // based on changes to one of the other fields.
                        setFieldValue('variant_id', target.value);
                        if (formType === 'new') {
                          const variant = variants.find((v) => v.id === target.value);
                          setFieldValue('name', variant.name);
                          setFieldValue('description', variant.description);
                          setFieldValue('sku', variant.sku);
                          setFieldValue('price', variant.price);
                        }
                      }}
                      disabled={formType === 'edit'}
                    />
                    <OTextField name="name" label="Name" />
                    <OTextField name="description" label="Description" multiline rows={4} />
                    <OTextField name="sku" label="Sku" disabled />
                    <FormikSimpleSelect
                      id="currency-select"
                      name="currency_iso"
                      label="Currency"
                      items={currencyCodes.map((code) => ({
                        label: code,
                        value: code,
                      }))}
                      classes={{ root: classes.selectCurrency }}
                      disabled={formType === 'edit'}
                    />
                    <OTextField name="price" label="Price" />
                  </Grid>

                  <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </PageSection>
    </GeneralModal>
  );
}

EditCatalogItemModal.propTypes = {
  catalogItem: PropTypes.object,
  onSave: PropTypes.func.isRequired,
};

// Component contained styles
const useStyles = makeStyles((theme) => ({
  formRoot: {
    '& .MuiFormControl-root': {
      margin: '0 4px 4px 0',
      width: '100%',
    },
  },
}));
