import _ from 'lodash';
import handleBadApi from 'lib/handle_bad_api';
import classNames from 'classnames';
import * as yup from 'yup';

// components
import { Grid, TextField, Typography, InputAdornment } from '@material-ui/core';
import { Formik, Form } from 'formik';
import Button from 'components/Button';
import Header from 'components/Header';
import DataGrid from 'components/DataGrid';
import { AddCircle } from '@material-ui/icons';
import VariantForm from 'components/VariantForm';
import PageSection from 'components/PageSection';
import { OFormikCheckBox } from 'components/form/OCheckBox';
import FilterFormTextField from 'components/form/FilterFormTextField';
import { FormikSimpleSelect } from 'components/form/SimpleSelect';
import TextArea from 'components/form/TextArea';
import { ThemeProvider } from '@material-ui/core/styles';
import formTheme from 'themes/form.theme';

// hooks
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from 'hooks/useAuth';
import { useHistory, useParams } from 'react-router-dom';

// component
function VariantText({ collection, property, variant, saveFn, InputProps, ...props }) {
  return (
    <TextField
      value={variant[property]}
      onChange={(event) => {
        let { value } = event.target;
        saveFn({ ...collection, [variant.id]: { ...variant, [property]: value } });
      }}
      InputProps={InputProps}
    />
  );
}

const productSchema = yup.object().shape({
  name: yup.string().required('Name is Required'),
  sku: yup.string().required('SKU is Required'),
  brand: yup.string().required('Brand is Required'),
  type: yup.string().oneOf(['physical', 'digital', 'warranty']).required('Type is Required'),
  shipping_price: yup.number().required('Shipping Price is Required'),
  is_taxable: yup.boolean().required('Taxable? is Required'),
  is_shippable: yup.boolean().required('Shippable? is Required'),
});

function OptionText({ collection, property, variant, saveFn, classes, ...props }) {
  let options = _.get(variant, 'options', {});
  let value = _.get(options, property, '');

  return (
    <TextField
      value={_.get(value, 'value', '')}
      onChange={(event) => {
        let { value } = event.target;
        let newOptions = {
          ...options,
          [property]: { name: property, value },
        };
        let tmpVariant = { ...variant, options: newOptions };
        let newCollection = { ...collection, [variant.id]: tmpVariant };
        saveFn(newCollection);
      }}
      InputProps={{ classes: { input: classes.variantInput } }}
    />
  );
}

/**
 * skumatic5000
 * Generates skus when there isn't one... one day
 *
 * @param {object} - variant object
 * @returns {string}
 */
function skumatic5000(variant) {
  console.warn('sku-matic 5000 NOT IMPLEMENTED!!!');
  return variant.sku;
}

function cleanProduct(product) {
  return _.pick(product, [
    'brand',
    'description',
    'id',
    'is_archived',
    'is_shippable',
    'is_taxable',
    'name',
    'options',
    'shipping_price',
    'sku',
    'type',
  ]);
}

export default function ProductShowPage() {
  const [draftProduct, setDraftProduct] = useState({
    name: '',
    sku: '',
    is_taxable: false,
    is_shippable: false,
    type: 'physical',
    shipping_price: 0.0,
    is_archived: false,
    brand: '',
    description: '',
    options: {},
  });
  const [variants, setVariants] = useState([]);
  const [draftOption, setDraftOption] = useState({ name: '' });
  const [isEditingOptions, setIsEditingOptions] = useState(false);
  const [isEditingVariant, setIsEditingVariant] = useState(false);
  const [draftVariants, setDraftVariants] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const history = useHistory();
  const { productId } = useParams();
  const auth = useAuth();
  const { client } = auth;

  const classes = useStyles();

  async function saveProduct(product) {
    let productData = _.omit(product, 'created_at', 'updated_at', 'id');
    if (product.id) {
      let { data } = await client.patch(`/products/${product.id}`, productData);
      return data;
    } else {
      let { data } = await client.post(`/products`, productData);
      return data;
    }
  }

  const variantGridData = variants.map((v) => {
    let variant = _.get(draftVariants, v.id, v);
    if (!variant.sku) {
      variant.sku = skumatic5000(variant);
    }

    return {
      id: variant.id,
      idCol: <span className={classes.variantText}>{variant.id}</span>,
      ..._.reduce(
        draftProduct.options,
        (list, option, key) => {
          /*eslint no-param-reassign: ["error", { "props": false }]*/
          list[key] = (
            <OptionText
              collection={draftVariants}
              property={key}
              variant={variant}
              saveFn={setDraftVariants}
              classes={classes}
            />
          );
          return list;
        },
        {}
      ),
      name: (
        <VariantText
          collection={draftVariants}
          property="name"
          variant={variant}
          saveFn={setDraftVariants}
          InputProps={{
            classes: { input: classes.variantInput },
          }}
        />
      ),
      description: (
        <VariantText
          collection={draftVariants}
          property="description"
          variant={variant}
          saveFn={setDraftVariants}
          InputProps={{
            classes: { input: classes.variantInput },
          }}
        />
      ),
      sku: (
        <VariantText
          collection={draftVariants}
          property="sku"
          variant={variant}
          saveFn={setDraftVariants}
          InputProps={{
            classes: { input: classes.variantInput },
          }}
        />
      ),
      weight_grams: (
        <VariantText
          collection={draftVariants}
          property="weight_grams"
          variant={variant}
          saveFn={setDraftVariants}
          classes={classes}
          InputProps={{
            classes: { input: classes.variantInput },
            endAdornment: <InputAdornment position="end">g</InputAdornment>,
          }}
        />
      ),
      cost: (
        <VariantText
          collection={draftVariants}
          property="cost"
          variant={variant}
          saveFn={setDraftVariants}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            classes: { input: classes.variantInput },
          }}
        />
      ),
      price: (
        <VariantText
          collection={draftVariants}
          property="price"
          variant={variant}
          saveFn={setDraftVariants}
          classes={classes}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            classes: { input: classes.variantInput },
          }}
        />
      ),
    };
  });

  useEffect(() => {
    const controller = new AbortController();

    (async () => {
      if (!productId) {
        return;
      }

      let { data } = await client.get(`/products/${productId}`, {
        signal: controller.signal,
        params: {
          include: 'variants',
        },
      });

      let draftProduct = cleanProduct(data.attributes);

      setDraftProduct(draftProduct);
      setVariants(data.relationships.variants.data);
    })();

    return () => {
      controller.abort();
    };
  }, [productId, refresh, client]);

  if (!draftProduct) {
    return '';
  }

  function handleSaveOption(event) {
    let { name } = draftOption;
    let options = { ...draftProduct.options, [name]: { ...draftOption, type: 'text' } };

    setDraftProduct({ ...draftProduct, options });

    client
      .patch(`/products/${draftProduct.id}`, { options: options })
      .then(({ data }) => {
        setDraftProduct(data);
      })
      .catch((error) => {
        console.error('save options error', error);
      });
  }

  function handleSaveVariants(event) {
    client
      .patch(
        `/admin/variants_bulk`,
        Object.values(draftVariants).map((dv) =>
          _.omit(dv, 'created_at', 'updated_at', 'product_id', 'account_id')
        )
      )
      .then(({ data }) => {
        setRefresh(!refresh);
      })
      .catch((error) => {
        console.error('Error saving variants', error);
      });
  }

  function saveNewVariant(variant) {
    let variantData = { ...variant, product_id: draftProduct.id };

    return client
      .post(`/variants`, variantData)
      .then(({ data }) => {
        setVariants([...variants, data]);

        setIsEditingVariant(false);
      })
      .catch((error) => {
        console.error('error saving variant', error);
        handleBadApi(error, history);
      });
  }

  return (
    <Grid container item xs={12}>
      <Header heading={draftProduct.name || `New Product`}></Header>
      <Grid container item xs={12}>
        <Formik
          enableReinitialize
          initialValues={draftProduct}
          onSubmit={async (values) => {
            let isNew = !draftProduct.id;
            let product = await saveProduct(values);
            setDraftProduct(cleanProduct(product));
            if (isNew) {
              history.push(`/products/${product.id}`);
            }
          }}
          validationSchema={productSchema}
        >
          {({ isSubmitting, dirty }) => {
            return (
              <Form>
                <ThemeProvider theme={formTheme}>
                  <PageSection header="Product">
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        {draftProduct.id && (
                          <Typography variant="subtitle1">ID: {draftProduct.id}</Typography>
                        )}
                      </Grid>

                      <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FilterFormTextField id="name" label="name" name="name" />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <FormikSimpleSelect
                            items={['physical', 'digital', 'warranty'].map((type) => ({
                              value: type,
                              label: type,
                              key: type,
                            }))}
                            name="type"
                            id="product-type"
                            label="type"
                          />
                        </Grid>
                      </Grid>

                      <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FilterFormTextField id="sku" label="sku" name="sku" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FilterFormTextField
                            label="shipping price"
                            name="shipping_price"
                            id="shipping_price"
                          />
                        </Grid>
                      </Grid>

                      <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FilterFormTextField id="brand" label="brand" name="brand" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <OFormikCheckBox name="is_taxable" label="Taxable?" id="is_taxable" />
                          <OFormikCheckBox name="is_shippable" label="Shippable?" id="is_shippable" />
                        </Grid>
                      </Grid>

                      <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <TextArea rows={5} name="description" label="description" id="description" />
                        </Grid>
                        <Grid container item xs={12} sm={6} alignItems="flex-end">
                          <Grid item>
                            <Button type="submit" disabled={isSubmitting || !dirty}>
                              Save Product
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </PageSection>
                </ThemeProvider>
              </Form>
            );
          }}
        </Formik>
        {draftProduct.id && (
          <PageSection header="Variants">
            <Grid container>
              <Grid item xs={4}>
                <Button
                  onClick={() => {
                    setIsEditingOptions(true);
                  }}
                >
                  Add Option
                </Button>
                {/* add variant */}
                <Button
                  onClick={(event) => {
                    event.preventDefault();
                    // show variant form
                    setIsEditingVariant(true);
                  }}
                  disabled={isEditingVariant}
                >
                  Add Variant
                  <AddCircle />
                </Button>
              </Grid>
              <Grid item xs={9}>
                <div className="product-options" style={{ marginTop: '7px' }}>
                  {_.map(draftProduct.options, (value, key) => {
                    return (
                      <span className={classes.productOptionLabel} key={`product-option-${key}`}>
                        {key}
                      </span>
                    );
                  })}
                </div>
              </Grid>
            </Grid>

            {/* create new option form */}
            <div
              className={classNames({
                [classes.hideOption]: !isEditingOptions,
                [classes.optionForm]: true,
                'new-option-form': true,
              })}
              onKeyUp={(event) => {
                if (event.code === 27) {
                  setIsEditingOptions(false);
                }
              }}
            >
              <Grid container>
                <Grid item sm={4} xs={12}>
                  <TextField
                    value={draftOption.name}
                    onChange={(event) => {
                      let { value: name } = event.target;
                      setDraftOption({ ...draftOption, name });
                    }}
                    label="name"
                  />
                </Grid>
                <Grid item>
                  <Button
                    color="secondary"
                    onClick={() => {
                      setIsEditingOptions(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button onClick={handleSaveOption}>save option</Button>
                </Grid>
              </Grid>
            </div>
            {/* end create new option form */}

            {/* new variant form */}
            <div
              id="new-variant-form"
              className={classNames({
                [classes.newVariantForm]: true,
                active: isEditingVariant,
              })}
            >
              <VariantForm
                cancelAddVariantFn={() => {
                  setIsEditingVariant(false);
                }}
                saveFn={saveNewVariant}
                product={draftProduct}
              />
            </div>
            {/* end new variant form */}

            <DataGrid
              data={variantGridData}
              columns={[
                'id',
                ...Object.keys(draftProduct.options || {}),
                'name',
                'description',
                'sku',
                'weight',
                'cost',
                'price',
              ]}
              name="variant-grid"
            />
            <Grid item xs={12}>
              <Button
                onClick={handleSaveVariants}
                style={{ marginTop: '4px' }}
                variant="contained"
                color="primary"
              >
                Save Variants
              </Button>
            </Grid>
          </PageSection>
        )}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({
  hideOption: {
    display: 'none',
  },

  variantText: {
    fontSize: '1.0rem',
    whiteSpace: 'nowrap',
  },

  variantInput: {
    fontSize: '1.2rem',
  },

  productOptionLabel: {
    fontWeight: 'bold',
    margin: '2px 3px',
    backgroundColor: '#efefef',
    padding: '4px',
    borderRadius: '3px',
  },

  optionForm: {
    padding: '25px 0 25px 0',
    margin: '2px 10px',
  },

  newVariantForm: {
    display: 'none',
    marginBottom: '12px',
    borderBottom: '4px solid #000',

    '&.active': {
      display: 'block',
    },

    '& .MuiFormControl-root': {
      margin: '8px',
    },
  },
});
