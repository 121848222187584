import classnames from 'classnames';

// Components
// import { Link } from 'react-router-dom';
import { ListItem } from '@material-ui/core';

// Hooks
import { useHistory, useRouteMatch } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';

export default function NavLink({ children, m = 2, className = '', ...props }) {
  const history = useHistory();
  const match = useRouteMatch({ path: props.to, exact: true });

  const classes = useStyles();

  return (
    <div
      className={classnames(className, 'link', { [classes.active]: match })}
      onClick={() => {
        history.push(props.to);
      }}
    >
      <ListItem button m={m} disableGutters={true} classes={{ root: classes.root }}>
        {children}
      </ListItem>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  active: { color: theme.palette.primary.dark },
  root: {
    paddingLeft: '0.5rem',
  },
}));
