import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';

// Components
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { Field } from 'formik';
import { Select } from 'formik-material-ui';

// Hooks
import { makeStyles } from '@material-ui/core/styles';

export default function FormikSimpleSelect({
  id,
  name,
  classes = { root: '' },
  label,
  labelId,
  items = [],
  ...props
}) {
  const defaultClasses = useStyles();
  return (
    <FormControl className={classnames(defaultClasses.formControl, classes.root)}>
      <InputLabel htmlFor={id} id={labelId}>
        {label}
      </InputLabel>
      <Field
        id={id}
        name={name}
        component={Select}
        inputProps={{
          id,
          name,
        }}
        {...props}
        autoWidth={true}
      >
        {items.map((item) => {
          let value = item;
          let label = item;
          if (_.isObject(item)) {
            value = item.value;
            label = item.label;
          }

          return (
            <MenuItem value={value} key={`option-${value}-${id}`}>
              {label}
            </MenuItem>
          );
        })}
      </Field>
    </FormControl>
  );
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 'calc(100%)',
  },
}));

FormikSimpleSelect.propTypes = {
  classes: PropTypes.object,
  id: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  label: PropTypes.string.isRequired,
  labelId: PropTypes.string,
  name: PropTypes.string.isRequired,
};
