import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Order from 'lib/order';
import { currencyCodes } from 'lib/helpers';
import { v4 as uuid } from 'uuid';

// components
import { Button, Grid, Typography, Paper, Checkbox, FormControlLabel } from '@material-ui/core';
import CatalogItemSearch from 'components/search/CatalogItemSearch';
import ConfirmModal from 'components/modals/ConfirmModal';
import ContactSelector from 'components/ContactSelector';
import CreateNewCustomerModal from 'components/modals/CreateNewCustomerModal';
import CustomerDetail from 'components/CustomerDetail';
import CustomerSearch from 'components/search/CustomerSearch';
import Header from 'components/Header';
import OrderTotalSummary from 'components/OrderTotalSummary';
import PageSection from 'components/PageSection';
import SimpleSelect from 'components/form/SimpleSelect';

// hooks
import { useOrderReducer } from 'contexts/order.context';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

export default function NewOrderPage() {
  const history = useHistory();
  const location = useLocation();
  const { client } = useAuth();
  const classes = useStyles();
  const { store_id } = useParams();
  const { url } = useRouteMatch(`/store/:store_id`);

  const [billingSameAsShipping, setBillingSameAsShipping] = useState(false);
  const [activeChangeCurrency, setActiveChangeCurrency] = useState(null);
  const [isNewCustomerModalOpen, setIsNewCustomerModalOpen] = useState(false);

  const [state, dispatch] = useOrderReducer();

  // NOTE: we're Assigning the shipping and billing here so we can use an abstracted context with a reducer
  // for contacts, but not refactor the entire order at this time. This is a stepping stone.
  // The order should be refactored to use the context, and possibly pull the data from the admin endpoint
  const order = new Order({
    ...state.order,
    billing_contact: state.order.billing_contact,
    shipping_contact: state.order.shipping_contact,
    shipping_contact_id: state.order?.shipping_contact?.id,
    billing_contact_id: state.order?.billing_contact?.id,
    billing_same_as_shipping: billingSameAsShipping,
    customer: state.order.customer,
    customer_id: state.order?.customer?.id,
    store_id: store_id,
  });

  const selectBillingContact = (contact) => {
    dispatch({ type: 'SET-BILLING-CONTACT', contact });
  };
  const selectShippingContact = (contact) => {
    dispatch({ type: 'SET-SHIPPING-CONTACT', contact });
  };

  // NOTE@contacts: This is passed into the ContactSelector component, and
  // is used to determine if the a user has been selected.
  let userId = order?.customer?.id;

  // NOTE@hooks: This gets run when we load or reload the page, and assigns a
  // cleanup function to reset the order context.
  useEffect(() => {
    dispatch({ type: 'ORDER-STATE-TRANSITION', state: 'EDIT' });
    return function cleanup() {
      dispatch({ type: 'RESET-ORDER' });
    };
  }, [location.key, client, dispatch]);

  let orderTitle = `New Order`;
  if (!_.isEmpty(order.customer)) {
    orderTitle = `${order.customer.name}'s New Order`;
  }

  function handleBillingSameAsShipping(event) {
    const { checked } = event.target;
    setBillingSameAsShipping(checked);
  }

  async function saveOrder(event) {
    event.preventDefault();

    // TODO: refactor this into Order model
    // munge order data into expected format
    let orderData = order.export('build');
    orderData.idempotent_key = `admin-draft-${uuid()}`;
    orderData.billing_contact = _.pickBy(orderData.billing_contact, _.identity);
    orderData.shipping_contact = _.pickBy(orderData.shipping_contact, _.identity);

    const { data } = await client.post('/orders', orderData);
    history.push(`${url}/orders/${data.data.order.id}`);
  }

  /**
   * This is the selection handler for the customer search input
   * @param {*} customer The selected customer
   */
  function handleCustomerChange(customer) {
    // assign the customer and contacts to the order state
    dispatch({ type: 'SET-CUSTOMER', customer });
    dispatch({ type: 'SET-CONTACTS', contacts: customer.contacts });
  }

  /**
   * This is the selection handlers for the catalog item auto-complete input
   * @param {*} catalogItem The selected catalog item
   */
  function handleAddCatalogItem(catalogItem) {
    let order_item = Order.catalogItemToOrderCartItem(catalogItem);
    dispatch({ type: 'ADD-ORDER-ITEM', order_item });
  }

  function handleRemoveOrderItem(orderItem) {
    return (event) => {
      event.preventDefault();

      dispatch({ type: 'REMOVE-ORDER-ITEM', order_item: orderItem });
    };
  }

  /**
   * This is the onChange handler for the order currency selector
   * @param {*} event The select component event
   * @param {*} value The value of the currency selector
   */
  function handleChangeOrderCurrency(event, value) {
    setActiveChangeCurrency(value);
  }

  const activeOrderItems = state.order.order_items.filter((oi) => oi.action !== 'remove');

  const canSave =
    !_.isEmpty(order.order_items) ||
    !_.isEmpty(order.billing_contact) ||
    !!order.billing_contact_id ||
    !_.isEmpty(order.shipping_contact) ||
    !!order.shipping_contact_id;

  return (
    <Grid container item xs={12}>
      <Header heading={orderTitle}></Header>
      <Grid container item xs={12}>
        <Grid container item xs={8}>
          <Grid item xs={12}>
            <PageSection>
              <CatalogItemSearch
                currencyCodeISO={state.order.currency_iso}
                storeId={store_id}
                onSelect={handleAddCatalogItem}
                timeoutDuration={600}
              />
            </PageSection>
          </Grid>
          <Grid item container xs={12}>
            <OrderTotalSummary
              order={state.order}
              activeOrderItems={activeOrderItems}
              handleRemoveOrderItem={handleRemoveOrderItem}
            />

            <Grid item container xs={12}>
              <Grid item xs={6}>
                <PageSection noPaper={true}>
                  <SimpleSelect
                    id="currency-select"
                    label="Currency"
                    items={currencyCodes}
                    classes={{ root: classes.selectCurrency }}
                    value={order.currency_iso}
                    onChange={handleChangeOrderCurrency}
                  />
                </PageSection>
              </Grid>
              <Grid item xs={6} className={classes.saveOrderButtonContainer}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.order.is_test}
                      onClick={(event) => {
                        let { checked } = event.target;
                        dispatch({ type: 'SET-ORDER', order: { ...state.order, is_test: checked } });
                      }}
                    />
                  }
                  label="Test?"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.order.tax_is_included}
                      onClick={(event) => {
                        let { checked } = event.target;
                        dispatch({
                          type: 'SET-ORDER',
                          order: { ...state.order, tax_is_included: checked },
                        });
                      }}
                    />
                  }
                  label="Tax included"
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={saveOrder}
                  className={classes.saveOrderButton}
                  disabled={!canSave}
                >
                  Save Order
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Right column */}
        <Grid item xs={4}>
          <Paper classes={{ root: classes.orderSections }}>
            <Typography variant="h6">Select Customer</Typography>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={8}>
                <CustomerSearch onSelect={handleCustomerChange} timeoutDuration={600} />
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setIsNewCustomerModalOpen(true);
                  }}
                  classes={{ root: classes.addNewUserButton }}
                >
                  Add New
                </Button>
              </Grid>
            </Grid>
            <CustomerDetail customer={state.order.customer} />

            {!_.isEmpty(state.order.customer) && (
              <>
                <hr />
                <Typography variant="h6">Select Shipping</Typography>
                <ContactSelector
                  handleSelect={selectShippingContact}
                  id="contact-selector"
                  value={state.order.shipping_contact}
                  contacts={state.contacts}
                  userId={userId}
                  dispatch={dispatch}
                  type="shipping"
                  modal_display={state.display_shipping_modal}
                />
              </>
            )}
            {order.shipping_contact && (
              <>
                <FormControlLabel
                  classes={{ root: classes.clear }}
                  control={
                    <Checkbox
                      checked={billingSameAsShipping}
                      onChange={handleBillingSameAsShipping}
                      name="billing-same-as-shipping"
                      value={billingSameAsShipping}
                      contacts={[order.billing_contact]}
                    />
                  }
                  label="Billing same as shipping?"
                />
              </>
            )}

            {!_.isEmpty(state.order.customer) && !billingSameAsShipping && (
              <>
                <hr />
                <Typography variant="h6">Select Billing</Typography>
                <ContactSelector
                  handleSelect={selectBillingContact}
                  id="contact-selector"
                  value={order.billing_contact}
                  contacts={state.contacts}
                  userId={userId}
                  dispatch={dispatch}
                  type="billing"
                  modal_display={state.display_billing_modal}
                />
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
      <ConfirmModal
        open={Boolean(activeChangeCurrency)}
        handleConfirm={async () => {
          dispatch({ type: 'SET-ORDER-CURRENCY', currency_iso: activeChangeCurrency });
          setActiveChangeCurrency(null);
        }}
        handleClose={() => {
          setActiveChangeCurrency(null);
        }}
        dispatch={_.noop}
        title={
          activeChangeCurrency &&
          `Changing the currency will remove all current draft order items. Continue?`
        }
        maxWidth="xs"
      />
      <CreateNewCustomerModal
        handleSave={(customer) => {
          dispatch({ type: 'SET-CUSTOMER', customer });
          setIsNewCustomerModalOpen(false);
        }}
        handleClose={() => {
          setIsNewCustomerModalOpen(false);
        }}
        open={isNewCustomerModalOpen}
      />
    </Grid>
  );
}

// Styles
const useStyles = makeStyles({
  orderItemsGrid: {
    marginTop: '10px',
  },

  productPrice: {
    display: 'inline-block',
    marginRight: '1rem',
  },
  productName: {},

  fixPaperRoot: {
    flexGrow: 1,
  },

  orderSections: {
    margin: '0 2px 5px 0',
    padding: '1.5rem',
  },

  saveOrderButtonContainer: {
    textAlign: 'right',
    padding: '7px',
    '& > button': {
      marginLeft: '6px',
    },
  },

  clear: {
    clear: 'both',
    display: 'block',
  },

  addNewUserButton: {
    marginLeft: '8px',
  },
});
