import _ from 'lodash';
import { rekeyDataStateFields } from 'lib/datagrid_helpers';

// components
import { CurrencyCell, FormattedCell, DateTimeCell, LinkCell } from 'lib/datagrid_formatters';
import { LocalShipping, LocalShippingTwoTone } from '@material-ui/icons';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';

// hooks
import { useState, useCallback, useEffect, useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';

function OrderSlugCell(props) {
  const { field, dataItem, storeId } = props;
  const id = _.get(dataItem, field);
  const slug = _.get(dataItem, 'slug');
  const url = `/store/${storeId}/orders/${id}`;
  return <LinkCell url={url} text={slug} {...props} />;
}

function CustomerEmailCell(props) {
  const { field, dataItem, storeId } = props;
  const email = _.get(dataItem, field);
  const id = _.get(dataItem.customer, 'id');
  const url = `/store/${storeId}/customers/${id}`;
  return <LinkCell url={url} text={email} {...props} />;
}

function ShipStatusCell(props) {
  const { field, dataItem } = props;
  const status = _.get(dataItem, field);
  const icons = {
    partial: () => (
      <span title="partial">
        <LocalShippingTwoTone title="partial" />
      </span>
    ),
    complete: () => (
      <span title="complete">
        <LocalShipping title="complete" />
      </span>
    ),
  };
  const icon = icons[status] ? icons[status]() : '';

  return <FormattedCell {...props} formatted={icon} />;
}

const initDataState = {
  skip: 0,
  take: 10,
  group: [],
  sort: [{ field: 'created_at', dir: 'desc' }],
};

/**
 * A grid component for displaying orders.
 * @param {object} props
 * @param {object[]} props.rows The data to display in the grid
 * @param {function} [props.handleDataState] The function to call when the grid data state changes
 * @returns {JSX.Element} A grid component
 */
export default function OrdersDG3({ rows, handleDataState, ...props }) {
  const { params } = useRouteMatch();
  const { store_id } = params;

  const [dataState, setDataState] = useState(initDataState);
  const gridRef = useRef(null);

  const onDataStateChange = useCallback(
    (event) => {
      const { dataState } = event;
      setDataState(dataState);
      // NOTE: If handleDataState is defined, pass the dataState to it using
      // the transformer function to ensure that that column names are prefixed
      // with the relation name.
      if (handleDataState) {
        handleDataState(rekeyDataStateFields(event));
      }
    },
    [handleDataState]
  );

  // When the grid data changes, auto-fit the column widths to the data.
  useEffect(() => {
    const { current: grid } = gridRef;
    if (grid) {
      grid.fitColumns(grid.columns.map((col) => col.id));
    }
  }, [rows]);

  const gridProps = {
    resizable: true,
    sortable: true,
    pageable: {
      pageSizes: [10, 25, 50, 100, 200, 400],
    },
    ...props,
  };

  // NOTE@grid: The dbField prop is used to specify the column name as it would
  // appear in the database query, usually prefixed with the relation name.
  return (
    <Grid
      {...gridProps}
      data={rows.data}
      total={rows.total}
      onDataStateChange={onDataStateChange}
      {...dataState}
      dataItemKey="id"
      ref={gridRef}
    >
      <Column
        field="id"
        dbField="orders.id"
        title="Order Id"
        cell={(props) => <OrderSlugCell {...props} storeId={store_id} />}
        width="200px"
      />
      <Column field="status" dbField="orders.status" title="Status" width="100px" />
      <Column
        field="total_amount"
        dbField="orders.total_amount"
        title="Total"
        cell={(props) => <CurrencyCell {...props} currencyColumn="currency_iso" />}
        width="100px"
      />
      <Column field="customer.name" title="Name" width="160px" />
      <Column
        field="customer.email"
        title="Email"
        cell={(props) => <CustomerEmailCell {...props} storeId={store_id} />}
        width="200px"
      />
      <Column field="shipping_contact.country_iso" title="Country" width="100px" />
      <Column
        field="created_at"
        dbField="orders.created_at"
        title="Created At"
        cell={DateTimeCell}
        width="150px"
      />
      <Column field="session.utm_campaign" title="Campaign" width="150px" />
      <Column
        field="ship_status"
        dbField="orders.ship_status"
        title="Ship Status"
        cell={ShipStatusCell}
        width="100px"
      />
    </Grid>
  );
}
