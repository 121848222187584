import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { parseCurrency } from 'lib/helpers';

function stripTimestamps(record) {
  return _.omit(record, 'created_at', 'updated_at');
}
// NOTE: I think we should move away from these rich objects. They convolute managing state
class OrderItem {
  constructor(data) {
    if (data instanceof OrderItem) {
      return data;
    }

    Object.assign(this, data);

    // NOTE: the temp id is a good idea to ensure stable keys, though we need to wait until the refactor is done
    // and converting to use context and probably moving away from these rich objects to avoid needing to do double the work
    if (!this.id) {
      this.tempId = uuid();
    }

    if (data.sales_tax_amount && !_.isNumber(data.sales_tax_amount)) {
      this.sales_tax_amount = parseFloat(data.sales_tax_amount);
    }
  }

  export() {
    return _.pick(
      this,
      'id',
      'catalog_item_id',
      'order_id',
      'product_id',
      'variant_id',
      'fulfillment_id',
      'quantity',
      'price',
      'discount',
      'weight_grams',
      'is_taxable',
      'is_shippable',
      'shipping_price',
      'action',
      'meta'
    );
  }
}

export default class Order {
  constructor(data) {
    _.forEach(data, (d, key) => {
      if (_.isFunction(this[key])) {
        throw new Error(`Cannot overwrite prototype function: this.prototype.${key}`);
      }
    });

    /*eslint no-param-reassign: ["error", {"props": false }]*/
    // if (data.order_items) {
    //   data.order_items = data.order_items.map((oi) => new OrderItem(oi));
    // }
    Object.assign(this, data);

    if (_.isArray(this.payment_transactions)) {
      this.payment_transactions = this._sanitizePaymentTransactions(this.payment_transactions);
    }
  }

  /**
   * getSubtotal
   * loop through variants and pull the subtotal
   *
   * @returns {number} - the total of all the variants associated with the order
   */
  getSubtotal() {
    const draftSubtotal = _.sumBy(
      this.order_items.filter((item) => !item.id),
      (item) => item.quantity * item.price
    );
    const subTotal = this.id ? +this.subtotal_amount + draftSubtotal : draftSubtotal;
    return parseCurrency(subTotal);
  }

  getTotal() {
    return parseCurrency(_.get(this, 'total_amount', 0));
  }

  getTaxes() {
    return parseCurrency(_.get(this, 'sales_tax_amount', 0));
  }

  getShippingAmount() {
    return parseCurrency(_.get(this, 'shipping_amount', 0));
  }

  // REVIEW: there are more cases that control cancelability
  canCancel() {
    return this.status !== 'cancelled';
  }

  export(intent) {
    this.intent = intent;
    let orderData = _.pick(this, [
      'billing_contact',
      'billing_same_as_shipping',
      'card',
      'currency_iso',
      'intent',
      'is_test',
      'order_items',
      'payment_group_id',
      'shipping_contact',
      'store_id',
      'tax_is_included',
      'user',
    ]);

    // If the intent is build, then don't attach the card info.
    if (intent === 'build') {
      orderData.card = {};
    }

    orderData = _.omitBy(orderData, _.isNull);
    if (this.order_items) {
      orderData.order_items = this.order_items.map((oi) => new OrderItem(oi).export());
    }
    orderData.customer = _.omit(stripTimestamps(this.customer), 'type', 'password');

    if (this.billing_contact) {
      orderData.billing_contact = stripTimestamps(this.billing_contact);
    }
    if (this.shipping_contact) {
      orderData.shipping_contact = stripTimestamps(this.shipping_contact);
    }

    return orderData;
  }

  _sanitizePaymentTransactions(paymentTransactions) {
    return paymentTransactions.map((pt) => {
      return Object.assign(pt, { amount: parseCurrency(pt.amount) });
    });
  }

  /**
   * Accepts a catalog item and returns a cart item.
   * A cart item is a small subset of order_item information
   * that is used during order creation or when patching
   * an order with modified order items.
   *
   * @param {object} catalogItem
   * @returns {object} orderCartItem
   */
  static catalogItemToOrderCartItem(catalogItem) {
    // NOTE: I don't like adding to the decorator, since I think we will
    // probably remove it in the future, but this method is currently
    // duplicated and this seems like the logical place for it to live
    // for now.
    return {
      name: catalogItem.name,
      catalog_item_id: catalogItem.id,
      price: +catalogItem.price,
      quantity: 1,
      total_price: +catalogItem.price * 1,
    };
  }
}
