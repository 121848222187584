import React from 'react';
import _ from 'lodash';

import { MoneyOff, MonetizationOn } from '@material-ui/icons';

export default function OrderItemToggleFundingLink({
  order_item,
  handlePopover = _.noop,
  handlePopoverClose = _.noop,
  setActiveOrderItem,
  handleDetachPopover = _.noop,
  handleDetachPopoverClose = _.noop,
  setTargetDetachOrderItem,
  canEdit,
}) {
  // TODO: find out why the popover isn't working here

  // If the item has already been refunded, don't show the funding link
  if (order_item?.is_refunded) {
    return null;
  }

  // the item is funded, allow detaching the funding
  if (order_item?.funding_transaction_id) {
    if (canEdit) {
      return (
        <span
          onClick={async () => {
            setTargetDetachOrderItem(order_item);
          }}
          onMouseEnter={handleDetachPopover}
          onMouseLeave={handleDetachPopoverClose}
        >
          <MonetizationOn title="Detach Funding" />
        </span>
      );
    } else {
      return (
        <span
          // onMouseEnter={handleDetachPopover}
          // onMouseLeave={handleDetachPopoverClose}
          title="This item is attached to funding"
          className="icon disabled"
        >
          <MonetizationOn />
        </span>
      );
    }
  } else {
    if (canEdit) {
      return (
        <span
          onMouseEnter={handlePopover}
          onMouseLeave={handlePopoverClose}
          onClick={(event) => {
            event.preventDefault();
            setActiveOrderItem(order_item);
          }}
          title="Attach Funding"
        >
          <MoneyOff />
        </span>
      );
    } else {
      return (
        <span
          // onMouseEnter={handlePopover}
          // onMouseLeave={handlePopoverClose}
          title="This item is not funded or attached to funding"
          className="icon disabled"
        >
          <MoneyOff />
        </span>
      );
    }
  }
}
