import React, { useState } from 'react';
import * as Yup from 'yup';

// Components
import { Grid, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import Button from 'components/Button';

// hooks
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from 'hooks/useAuth';

const formSchema = Yup.object().shape({
  order_id: Yup.string().uuid().required(),
  body: Yup.string().required(),
});

export default function NoteModal({ cancelCb, saveCb, order_id, open, ...props }) {
  const auth = useAuth();
  const { client } = auth;
  const classes = useStyles();

  const [unknownError, setUnknownError] = useState(null);

  async function saveNote(values) {
    let { data } = await client.post(`/notes`, values);
    saveCb(data);
  }

  return (
    <Dialog open={open} fullWidth={true}>
      <DialogTitle>Attach Note to order</DialogTitle>
      <DialogContent>
        {unknownError && <div className="error">{unknownError}</div>}
        <Formik
          initialValues={{
            body: '',
            order_id,
          }}
          onSubmit={async (values) => {
            setUnknownError(null);
            try {
              await saveNote(values);
            } catch (error) {
              setUnknownError(error.message ?? 'There was an unknown error');
            }
          }}
          validationSchema={formSchema}
        >
          {({ isSubmitting }) => (
            <Form>
              <Grid container>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="body"
                    type="text"
                    InputProps={{ rows: 5 }}
                    variant="outlined"
                    multiline
                    classes={{ root: classes.textField }}
                  />
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => {
                      cancelCb();
                    }}
                    variant="contained"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

// Styles
const useStyles = makeStyles({
  textField: {
    width: '100%',
  },

  button: {
    margin: '4px 5px',
  },
});
