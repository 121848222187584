import _ from 'lodash';
import pkg from '../../../package.json';

// Components
import { Drawer, List, Chip } from '@material-ui/core';
import Button from 'components/Button';
import LinkGroup from './LinkGroup';
import NavLink from 'components/NavLink';
import StoreLink from 'components/StoreLink';
import StoreSelector from 'components/form/StoreSelector';

// Hooks
import { useEffect } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { makeStyles } from '@material-ui/core/styles';

export default function SideBar(props) {
  const { ability, store, stores, fetchStores, validateToken, signout, token } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const loginMatch = useRouteMatch();

  const classes = useStyles();

  useEffect(() => {
    (async () => {
      if (!validateToken()) {
        return;
      }

      await fetchStores();
    })();
  }, [token, fetchStores, validateToken]);

  const isTokenValid = validateToken();
  const showLoginButton = !loginMatch?.isExact && !isTokenValid;
  const showLogoutButton = isTokenValid;
  const showNavMenu = isTokenValid && store;
  // NOTE@amplify: We need to do this because AWS Amplify won't allow us to override NODE_ENV
  const theEnv = process.env.REACT_APP_BUILD_ENV || process.env.NODE_ENV;
  const versionEnv = theEnv === 'production' ? '' : `@${theEnv}`;

  return (
    <Drawer variant="permanent" classes={{ root: classes.root }} {...props}>
      {!_.isEmpty(stores) && <StoreSelector items={stores} />}
      {showNavMenu && (
        <List>
          <LinkGroup title="Customer Service">
            {store && (
              <>
                {ability.can('access', 'customers') && (
                  <NavLink to={`/store/${store.id}/customers`}>Customers</NavLink>
                )}
                {ability.can('access', 'orders') && (
                  <NavLink to={`/store/${store.id}/orders`}>Orders</NavLink>
                )}
              </>
            )}

            {ability.can('access', 'products') && <NavLink to="/products">Products</NavLink>}
            {/* Discount codes are not yet implemented. */}
            {/* <Link to="/discount_codes">
              <ListItem button>Discount Codes</ListItem>
            </Link> */}
          </LinkGroup>

          {ability.can('access', 'products') && (
            <LinkGroup title="Products">
              <StoreLink route="/catalogs" title="Catalogs" />
            </LinkGroup>
          )}

          {ability.can('access', 'reports') && (
            <LinkGroup title="Reports">
              <StoreLink route="/reports/order_summary" title="Order Summary" />
              <StoreLink route="/reports/order_details" title="Order Details" />
              <StoreLink route="/reports/transaction_summary" title="Transaction Summary" />
              <StoreLink route="/reports/product_summary" title="Product Summary" />
            </LinkGroup>
          )}

          {ability.can('access', 'payments') && (
            <LinkGroup title="Payments">
              <StoreLink route="/processors" title="Processors" />
              <StoreLink route={`/payment_groups`} title="Payment Groups" />
            </LinkGroup>
          )}

          {ability.can('access', 'stores') && (
            <LinkGroup title="Stores">
              <NavLink to="/stores">Stores</NavLink>
            </LinkGroup>
          )}

          {ability.can('access', 'providers') && (
            <LinkGroup title="Providers">
              <StoreLink route="/email_providers" title="Email Providers" />
              <StoreLink route="/fulfillment_providers" title="Fulfillment Providers" />
            </LinkGroup>
          )}

          {ability.can('access', 'permissions') && (
            <LinkGroup title="Permissions">
              <NavLink to="/admin/users">Users</NavLink>
              <NavLink to="/admin/roles">Roles</NavLink>
            </LinkGroup>
          )}
        </List>
      )}
      {showLogoutButton && (
        <Button
          onClick={(event) => {
            signout();
            history.push('/');
          }}
        >
          Signout
        </Button>
      )}
      {showLoginButton && (
        <Button
          onClick={(event) => {
            history.push('/login', { from: location });
          }}
        >
          Login
        </Button>
      )}
      <Chip label={`v${pkg.version}${versionEnv}`} className={classes.version} />
    </Drawer>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiListItem-root': {
      padding: '0 !important',
    },
  },

  version: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[600],
  },
}));
