import React from 'react';
import GeneralModal from 'components/modals/GeneralModal';
import 'components/form/NewContactForm';
import NewContactForm from 'components/form/NewContactForm';

import { makeStyles } from '@material-ui/core/styles';

// TODO: combine this with the NewContactModal component
export default function SaveContactModal({ dispatch, state, ...props }) {
  const classes = useStyles();

  let { customer } = state;
  return (
    <GeneralModal
      dispatch={dispatch}
      state={state}
      maxWidth="md"
      classes={{ paper: classes.paper }}
      {...props}
    >
      <NewContactForm dispatch={dispatch} userId={customer.id} contact={state.edit_contact} />
    </GeneralModal>
  );
}

const useStyles = makeStyles({
  closeButton: {
    position: 'absolute',
    top: '2px',
    right: '2px',
    cursor: 'pointer',
  },
  titleBar: {
    position: 'relative',
  },

  transactionField: {
    margin: '3px 10px',
  },

  paper: {
    maxWidth: '650px',
  },
});
