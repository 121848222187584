import React, { Fragment } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EmptyStateDG from 'components/EmptyStateDG';

// hooks
import { makeStyles } from '@material-ui/core/styles';

// TODO: continue working on replacing homegrown datagrid with this one
// export default function DataGrid({ columns, name, data, ...props }) {
//   let dataGridColumns = columns;
//   let gridData = data;

//   if (_.isEmpty(data)) {
//     return '';
//   }
//   // check for old datagrid api
//   if (!_.isObject(columns[0])) {
//     // map the array position to the column field
//     console.log('first datum', data[0], '\n', data);
//     console.log('column cehck', columns[0]);
//     dataGridColumns = Object.keys(data[0]).map((field, i) => {
//       console.log('creating a key', columns[i], i, field);
//       return {
//         field,
//         headerName: columns[i],
//         type: 'string',
//       };
//     });
//   }
//   gridData = data.map((d) => {
//     return { id: uuid(), ...data };
//   });

//   console.log('gridData', gridData);
//   console.log('columns', dataGridColumns);

//   return <MDataGrid columns={dataGridColumns} rows={gridData} pageSize={30} />;
// }

export default function DataGrid({ data, columns = [], name, decorator, id = 'id', ...props }) {
  const classes = useStyles();
  if (!data || !data.length) {
    return <EmptyStateDG />;
  }
  let gridData;

  gridData = data;
  if (decorator) {
    gridData = decorator(data);
  }

  return (
    <TableContainer className={classes.container}>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            {columns.map((column) => {
              return <TableCell key={`header-${name}-${column}`}>{column}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {gridData.map((d, j) => {
            return (
              <Fragment key={`fragment-${name}-${d[id]}`}>
                <TableRow>
                  {Object.keys(d)
                    .filter((k) => k !== 'id')
                    .map((prop, i) => {
                      return <TableCell key={`${name}-${prop}-${d[id] ?? i}`}>{d[prop]}</TableCell>;
                    })}
                </TableRow>
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  container: {
    maxHeight: '100vh',
    display: 'inline-block',
    overflow: 'scroll',
  },
});
