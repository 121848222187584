import React from 'react';

import { ListItem, Grid, ListItemText, Checkbox, FormControlLabel } from '@material-ui/core';
import Currency from 'components/Currency';

import { useOrder } from 'contexts/order.context';
import { makeStyles } from '@material-ui/core/styles';

export default function ProductListItem({ order_item, currencyCodeISO }) {
  const classes = useStyles();
  const { dispatch } = useOrder();

  return (
    <ListItem disableGutters={true}>
      <ListItemText>
        <Grid container item xs={12} spacing={1} alignItems="center" className={classes.container}>
          <Grid container item xs={12} className={classes.header}>
            <Grid item xs={5}>
              Name
            </Grid>
            <Grid item xs={2}>
              Quantity
            </Grid>
            <Grid item xs={2}>
              Price
            </Grid>
            <Grid item xs={2}>
              Line Total
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={5}>
              <span className={classes.productName}>{order_item.name || order_item.variant.name}</span>
            </Grid>
            <Grid item xs={2}>
              {order_item.quantity}
            </Grid>
            <Grid item xs={2}>
              <Currency amount={order_item.price} currencyCodeISO={currencyCodeISO} />
            </Grid>
            <Grid item xs={2}>
              <Currency
                amount={+order_item.price * order_item.quantity}
                currencyCodeISO={currencyCodeISO}
              />
            </Grid>
            <Grid item xs={1}>
              {order_item?.funding_transaction && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        order_item['meta:funding_detached'] === true ||
                        order_item?.meta?.funding_detached === true
                      }
                      onChange={() => {
                        dispatch({ type: 'SET-DETACH-FUNDING', order_item });
                      }}
                      name={`remove_funding_${order_item.id}`}
                    />
                  }
                  label="remove funding"
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </ListItemText>
    </ListItem>
  );
}

const useStyles = makeStyles({
  fixPaperRoot: {
    flexGrow: 1,
  },

  productName: {},

  removeProductIcon: {
    cursor: 'pointer',
    color: '#5A7A94',

    '&:hover': {
      color: '#346a97',
    },
  },

  header: {
    fontWeight: 'bold',
    fontSize: '13px',
  },
});
