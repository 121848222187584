import { useState, useEffect } from 'react';
import _ from 'lodash';

import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import Button from 'components/Button';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  button: {
    marginRight: '3px',
  },
});

export default function DialogConfirm({
  question = '',
  open = false,
  confirmText = 'Are you sure?',
  cancelText = 'Cancel',
  inFlightText = 'Processing',
  cancelFn,
  confirmFn,
  ...props
}) {
  const classes = useStyles();

  const [enabled, setEnabled] = useState(true);
  const [message, setMessage] = useState(question);

  // REVIEW: not totally sure about the pitfalls of this pattern
  // seems to work here. might be a more sanctioned way
  useEffect(() => {
    setMessage(question);
  }, [question]);

  function handleConfirm() {
    setEnabled(false);
    setMessage(inFlightText);

    // defer here to allow the rerenders to happen (since they're async)
    // to update messaging and disable buttons during cancel order api call
    _.defer(async () => {
      await confirmFn();
      setEnabled(true);
      setMessage(question);
    });
  }

  return (
    <Dialog open={open}>
      <DialogTitle>{message}</DialogTitle>
      <DialogContent>
        <Button
          className={classes.button}
          onClick={() => {
            cancelFn();
          }}
          variant="contained"
          disabled={!enabled}
        >
          {cancelText}
        </Button>
        <Button
          className={classes.button}
          onClick={handleConfirm}
          variant="contained"
          color="primary"
          disabled={!enabled}
        >
          {confirmText}
        </Button>
      </DialogContent>
    </Dialog>
  );
}
