import _ from 'lodash';
import { rekeyDataStateFields } from 'lib/datagrid_helpers';

// components
import { DateTimeCell, LinkCell } from 'lib/datagrid_formatters';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';

// hooks
import { useState, useCallback, useEffect, useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';

function CustomerNameCell(props) {
  const { field, dataItem, storeId } = props;
  const name = _.get(dataItem, field);
  const id = _.get(dataItem, 'id');
  const url = `/store/${storeId}/customers/${id}`;
  return <LinkCell url={url} text={name} {...props} />;
}

const initDataState = {
  skip: 0,
  take: 10,
  group: [],
  sort: [{ field: 'created_at', dir: 'desc' }],
};

/**
 * A grid component for displaying customers.
 * @param {object} props
 * @param {object[]} props.rows The data to display in the grid
 * @param {function} [props.handleDataState] The function to call when the grid data state changes
 * @returns {JSX.Element} A grid component
 */
export default function CustomersDG2({ rows, handleDataState, ...props }) {
  const { params } = useRouteMatch();
  const { store_id } = params;

  const [dataState, setDataState] = useState(initDataState);
  const gridRef = useRef(null);

  const onDataStateChange = useCallback(
    (event) => {
      const { dataState } = event;
      setDataState(dataState);

      if (handleDataState) {
        handleDataState(rekeyDataStateFields(event));
      }
    },
    [handleDataState]
  );

  // When the grid data changes, auto-fit the column widths to the data.
  useEffect(() => {
    const { current: grid } = gridRef;
    if (grid) {
      grid.fitColumns(grid.columns.map((col) => col.id));
    }
  }, [rows]);

  const gridProps = {
    resizable: true,
    sortable: true,
    pageable: {
      pageSizes: [10, 25, 50, 100, 200, 400],
    },
    ...props,
  };

  // NOTE@grid: The dbField prop is used to specify the column name as it would
  // appear in the database query, usually prefixed with the relation name.
  return (
    <Grid
      {...gridProps}
      data={rows.data}
      total={rows.total}
      onDataStateChange={onDataStateChange}
      {...dataState}
      dataItemKey="id"
      ref={gridRef}
    >
      <Column
        field="created_at"
        dbField="users.created_at"
        title="Created At"
        cell={DateTimeCell}
        width="150px"
      />
      <Column field="slug" dbField="users.slug" title="ID" width="200px" />
      <Column
        field="name"
        dbField="users.name"
        title="Name"
        width="200px"
        cell={(props) => <CustomerNameCell {...props} storeId={store_id} />}
      />
      <Column field="email" dbField="users.email" title="Email" width="220px" />
      <Column field="phone_number" dbField="main_contact.phone_number" title="Phone" width="180px" />
      {/* <Column field="city" dbField="main_contact.city" title="City" width="150px" /> */}
      <Column field="state" dbField="main_contact.state" title="State" width="120px" />
      <Column field="postal_code" dbField="main_contact.postal_code" title="Zip" width="100px" />
      <Column field="country_iso" dbField="main_contact.country_iso" title="Country" width="120px" />
    </Grid>
  );
}
