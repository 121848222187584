import { ArrowDropDown } from '@material-ui/icons';
import { Menu, MenuItem } from '@material-ui/core';
import Button from 'components/Button';

import { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

export default function DropDownButton({ children, menuItems, ...props }) {
  const [buttonAnchor, setButtonAnchor] = useState(null);

  const popoverOpen = Boolean(buttonAnchor);
  const classes = useStyles();

  const buttonContainer = useRef(null);

  return (
    <Button variant="contained" color="primary" ref={buttonContainer} {...props}>
      {children}
      <ArrowDropDown
        classes={{ root: classes.dropDownButton }}
        onClick={(event) => {
          event.stopPropagation();

          setButtonAnchor(buttonContainer.current);
        }}
      />
      <Menu
        open={popoverOpen}
        getContentAnchorEl={null}
        anchorEl={buttonAnchor}
        onClose={(event) => {
          event.stopPropagation();
          setButtonAnchor(null);
        }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        {menuItems.map((item) => (
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              item.onClick(event);
              setButtonAnchor(null);
            }}
            key={item.id}
          >
            {item.text}
          </MenuItem>
        ))}
      </Menu>
    </Button>
  );
}

const useStyles = makeStyles({
  dropDownButton: {
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#2196f3',
      borderRadius: '2px',
    },
  },
});
