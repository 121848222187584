import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import S from 'lib/stringomatic';
import _ from 'lodash';

// Components
import { Button, Grid } from '@material-ui/core';
import { Formik, Form } from 'formik';
import Header from 'components/Header';
import OTextField from 'components/form/OTextField';
import PageSection from 'components/PageSection';
import PageLoading from 'components/Loading/PageLoading';

// Hooks
import { useParams, useHistory } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

// exists to format the input name to a friendlier string
const requiredMessage = ({ path }) => {
  return `${S.humanize(path)} is Required`;
};

const formSchema = yup.object().shape({
  name: yup.string().required(requiredMessage),
  post_sale_expiration: yup.number().required(requiredMessage),
  order_confirmation_email_delay: yup.number().required(requiredMessage),
});

export default function StoresEditPage() {
  const { client } = useAuth();
  const { store_id } = useParams();
  const history = useHistory();

  const [store, setStore] = useState(null);

  useEffect(() => {
    (async () => {
      let { data } = await client.get(`/admin/stores/${store_id}`);

      let { configuration, ...storeData } = data;

      Object.assign(
        storeData,
        _.pick(configuration, 'post_sale_expiration', 'order_confirmation_email_delay')
      );
      setStore(storeData);
    })();
  }, [client, store_id]);

  if (!store) {
    return <PageLoading />;
  }

  return (
    <Grid container>
      <Header heading="Create a new Store" />
      <PageSection>
        <Grid item container>
          <Formik
            initialValues={store}
            onSubmit={async ({ name, post_sale_expiration, order_confirmation_email_delay }) => {
              let storeData = {
                name,
                configuration: {
                  post_sale_expiration,
                  order_confirmation_email_delay,
                },
              };
              await client.patch(`/admin/stores/${store_id}`, storeData);
              history.push(`/stores`);
            }}
            validationSchema={formSchema}
          >
            {({ isSubmitting, touched }) => {
              return (
                <Form>
                  <Grid container>
                    <Grid item xs={12}>
                      <OTextField name="name" label="Name" />
                    </Grid>
                    <h3>Orders</h3>
                    <Grid item xs={12}>
                      <OTextField name="post_sale_expiration" label="Post Sale Expiration Delay" />
                    </Grid>

                    <h3>Notifications</h3>
                    <Grid item xs={12}>
                      <OTextField
                        name="order_confirmation_email_delay"
                        label="Order Confirmation Email Delay"
                      />
                    </Grid>

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting || _.isEmpty(touched)}
                    >
                      Save
                    </Button>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </PageSection>
    </Grid>
  );
}
