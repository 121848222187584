import React from 'react';
import { FormControlLabel, Radio } from '@material-ui/core';

export default function RadioList({ list }) {
  return list.map((radio, i) => (
    <FormControlLabel
      value={radio.value}
      control={<Radio />}
      label={radio.label}
      name={radio.name}
      key={`radio-${radio.value}-${i}-${radio.name}`}
    />
  ));
}
