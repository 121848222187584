import React, { useEffect, useState } from 'react';
import _ from 'lodash';

// components
import { Grid, Checkbox } from '@material-ui/core';
import Header from 'components/Header';
import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import ConfirmModal from 'components/modals/ConfirmModal';
import { Link } from 'react-router-dom';
import AppBarButton from 'components/AppBarButton';

// hooks
import { useAuth } from 'hooks/useAuth';

import { useParams, useHistory, useRouteMatch } from 'react-router';

function ActionsCell({ className = 'actions-cell', id, handleDelete, ...props }) {
  const { url } = useRouteMatch();

  return (
    <div className={className}>
      <Link to={`${url}/${id}`}>Edit</Link>
      <span className="link" onClick={handleDelete}>
        Delete
      </span>
    </div>
  );
}

export default function EmailProvidersIndexPage() {
  const { client } = useAuth();
  const { store_id } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch();

  const [emailProviders, setEmailProviders] = useState([]);
  const [activeDelete, setActiveDelete] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);

  const refresh = () => setForceRefresh(!forceRefresh);

  useEffect(() => {
    (async () => {
      let { data } = await client.get(`/admin/email_providers`, { params: { store_id } });
      setEmailProviders(data);
    })();
  }, [store_id, forceRefresh, client]);

  return (
    <Grid container item xs={12}>
      <Header heading="Email Providers">
        <AppBarButton
          onClick={() => {
            history.push(`${url}/new`);
          }}
        >
          Add Email Provider
        </AppBarButton>
      </Header>
      <Grid container item xs={12}>
        <GridComponent dataSource={emailProviders}>
          <ColumnsDirective>
            <ColumnDirective field="name" headerText="Name" />
            <ColumnDirective
              field="default"
              headerText="Default Provider?"
              template={({ is_default }) => (
                <Checkbox type="checkbox" checked={is_default} inputProps={{ readOnly: true }} />
              )}
            />
            <ColumnDirective
              headerText="Actions"
              template={(props) => (
                <ActionsCell
                  handleDelete={(event) => {
                    event.preventDefault();

                    setActiveDelete(props);
                  }}
                  {...props}
                />
              )}
            />
            {/* <ColumnDirective headerText="Actions" template={ActionsCell} /> */}
          </ColumnsDirective>
        </GridComponent>
      </Grid>
      <ConfirmModal
        open={Boolean(activeDelete)}
        handleConfirm={async () => {
          await client.delete(`/admin/email_providers/${activeDelete?.id}`);
          refresh();
          setActiveDelete(null);
        }}
        handleClose={() => {
          setActiveDelete(null);
        }}
        dispatch={_.noop}
        title={activeDelete && `Delete Email Provider, "${activeDelete?.name}"?`}
        maxWidth="xs"
      />
    </Grid>
  );
}
