import React from 'react';
import _ from 'lodash';
import { cellCurrencyFn, cellDateTime } from 'lib/datagrid_formatters';

import { LocalShipping, LocalShippingTwoTone } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import {
  ColumnDirective,
  ColumnMenu,
  ColumnsDirective,
  GridComponent,
  Inject,
  Resize,
  Page,
  Group,
} from '@syncfusion/ej2-react-grids';

// hooks
import { useRouteMatch } from 'react-router-dom';

const pageSettings = {
  pageSize: 10,
  pageSizes: [5, 10, 20, 50, 100, 200, 'All'],
};

const cellCurrency = _.partialRight(cellCurrencyFn, 'currency_iso');

export default function OrdersDG2({ rows, hideColumns = [], ...props }) {
  const { params } = useRouteMatch();
  const { store_id } = params;

  const gridProps = {
    pageSettings,
    allowResizing: true,
    allowGrouping: true,
    allowPaging: true,
    showColumnMenu: true,
    ...props,
  };

  const cellOrderSlug = (props) => {
    const { column } = props;
    const { field } = column;
    const id = _.get(props, field);
    const slug = _.get(props, 'slug');
    return <Link to={`/store/${store_id}/orders/${id}`}>{slug}</Link>;
  };

  const cellCustomerEmail = (props) => {
    const { column } = props;
    const { field } = column;
    const customer_email = _.get(props, field);
    const customer_id = props.customer?.id;
    return <Link to={`/store/${store_id}/customers/${customer_id}`}>{customer_email}</Link>;
  };

  const cellCustomerFullname = (props) => {
    const contact = props.shipping_contact ?? props.billing_contact ?? {};
    return `${contact.first_name} ${contact.last_name}`;
  };

  const cellShipStatus = (props) => {
    const { column } = props;
    const { field } = column;
    const value = _.get(props, field);
    if (value === 'complete')
      return (
        <span title="complete">
          <LocalShipping />
        </span>
      );

    if (value === 'partial')
      return (
        <span title="partial">
          <LocalShippingTwoTone title="partial" />
        </span>
      );

    return '';
  };

  return (
    <GridComponent dataSource={rows} {...gridProps}>
      <Inject services={[Resize, Page, ColumnMenu, Group]} />
      <ColumnsDirective>
        <ColumnDirective
          field="id"
          headerText="Order Id"
          width="200"
          template={cellOrderSlug}
          allowGrouping={false}
        />
        <ColumnDirective field="status" headerText="Status" width="150" />
        <ColumnDirective field="total_amount" headerText="Total" template={cellCurrency} width="120" />
        <ColumnDirective field="customer.name" headerText="Name" template={cellCustomerFullname} />
        <ColumnDirective field="customer.email" headerText="Email" template={cellCustomerEmail} />
        <ColumnDirective field="shipping_contact.country_iso" headerText="Country" width="120" />
        <ColumnDirective field="created_at" headerText="Created At" template={cellDateTime} />
        <ColumnDirective field="session.utm_campaign" headerText="Campaign" />
        <ColumnDirective
          field="ship_status"
          headerText="Ship Status"
          template={cellShipStatus}
          width="140"
        />
      </ColumnsDirective>
    </GridComponent>
  );
}
