import React, { useReducer, useMemo } from 'react';

function reducer(state, action) {
  switch (action.type) {
    case 'REQUEST-ERROR':
      return state;

    // case 'UNAUTHORIZED-NETWORK':
    //   return { ...state, message: 'Your login has expired. Please login again.' };
    case 'REDIRECT':
      return { ...state, redirect: undefined };

    case 'SET-STORE':
      return { ...state, store: action.store };
    case 'UNSET-STORE':
      let { store, ...sansStoreState } = state;
      return sansStoreState;
    case 'SET-TITLE':
      return { ...state, pageTitle: action.pageTitle };

    // Used for enabling and disabling features in the interface programmatically
    case 'ENABLE-FEATURE':
      return { ...state, features: { ...state.features, [action.feature]: true } };

    case 'DISABLE-FEATURE':
      return { ...state, features: { ...state.features, [action.feature]: false } };

    case 'LOADING-START':
      return { ...state, loading: true, message: action.message };

    case 'LOADING-DONE':
      return { ...state, loading: false };

    case 'DISPLAY-ERROR':
      return {
        ...state,
        message: action.message || action?.error?.message,
        error: action?.error,
        redirect: action.redirect,
      };
    case 'CLEAR-ERROR':
      return {
        ...state,
        message: undefined,
        error: undefined,
        redirect: undefined,
      };

    default:
      throw new Error(`Dispatch ${action.type} not handled`);
  }
}

const ApplicationContext = React.createContext();

function ApplicationProvider(props) {
  const [state, dispatch] = useReducer(reducer, {
    state: '',
    features: {
      breadcrumbs: false,
    },
  });
  const value = useMemo(() => [state, dispatch], [state]);

  return <ApplicationContext.Provider value={value} {...props} />;
}

function useApplication() {
  const context = React.useContext(ApplicationContext);
  if (!context) {
    throw new Error('useApplication must be used within an ApplicationProvider');
  }

  const [state, dispatch] = context;

  return {
    state,
    dispatch,
  };
}

export { ApplicationProvider, useApplication };
