import React from 'react';
import PageSection from 'components/PageSection';
import DataGrid from 'components/DataGrid';

import { formatDateTime } from 'lib/helpers';

export default function NotesSection({ notes = [] }) {
  // TODO: need to fetch actual user info
  // will probably be easier to rework to use objection model and use withGraphFetched

  const dgData = notes.map((note) => ({
    id: note.id,
    body: note.body,
    user: note?.user?.name,
    created_at: formatDateTime(note.created_at),
  }));
  return (
    <PageSection header="Notes">
      {notes.length ? (
        <DataGrid name="notes" rows={['Note', 'User', 'Created At']} data={dgData} />
      ) : (
        <div>No notes at this time</div>
      )}
    </PageSection>
  );
}
