import classnames from 'classnames';

// components
import { Grid, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import HeaderSubsection from './HeaderSubsection';

// Hooks
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

export default function ContactCard({ contact, dispatch }) {
  const c = contact;
  const classes = useStyles();

  const [isExpanded, setIsExpanded] = useState(false);

  function handleEdit() {
    dispatch({ type: 'EDIT-CONTACT', contact });
  }

  const maxLength = 19;

  let description = c.description;
  if (description && description.length > maxLength) {
    description = description.substr(0, maxLength) + '...';
  }

  return (
    <Grid item container classes={{ root: classes.root }} xs={2}>
      <Grid item xs={12} classes={{ root: 'contact-name' }}>
        <HeaderSubsection>{`${contact?.first_name} ${contact?.last_name}`}</HeaderSubsection>
        <EditIcon onClick={handleEdit} style={{ cursor: 'pointer' }} className={classes.editIcon} />
      </Grid>
      <Grid item xs={12} classes={{ root: 'contact-type' }}>
        <Typography variant="subtitle2">{`${contact?.type}`}</Typography>
      </Grid>
      <Grid item xs={12} classes={{ root: 'address-container' }}>
        {/* <ContactAddress contact={c} /> */}

        <div className={classnames('contact-card', classes.root)}>
          <div className={classnames('description', classes.selected)}>{contact.description}</div>
          {contact.email && (
            <span className={classnames('email', classes.subtitle)}>{contact.email}</span>
          )}
          <address className={classes.address}>{contact.address_1}</address>

          <div className={classnames('detail', classes.showMore, { [classes.expanded]: isExpanded })}>
            {contact.address_2 && <address className={classes.address}>{contact.address_2} </address>}
            {contact.city && (contact.state || contact.postal_code) && (
              <div className={classnames('city', classes.address)}>
                {contact.city}, {contact.state} {contact.postal_code}
              </div>
            )}
          </div>
          <div
            className={classnames('show-more-link', classes.showMoreLink)}
            onClick={() => {
              setIsExpanded((prev) => !prev);
            }}
          >
            {isExpanded ? 'Show Less' : 'Show More'}
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.listItem.background,
    maxWidth: '230px',
    margin: '2px',
    padding: '0 2px',
  },

  editIcon: {
    color: theme.palette.primary.main,
    float: 'right',

    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },

  addressRoot: { fontWeight: 'normal !important' },

  selected: {
    fontSize: '.8em',
  },

  subtitle: {
    color: '#0C2D48',
    fontSize: '1em',
    paddingLeft: '1.2rem',
  },

  address: {
    paddingLeft: '1.2rem',
    lineHeight: '1.7rem',
  },

  showMore: {
    height: 0,
    overflow: 'hidden',
    transition: 'height .5s ease',
  },

  expanded: {
    height: '4rem',
  },

  showMoreLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: '.8em',

    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}));
