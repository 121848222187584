import React, { useEffect } from 'react';
import Plotly from 'plotly.js-dist';
import Loader from './Loader';

export default function BarChart({ classes = {}, ...props }) {
  let { id, data, title } = props;

  useEffect(() => {
    if (!data) return;

    let layout = {
      title,
      showlegend: false,
      bargap: 0.05,
      autosize: true,
    };

    Plotly.react(id, data, layout, { responsive: true });
  }, [data, id, title]);

  if (!data) {
    return <Loader />;
  }
  data[0].type = 'bar';

  return <div id={id} className={classes.root}></div>;
}
