import React, { Fragment } from 'react';
import classnames from 'classnames';

// components
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import NewContactModal from './NewContactModal';
import ContactAddress from 'components/ContactAddress';

// hooks
import { makeStyles } from '@material-ui/core/styles';

export default function ContactSelector({
  user,
  id = 'contact-selector',
  handleSelect,
  label,
  value,
  contacts,
  dispatch,
  userId,
  type,
  modal_display,
  ...props
}) {
  let labelId = `label-${id}`;

  const classes = useStyles();

  function showNewContactModal() {
    dispatch({ type: `open-${type}-modal` });
  }

  function handleChange(event) {
    let { value } = event.target;

    if (value === 'new' && userId) {
      return showNewContactModal();
    }

    handleSelect(value);
  }

  return (
    <Fragment>
      <FormControl className={classes.root}>
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
          labelId={labelId}
          id={userId}
          value={value}
          onChange={handleChange}
          renderValue={(value) => <ContactAddress contact={value} />}
        >
          {contacts.map((c) => {
            return (
              <MenuItem
                value={c}
                key={`contact-selector-${c.id}`}
                classes={{ root: classes.contactOption, selected: classes.contactOptionSelected }}
              >
                <div>
                  <div className="description" style={{ fontSize: '.7em' }}>
                    {c.description}
                  </div>
                  <span className={classnames(classes.address, classes.subtitle)}>{c.email}</span>{' '}
                  <address className={classes.address}>
                    {c.address_1} {c?.city}, {c?.state} {c?.postal_code}
                  </address>
                </div>
                {/* <ContactAddress contact={c} /> */}
              </MenuItem>
            );
          })}
          <MenuItem
            value="new"
            classes={{
              root: classnames(classes.contactOption, { [classes.disabled]: !userId }),
              selected: classes.contactOptionSelected,
            }}
          >
            Create New Contact
          </MenuItem>
        </Select>
      </FormControl>
      <NewContactModal dispatch={dispatch} userId={userId} type={type} modal_display={modal_display} />
    </Fragment>
  );
}

const useStyles = makeStyles({
  root: {},
  contactOption: {
    '& .description': {
      fontWeight: 'bold',
      marginRight: '.5rem',
    },
  },
  contactOptionSelected: {
    backgroundColor: '#ffffcf !important',
  },

  disabled: {
    color: '#efefef !important',
  },

  address: {
    paddingLeft: '1.2rem',
    fontSize: '1em',
  },

  subtitle: {
    fontWeight: 'bold',
    paddingLeft: '1.2rem',
    fontSize: '1em',
  },
});
