import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { CheckboxWithLabel } from 'formik-material-ui';
import { Field } from 'formik';

export default function OCheckBox({ label, ...props }) {
  return <FormControlLabel control={<Checkbox {...props} />} label={label} />;
}

function OFormikCheckBox({ label, ...props }) {
  return <Field component={CheckboxWithLabel} type="checkbox" Label={{ label }} {...props} />;
}

OFormikCheckBox.propTypes = {
  label: PropTypes.string.isRequired,
};

export { OFormikCheckBox };
