import React from 'react';
import { cellDateTime } from 'lib/datagrid_formatters';
import _ from 'lodash';

import { LocalShipping } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Resize,
  Page,
} from '@syncfusion/ej2-react-grids';

export default function FulfillmentsDG2({ rows, hideColumns = [], autoFit = false, ...props }) {
  const pageSettings = {
    pageSize: 10,
    pageSizes: [5, 10, 20, 50, 100, 200, 'All'],
  };

  const gridProps = {
    pageSettings,
    allowResizing: true,
    allowPaging: true,
    ...props,
  };

  const colVisible = (column) => !hideColumns.includes(column);

  const cellOrderSlug = (props) => {
    const { column } = props;
    const { field } = column;
    const id = _.get(props, field);
    const slug = _.get(props, 'order.slug');
    return <Link to={`/orders/${id}`}>{slug}</Link>;
  };

  const cellShipStatus = (props) => {
    const { column } = props;
    const { field } = column;
    const value = _.get(props, field);
    if (value === 'shipped')
      return (
        <span title="shipped">
          <LocalShipping />
        </span>
      );
    return '';
  };

  const headStatusIcons = (props) => {
    return <LocalShipping />;
  };

  const cellShipmentItems = (props) => {
    const { column } = props;
    const { field } = column;
    const items = _.get(props, field);
    return (
      <ul>
        {items.map((oi) => (
          <li
            style={{ whiteSpace: 'nowrap' }}
            key={`summary-${oi.id}`}
          >{`${oi.quantity} ${oi.variant.sku}, ${oi.variant.name}`}</li>
        ))}
      </ul>
    );
  };

  const cellShippingAddress = (props) => {
    const contact = _.get(props, 'shipping_contact');
    return `${contact.address_1.substring(0, 25)}...${contact.postal_code}`;
  };

  return (
    <GridComponent dataSource={rows} {...gridProps}>
      <Inject services={[Resize, Page]} />
      <ColumnsDirective>
        <ColumnDirective
          field="status"
          headerText="Status Icons"
          width="80"
          template={cellShipStatus}
          headerTemplate={headStatusIcons}
          autoFit={autoFit}
        />
        <ColumnDirective
          field="order.id"
          headerText="Order Id"
          width="200"
          template={cellOrderSlug}
          visible={colVisible('Order Id')}
          autoFit={autoFit}
        />
        <ColumnDirective
          field="customer.name"
          headerText="Name"
          visible={colVisible('Name')}
          autoFit={autoFit}
        />
        <ColumnDirective field="carrier" headerText="Carrier" width="150" autoFit={autoFit} />
        <ColumnDirective field="tracking_number" headerText="Tracking #" autoFit={autoFit} />
        <ColumnDirective
          field="order_items"
          headerText="Items"
          template={cellShipmentItems}
          visible={colVisible('Items')}
        />
        <ColumnDirective
          field="shipping_contact.id"
          headerText="Shipping Address"
          template={cellShippingAddress}
          visible={colVisible('Shipping Address')}
          width="200"
        />
        <ColumnDirective field="status" headerText="Status" width="150" autoFit={autoFit} />
        <ColumnDirective
          field="shipped_at"
          headerText="Shipped At"
          template={cellDateTime}
          width="150"
          autoFit={autoFit}
        />
        <ColumnDirective
          field="created_at"
          headerText="Created At"
          template={cellDateTime}
          width="150"
          autoFit={autoFit}
        />
      </ColumnsDirective>
    </GridComponent>
  );
}
