import React from 'react';

// import GeneralErrorBoundary from 'components/GeneralErrorBoundary';

function Buggy() {
  throw new Error('Sentry Test with feedback');
}

export default function SentryTestPage() {
  return (
    <div id="sentry-test">
      {/* <GeneralErrorBoundary> */}
      <h1>Sentry Test</h1>
      <div>
        <button
          onClick={() => {
            throw new Error(`sentry test error`);
          }}
        >
          Throw Error
        </button>
        <Buggy />
      </div>
      {/* </GeneralErrorBoundary> */}
    </div>
  );
}
