import { createTheme } from '@material-ui/core/styles';
import theme from 'themes/main.theme';

const formTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,

    MuiTextField: {
      root: {
        margin: '0.5em 2px',
      },
    },

    MuiInputBase: {
      root: {
        fontSize: '13px',
        width: '20em',
      },
    },

    MuiSelect: {
      root: {
        margin: '0.5em 2px',
        paddingTop: '0',
      },
    },
    MuiSelectInput: {
      root: {
        padding: 0,
      },
    },

    MuiGrid: {
      root: {
        padding: 0,
        margin: 0,
      },
    },
  },
});

export default formTheme;
