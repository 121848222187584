import { v4 as uuid } from 'uuid';

import { Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { Field } from 'formik';
import { Select as SelectMUI } from 'formik-material-ui';

/**
 *
 * @param {object} params
 * @param {string} [params.id] The unique ID of the component
 * @param {string} params.label The display label
 * @param {string} params.value The selected value
 * @param {string[]} params.items The list of items to display
 * @param {function} params.onChange The callback to invoke when the value changes
 * @param {object} [params.classes] The classes to apply to the component
 */
export default function SimpleSelect({
  id = uuid(),
  label,
  value,
  items = [],
  onChange,
  classes = {},
  ...props
}) {
  const labelId = `${id}-select-label`;

  /**
   * The onChange handler for the select
   * @param {*} event
   */
  function handleChange(event) {
    onChange(event, event.target.value);
  }

  // REVIEW@mui: I'm not sure that this is the best way to handle styling a
  // component from the outside. Look and see how MUI recommends styling nested
  // components.
  return (
    <FormControl className={classes.root}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        classes={{ root: classes.formControl }}
        onChange={handleChange}
        value={value}
      >
        {items.map((item) => (
          <MenuItem value={item} key={`option-${item}-${id}`}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function FormikSimpleSelect({ items, label, ...props }) {
  return (
    <FormControl fullWidth={true}>
      <InputLabel>{label}</InputLabel>
      <Field component={SelectMUI} {...props}>
        {items.map((item) => (
          <MenuItem value={item.value} key={item.key}>
            {item.label}
          </MenuItem>
        ))}
      </Field>
    </FormControl>
  );
}

export { FormikSimpleSelect };
