import React from 'react';

import * as yup from 'yup';
import { requiredMessage } from 'lib/form_helpers';

// Components
import { Button, FormControl, Grid, MenuItem } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { Select } from 'formik-material-ui';
import Header from 'components/Header';
import OTextField from 'components/form/OTextField';
import PageSection from 'components/PageSection';
import { OFormikCheckBox } from 'components/form/OCheckBox';
import SecretWarningHint from 'components/SecretWarningHint';

// Hooks
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

const formSchema = yup.object().shape({
  name: yup.string().required(requiredMessage),
  api_key: yup.string().required(requiredMessage),
  api_secret: yup.string().required(requiredMessage),
  min_age: yup.number().required(requiredMessage),
  max_age: yup.number().required(requiredMessage),
  api_rate_limit: yup.number().required(requiredMessage),
  batch_size: yup.number().required(requiredMessage),
});

function InputRow({ name, label }) {
  return (
    <Grid item xs={12}>
      <OTextField name={name} label={label} />
    </Grid>
  );
}

export default function FulfillmentProvidersNewPage() {
  const { client } = useAuth();
  const { store_id } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch(`/store/:store_id`);

  return (
    <Grid container>
      <Header heading="Create a new Fulfillment Provider" />
      <PageSection>
        <Grid item container>
          <Formik
            initialValues={{
              name: '',
              type: 'shipstation',
              api_key: '',
              api_secret: '',
              store_id: '',
              min_age: 15,
              max_age: 30,
              batch_size: 5,
              api_rate_limit: 40,
              is_default: false,
              vendor_store_id: '',
            }}
            onSubmit={async (values) => {
              let {
                name,
                type,
                min_age,
                max_age,
                batch_size,
                api_rate_limit,
                api_key,
                api_secret,
                vendor_store_id,
                is_default,
              } = values;

              let fulfillmentData = {
                store_id,
                name,
                type,
                is_default,
                configuration: {
                  min_age,
                  max_age,
                  batch_size,
                  api_rate_limit,
                  vendor_store_id,
                },
                configuration_secret: {
                  api_key,
                  api_secret,
                },
              };
              await client.post(`/admin/fulfillment_providers`, fulfillmentData);
              history.push(`${url}/fulfillment_providers`);
            }}
            validationSchema={formSchema}
          >
            {({ isSubmitting }) => {
              return (
                <Form>
                  <Grid container>
                    <Grid item xs={12}>
                      <OTextField name="name" label="Name" />
                    </Grid>
                    <Grid item xs={12}>
                      <OFormikCheckBox name="is_default" id="is_default" label="Default?" />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl>
                        <Field component={Select} id="type" name="type" label="Type">
                          <MenuItem value="shipstation">Shipstation</MenuItem>
                        </Field>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <h3>
                      Authentication
                      <SecretWarningHint message="Warning: you cannot change your api key and/or secret after creation." />
                    </h3>
                    <OTextField id="api-key" name="api_key" label="api key" />
                    <OTextField id="api-secret" name="api_secret" label="api secret" />
                  </Grid>

                  <Grid container item xs={12}>
                    <h3>Shipstation Config</h3>
                    <InputRow
                      name="vendor_store_id"
                      label="Shipstation Store ID"
                      help="The ID of the Shipstation Store Into which fulfillments will be populated"
                    />
                    <InputRow name="min_age" label="Min. Age (secs)" />
                    <InputRow name="max_age" label="Max Age (secs)" />
                    <InputRow name="batch_size" label="Batch Size" />
                    <InputRow name="api_rate_limit" label="API Rate Limit" />
                  </Grid>
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                    Save
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </PageSection>
    </Grid>
  );
}
