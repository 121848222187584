import { useEffect, useState } from 'react';

// libs
import { getQueryParamsFromDataState } from 'lib/datagrid_helpers';
import { getQueryParamsFromFilterFormData } from 'lib/form_helpers';
import moment from 'moment-timezone';

// components
import { Grid } from '@material-ui/core';
import Header from 'components/Header';
import PageSection from 'components/PageSection';
import OrdersDG3 from 'components/DataGrids/OrdersDG3';
import OrderFilterFormik from 'components/form/OrderFiltersFormik';
import AppBarButton from 'components/AppBarButton';

// hooks
import { useAuth } from 'hooks/useAuth';
import { useOrder } from 'contexts/order.context';
import { useApplication } from 'contexts/application.context';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useParams, useHistory, useRouteMatch } from 'react-router-dom';

export default function OrdersIndexPage() {
  const { client } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const { url } = useRouteMatch();
  const { store_id } = useParams();

  const { dispatch: applicationDispatch } = useApplication();
  const { state, dispatch } = useOrder();
  const [orderFilters, setOrderFilters] = useState({
    'orders.created_at[$gte]': moment().startOf('month').toDate(),
    'orders.created_at[$lte]': moment().endOf('day').toDate(),
  });
  const [tableFilters, setTableFilters] = useState({
    $skip: 0,
    $limit: 10,
    '$sort[created_at]': -1,
  });

  useEffect(() => {
    (async () => {
      let params = {
        $joinEager: '[customer,session,shipping_contact]',
        store_id,
        ...orderFilters,
        ...tableFilters,
      };

      // REVIEW@objection: In order for the objection query builder to generate
      // the correct queries to handle paginated, sorted and filtered data, we
      // need to respect the following rules when it comes to how we join
      // relations.
      // If the the relation is a hasMany, then we need to use `$eager` and
      // any filtering on the relation should be handled through a modifier.
      // If the relation is a hasOne, then the following additional guidelines
      // should be applied:
      // 1. If we will be filtering on the relation, but not selecting the data
      // from that relation, then we can use `$joinRelation` to bind the relation.
      // 2. If we will be selecting the data from the relation, or plan to order
      // by or group by the relation, then we should use `$joinEager`
      // 3. All column names used for filtering, sorting and grouping should be
      // prefixed with the relation name.
      // Failing to follow these rules will result either in errors or getting
      // unexpected pagination results.

      let { data } = await client.get('/admin/orders', {
        params,
      });

      dispatch({ type: 'SET-ORDERS', orders: data });
    })();
  }, [orderFilters, tableFilters, location.key, store_id, client, dispatch]);

  useEffect(() => {
    // REVIEW@application: We probably also need a subtitle. Or perhaps we can
    // pass in an array, and nest the breadcrumbs at the top based on their order.
    applicationDispatch({ type: 'SET-TITLE', pageTitle: 'Orders' });
  }, [applicationDispatch]);

  async function handleFilterQuery(filters) {
    setOrderFilters(getQueryParamsFromFilterFormData(filters));
  }

  async function handleDataState(dataState) {
    setTableFilters(getQueryParamsFromDataState(dataState));
  }

  const classes = useStyles();

  return (
    <Grid container item xs={12} classes={{ root: classes.pageRoot }} alignContent="flex-start">
      <Header heading="Orders">
        <AppBarButton
          variant="contained"
          onClick={() => {
            history.push(`${url}/new`);
          }}
          color="primary"
        >
          Create Order
        </AppBarButton>
      </Header>

      <PageSection header="Filter">
        <OrderFilterFormik filterFn={handleFilterQuery} />
      </PageSection>
      <PageSection header="Orders" className={classes.ordersSection}>
        <OrdersDG3 rows={state.orders} handleDataState={handleDataState} />
      </PageSection>
    </Grid>
  );
}

const useStyles = makeStyles({
  pageRoot: {
    height: '95vh',
    overflow: 'auto',
  },
  ordersSection: {
    flexGrow: 1,
    minHeight: 0,
    overflow: 'auto',
    width: '80vh',
  },
});
