import React, { useEffect, useState } from 'react';
import _ from 'lodash';

// components
import { Grid } from '@material-ui/core';
import Header from 'components/Header';
import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import ConfirmModal from 'components/modals/ConfirmModal';
import { Link } from 'react-router-dom';
import AppBarButton from 'components/AppBarButton';

// hooks
import { useAuth } from 'hooks/useAuth';

import { useParams, useHistory, useRouteMatch } from 'react-router';

function ActionsCell({ className = 'actions-cell', id, handleDelete, ...props }) {
  const { url } = useRouteMatch();

  return (
    <div className={className}>
      <Link to={`${url}/${id}`}>Edit</Link>
      <span className="link" onClick={handleDelete}>
        Delete
      </span>
    </div>
  );
}

export default function CatalogsIndexPage() {
  const { client } = useAuth();
  const { store_id } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch();

  const [emailProviders, setEmailProviders] = useState([]);
  const [activeDelete, setActiveDelete] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);

  const refresh = () => setForceRefresh(!forceRefresh);

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      let { data } = await client.get(`/admin/catalogs`, {
        params: { store_id },
        signal: controller.signal,
      });
      setEmailProviders(data);
    })();
    return () => {
      controller.abort();
    };
  }, [store_id, forceRefresh, client]);

  return (
    <Grid container item xs={12}>
      <Header heading="Catalogs">
        <AppBarButton
          onClick={() => {
            history.push(`${url}/new`);
          }}
        >
          Add Catalog
        </AppBarButton>
      </Header>
      <Grid container item xs={12}>
        <GridComponent dataSource={emailProviders}>
          <ColumnsDirective>
            <ColumnDirective field="name" headerText="Name" />
            <ColumnDirective
              headerText="Actions"
              template={(props) => (
                <ActionsCell
                  handleDelete={(event) => {
                    event.preventDefault();

                    setActiveDelete(props);
                  }}
                  {...props}
                />
              )}
            />
            {/* <ColumnDirective headerText="Actions" template={ActionsCell} /> */}
          </ColumnsDirective>
        </GridComponent>
      </Grid>
      <ConfirmModal
        open={Boolean(activeDelete)}
        handleConfirm={async () => {
          await client.delete(`/admin/catalogs/${activeDelete?.id}`);
          refresh();
          setActiveDelete(null);
        }}
        handleClose={() => {
          setActiveDelete(null);
        }}
        dispatch={_.noop}
        title={activeDelete && `Delete Catalog, "${activeDelete?.name}"?`}
        maxWidth="xs"
      />
    </Grid>
  );
}
