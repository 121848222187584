import _ from 'lodash';
import PaymentTransaction from 'lib/payment_transaction';
import PropTypes from 'prop-types';

// Components
import Button from 'components/Button';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { CurrencyCell, DateTimeCell, FormattedCell } from 'lib/datagrid_formatters';
import { NavLink, useParams, useRouteMatch } from 'react-router-dom';

// Hooks
import { useEffect, useRef } from 'react';

export default function PaymentTransactionsDG2({
  rows,
  hideColumns = [],
  selectedTransactions = [],
  setSelectedTransactions,
  dispatch,
  ...props
}) {
  const { store_id } = useParams();
  const gridRef = useRef(null);

  const canChargeback = (trx) => {
    return (
      trx.type === 'process' &&
      trx.status === 'approved' &&
      !trx.is_charged_back &&
      trx.credit_balance > 0
    );
  };

  const decoratedRows = rows.map((row) => {
    const formattedPt = { ...row };
    if (_.find(selectedTransactions, { id: formattedPt.id })) {
      formattedPt.isCheckedChargeback = true;
    } else {
      formattedPt.isCheckedChargeback = false;
    }

    return new PaymentTransaction(formattedPt);
  });

  const gridProps = {
    resizable: true,
    ...props,
  };

  // When the grid data changes, auto-fit the column widths to the data.
  useEffect(() => {
    const { current: grid } = gridRef;
    if (grid) {
      grid.fitColumns(grid.columns.map((col) => col.id));
    }
  }, [rows]);

  function ActionCell(props) {
    return (
      <FormattedCell
        {...props}
        formatted={
          <Button
            onClick={() => {
              dispatch({ type: 'BEGIN-CHARGEBACK-SUBMISSION', chargebackTransaction: props.dataItem });
            }}
            disabled={!canChargeback(props.dataItem)}
            size="small"
          >
            chargeback
          </Button>
        }
      />
    );
  }

  function IdCell(props) {
    const match = useRouteMatch({
      path: `/store/${store_id}/orders/${props.dataItem.order_id}`,
      exact: true,
    });

    let cell;
    let formattedId = props.dataItem?.truncatedId ?? props.dataItem.id;
    if (!match) {
      cell = (
        <NavLink exact to={`/store/${store_id}/orders/${props.dataItem.order_id}`}>
          {formattedId}
        </NavLink>
      );
    } else {
      cell = formattedId;
    }

    return <FormattedCell {...props} formatted={cell} />;
  }

  return (
    <Grid {...gridProps} data={decoratedRows} ref={gridRef}>
      {/* // NOTE: I tried to compose as a new component that forwards the props to GridColumn, but it didn't work.
      // This is why we have conditionals around each Column. */}
      {!_.includes(hideColumns, 'truncateId') && (
        <Column
          field="truncatedId"
          dbField="payment_transactions.id"
          title="ID"
          width="120px"
          cell={IdCell}
        />
      )}
      {!_.includes(hideColumns, 'amount') && (
        <Column
          field="amount"
          dbField="payment_transactions.amount"
          title="Amount"
          width="120px"
          cell={CurrencyCell}
        />
      )}
      {!_.includes(hideColumns, 'processor_type') && (
        <Column
          field="processor_type"
          dbField="payment_transactions.processor_type"
          title="Processor"
          width="120px"
        />
      )}
      {!_.includes(hideColumns, 'type') && (
        <Column field="type" dbField="payment_transactions.type" title="Type" width="120px" />
      )}
      {!_.includes(hideColumns, 'status') && (
        <Column field="status" dbField="payment_transactions.status" title="Status" width="120px" />
      )}
      {!_.includes(hideColumns, 'Action') && (
        <Column
          field="Action"
          dbField="payment_transactions.id"
          title="Action"
          cell={ActionCell}
          width="150px"
        />
      )}
      {!_.includes(hideColumns, 'created_at') && (
        <Column
          field="created_at"
          dbField="payment_transactions.created_at"
          title="Created"
          width="120px"
          cell={DateTimeCell}
        />
      )}
    </Grid>
  );
}

PaymentTransactionsDG2.propTypes = {
  rows: PropTypes.array.isRequired,
  hideColumns: PropTypes.arrayOf(
    PropTypes.oneOf(['truncateId', 'amount', 'processor_type', 'type', 'status', 'Action', 'created_at'])
  ),
  selectedTransactions: PropTypes.array,
  setSelectedTransactions: PropTypes.func,
  dispatch: PropTypes.func,
};
