import React, { useEffect, useState } from 'react';

import * as yup from 'yup';
import S from 'lib/stringomatic';
import qs from 'qs';

// Components
import { Button, FormControl, Grid, MenuItem, InputLabel } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { Select } from 'formik-material-ui';
import Header from 'components/Header';
import OTextField from 'components/form/OTextField';
import SwitchGroup from 'components/form/SwitchGroup';
import PageSection from 'components/PageSection';

// Hooks
import { useHistory } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

// exists to format the input name to a friendlier string
const requiredMessage = ({ path }) => {
  return `${S.humanize(path)} is Required`;
};

const formSchema = yup.object().shape({
  name: yup.string().required(requiredMessage),
  company_name: yup
    .string()
    .nullable(true)
    .transform((v, o) => (v === null ? '' : v)),
  email: yup.string().required(requiredMessage),
  password: yup.string().required(requiredMessage),
  type: yup.string().required(requiredMessage),
  activeRoles: yup.array().default([]),
});

export default function UsersNewPage() {
  const { client } = useAuth();
  const history = useHistory();

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    (async () => {
      let { data } = await client.get('/admin/roles', {
        params: {},
        paramsSerializer: (params) => qs.stringify(params, { encodeValuesOnly: true }),
      });

      setRoles(data);
    })();
  }, [client]);

  return (
    <Grid container>
      <Header heading="Create a new User" />
      <PageSection>
        <Grid item container>
          <Formik
            initialValues={{
              name: '',
              company_name: '',
              type: 'user',
              email: '',
              password: '',
            }}
            onSubmit={async (values) => {
              let { name, company_name, type, email, password, activeRoles } = values;
              let userData = {
                company_name,
                email,
                name,
                password,
                type,
                $graph: {
                  $relate: {
                    roles: {
                      relation: 'roles',
                      replace: true,
                      data: activeRoles.map((roleId) => ({ id: roleId })),
                    },
                  },
                },
              };
              await client.post(`/admin/users`, userData);
              history.push('/admin/users');
            }}
            validationSchema={formSchema}
          >
            {({ isSubmitting }) => {
              return (
                <Form>
                  <Grid container>
                    <Grid item xs={12}>
                      <OTextField name="name" label="Name" />
                    </Grid>
                    <Grid item xs={12}>
                      <OTextField name="email" label="Email" />
                    </Grid>
                    <Grid item xs={12}>
                      <OTextField name="company_name" label="Company Name" />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl>
                        <InputLabel id="type-label">Type</InputLabel>
                        <Field component={Select} id="type" name="type">
                          <MenuItem value="user">User</MenuItem>
                          <MenuItem value="admin">Admin</MenuItem>
                          <MenuItem value="application">Application</MenuItem>
                        </Field>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <OTextField id="password" name="password" label="Password" />
                  </Grid>

                  <Grid item xs={12}>
                    <h3>Roles</h3>
                    <SwitchGroup label="Assign active roles:" dataName="activeRoles" items={roles} />
                  </Grid>

                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                    Save
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </PageSection>
    </Grid>
  );
}
