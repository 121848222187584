import { useState, useEffect } from 'react';

// libs
import { getQueryParamsFromDataState } from 'lib/datagrid_helpers';
import { getQueryParamsFromFilterFormData } from 'lib/form_helpers';

// components
import { Grid } from '@material-ui/core';
import Header from 'components/Header';
import PageSection from 'components/PageSection';
import CustomersDG2 from 'components/DataGrids/CustomersDG2';
import CustomerFiltersFormik from 'components/form/CustomerFiltersFormik';

// hooks
import { useLocation } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { makeStyles } from '@material-ui/core/styles';

function CustomersIndex() {
  const { client } = useAuth();
  const classes = useStyles();
  let location = useLocation();

  const [customers, setCustomers] = useState({});
  const [customerFilters, setCustomerFilters] = useState({});
  const [tableFilters, setTableFilters] = useState({
    $skip: 0,
    $limit: 10,
    '$sort[users.created_at]': -1,
  });

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      const params = {
        $joinRelation: '[orders]',
        '$modify[with_main_contact]': true,
        $select: ['users.*', 'main_contact.*'],
        ...customerFilters,
        ...tableFilters,
      };

      const { data } = await client.get('/admin/customers', { params, signal: controller.signal });

      setCustomers(data);
    })();

    // Abort the request (and the following promise chain) if the page unmounts
    return () => {
      controller.abort();
    };
  }, [location.key, client, tableFilters, customerFilters]);

  async function handleFilterQuery(filters) {
    setCustomerFilters(getQueryParamsFromFilterFormData(filters));
  }

  async function handleDataState(dataState) {
    setTableFilters(getQueryParamsFromDataState(dataState));
  }

  // Datagrid presentation logic
  return (
    <Grid container item xs={12} classes={{ root: classes.pageRoot }} alignContent="flex-start">
      <Header heading="Customers" />
      <PageSection header="Filter">
        <CustomerFiltersFormik filterFn={handleFilterQuery} />
      </PageSection>
      <PageSection header="Customers" className={classes.tableSection}>
        <CustomersDG2 rows={customers} handleDataState={handleDataState} />
      </PageSection>
    </Grid>
  );
}

export default CustomersIndex;

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    height: '95vh',
    overflow: 'auto',
  },
  tableSection: {
    flexGrow: 1,
    minHeight: 0,
    overflow: 'auto',
    width: '80vh',
  },
}));
