/**
 * @typedef { import('@progress/kendo-react-grid').Grid } Grid
 * @typedef { import('@progress/kendo-react-grid').GridDataStateChangeEvent } GridDataStateChangeEvent
 * @typedef { import('@progress/kendo-data-query').State } DataState
 */

/**
 * Given a Kendo DataState event, return a new DataState object where the
 * field names are replaced with the values optionally defined by the
 * dbField prop on the column definition.
 *
 * @param {GridDataStateChangeEvent} event The Kendo DataGrid DataState event
 * @param {DataState} event.dataState The Kendo DataGrid DataState object
 * @param {Grid} event.target The Kendo DataGrid target
 * @returns {DataState} The transformed DataState object
 */
export function rekeyDataStateFields({ target, dataState }) {
  const { columns } = target;
  const { sort, group } = dataState;

  return {
    ...dataState,
    sort: sort.map((s) => {
      let { field, dir } = s;
      const column = columns.find((c) => c.field === field);
      return { field: column.dbField ?? field, dir };
    }),
    group: group.map((g) => {
      let { field } = g;
      const column = columns.find((c) => c.field === field);
      return { field: column.dbField ?? field };
    }),
  };
}

/**
 * Given a DataState object, return a set of feathers query params for
 * pagination, and sorting.
 * @param {DataState} dataState The Kendo DataGrid DataState object
 * @returns {{$skip: number, $limit: number, $sorts: object}} Feathers query params
 */
export function getQueryParamsFromDataState(dataState, sortKey = '$sort') {
  const { skip, take, sort } = dataState;
  const sorts = sort.reduce((acc, s) => {
    const key = `${sortKey}[${s.field}]`;
    acc[key] = s.dir === 'asc' ? 1 : -1;
    return acc;
  }, {});

  return {
    ...sorts,
    $skip: skip,
    $limit: take,
  };
}
