import React, { useEffect, useState } from 'react';

// components
import { Grid, Checkbox } from '@material-ui/core';
import Header from 'components/Header';
import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import { Link } from 'react-router-dom';
import AppBarButton from 'components/AppBarButton';

// hooks
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { useAuth } from 'hooks/useAuth';

function ActionsCell({ className = 'actions-cell', id, handleDelete, ...props }) {
  const { url } = useRouteMatch();

  return (
    <div className={className}>
      <Link to={`${url}/${id}`}>Edit</Link>
      <span className="link" onClick={handleDelete}>
        Delete
      </span>
    </div>
  );
}

export default function StoresIndexPage() {
  const { client } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const { url } = useRouteMatch();
  const [stores, setStores] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(false);

  const refresh = () => {
    setForceRefresh(!forceRefresh);
  };

  useEffect(() => {
    (async () => {
      let { data } = await client.get(`/admin/stores`);
      setStores(data);
    })();
  }, [location.key, client, forceRefresh]);

  return (
    <Grid container item xs={12}>
      <Header heading="Stores">
        <AppBarButton
          variant="contained"
          color="primary"
          onClick={() => {
            history.push(`${url}/new`);
          }}
        >
          Add Store
        </AppBarButton>
      </Header>
      <Grid container item xs={12}>
        <GridComponent dataSource={stores}>
          <ColumnsDirective>
            <ColumnDirective field="name" headerText="Name" />
            <ColumnDirective field="configuration.post_sale_expiration" headerText="Post Sale Exp" />
            <ColumnDirective
              field="configuration.order_confirmation_email_delay"
              headerText="Order Confirm Email Delay"
            />
            <ColumnDirective
              field="default"
              headerText="Default Store?"
              template={({ is_default }) => (
                <Checkbox type="checkbox" checked={is_default} inputProps={{ readOnly: true }} />
              )}
            />
            <ColumnDirective
              headerText="Actions"
              template={(props) => (
                <ActionsCell
                  handleDelete={async () => {
                    await client.delete(`/admin/stores/${props.id}`);
                    refresh();
                  }}
                  {...props}
                />
              )}
            />
          </ColumnsDirective>
        </GridComponent>
      </Grid>
    </Grid>
  );
}
