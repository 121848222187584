import React, { useState } from 'react';
import PropTypes from 'prop-types';

// libs
import { makeStyles } from '@material-ui/core/styles';
import { formatCurrency } from 'lib/helpers';
import _ from 'lodash';
import qs from 'qs';

// components
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import Currency from 'components/Currency';

// hooks
import { useAuth } from 'hooks/useAuth';

// TODO@catalog-search: The component should allow the user to filter the
// catalogs in which they are searching.
// TODO@catalog-search: The component should allow the user to change the
// grouping of the results between catalog, product, and at some point,
// category, when categories are implemented.

/**
 * This is an auto-complete search component for catalog items.
 * @param {object} props
 * @param {function} props.onSelect The item selection callback
 * @param {string} props.storeId The store ID to search
 * @param {string} props.currencyCodeISO The currency code by which to filter catalog items
 * @param {number} [props.timeoutDuration] The duration in milliseconds to wait before executing the search
 */
export default function CatalogItemSearch({
  onSelect,
  storeId,
  currencyCodeISO = 'USD',
  timeoutDuration = 600,
  ...props
}) {
  const classes = useStyles();
  const { client } = useAuth();
  // Stores the search results from the server
  const [catalogItems, setCatalogItems] = useState([]);
  // Stores the search term
  const [searchInputValue, setSearchInputValue] = useState('');
  // Stores the selected item from the search results
  const [selectedValue, setSelectedValue] = useState(null);

  // The timeout is used to debounce the search
  let timeoutHandle;

  /**
   * This is the lookup handler for the catalog item auto-complete input
   * @param {*} event The AutoComplete event
   * @param {*} searchValue The search term in the auto-complete input
   */
  function handleSearch(event, searchValue) {
    setSearchInputValue(searchValue);
    if (timeoutHandle) {
      clearTimeout(timeoutHandle);
    }

    timeoutHandle = setTimeout(async () => {
      let { data: catalogItemsData } = await client.get(`/admin/catalogs`, {
        params: {
          store_id: storeId,
          $eager: '[catalog_items.[variant]]',
          $modify: {
            search_items: [searchValue, currencyCodeISO],
          },
        },
        paramsSerializer: (params) => qs.stringify(params, { encodeValuesOnly: true }),
      });

      // Get the nested catalog items, and flatten them into a single array,
      // appending the catalog name for grouping purposes.
      const catalogItems = _.flatMap(catalogItemsData, (catalog) => {
        return _.flatMap(catalog.catalog_items, (catalogItem) => {
          return {
            ...catalogItem,
            catalog_name: catalog.name,
          };
        });
      });
      setCatalogItems(catalogItems);
    }, timeoutDuration);
  }

  /**
   * This is the selection handler for the catalog item auto-complete input
   * @param {*} event The AutoComplete event
   * @param {*} selectedItem The selected catalog item
   */
  function handleSelection(event, selectedItem) {
    // clear the inputs and selected value of the auto-complete component
    setSearchInputValue('');
    setSelectedValue(null);

    // call the onSelect handler passed in via the props
    onSelect(selectedItem);
  }

  return (
    <Autocomplete
      id="catalog-item-search"
      onChange={handleSelection}
      onInputChange={handleSearch}
      options={catalogItems}
      filterOptions={(options, state) => {
        return options.filter((option) =>
          new RegExp(state.inputValue, 'im').test(`${option.sku}${option.name}${option.description}`)
        );
      }}
      getOptionLabel={(catalogItem) =>
        `${catalogItem.name} - ${catalogItem.variant.sku} - ${formatCurrency(
          catalogItem.price,
          catalogItem.currency_iso
        )}`
      }
      renderOption={(catalogItem) => (
        <>
          <span className={classes.catalogItemName}>{catalogItem.name}</span>
          <span>({catalogItem.variant.sku})</span>
          <span className={classes.catalogItemPrice}>
            <Currency amount={catalogItem.price} currencyCodeISO={catalogItem.currency_iso} />
          </span>
          <span>{catalogItem.currency_iso}</span>
        </>
      )}
      getOptionSelected={(catalogItem) => catalogItem.id}
      groupBy={(catalogItem) => catalogItem.catalog_name}
      inputValue={searchInputValue}
      value={selectedValue}
      renderInput={(params) => <TextField {...params} label="find catalog items" variant="outlined" />}
    />
  );
}

CatalogItemSearch.propTypes = {
  onSelect: PropTypes.func.isRequired,
  storeId: PropTypes.string.isRequired,
  timeoutDuration: PropTypes.number,
};

const useStyles = makeStyles((theme) => ({
  catalogItemName: {
    fontWeight: 'bold',
    padding: '0px 5px 0px 5px',
  },
  catalogItemPrice: {
    fontWeight: 'bold',
    padding: '0px 5px 0px 5px',
  },
}));
