import React, { Fragment } from 'react';

// Components
import { Backdrop, Grid, Paper } from '@material-ui/core';
import JSONPretty from 'react-json-pretty';
import Button from 'components/Button';

// Hooks
import { useApplication } from 'contexts/application.context';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

// JSON pretty css
import 'react-json-pretty/themes/monikai.css';

export default function ErrorDisplay({ error }) {
  const { state, dispatch } = useApplication();
  const history = useHistory();

  const classes = useStyles();

  if (!error) return '';

  // NOTE: in the future, if this needs more use cases, we should turn this into a HOC
  let errorMessage = '';

  // determine error type
  if (error?.isAxiosError) {
    // NOTE: sometimes we won't have a response (server unreachable), so check
    if (error.response) {
      let { status, data, statusText } = error.response;
      errorMessage = (
        <>
          <Grid item xs={12}>
            <p>
              If reporting, please screenshot and/or copy and paste this error message along with the
              report.
            </p>
          </Grid>
          <Grid item xs={12}>
            <h4>Server Error ({status})</h4>
          </Grid>
          <Grid item xs={12}>
            {statusText}
          </Grid>
          <Grid item xs={12}>
            <div style={{ margin: '.7em 0 .7em 0' }}>{state.error?.message}</div>
          </Grid>
          <Grid item xs={12}>
            {data && <JSONPretty mainStyle="height: 40vh" data={data} />}
          </Grid>
        </>
      );
    }
  } else {
    errorMessage = (
      <Grid item xs={12}>
        <div style={{ margin: '.7em 0 .7em 0' }}>{error?.message}</div>
      </Grid>
    );
  }

  return (
    <Backdrop className={classes.backdrop} open={Boolean(error) || state.error}>
      <Grid
        container
        alignContent="stretch"
        alignItems="center"
        classes={{ root: classes.errorContainer }}
        direction="row"
      >
        <Grid item xs={3} />
        <Grid item xs={6}>
          <Paper classes={{ root: classes.errorContent }}>
            <Grid container>
              <Grid item xs={12}>
                <h2>{state.message && <Fragment>{state.message}</Fragment>}</h2>
              </Grid>
              {errorMessage}
              <Grid item classes={{ root: classes.errorButtons }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    dispatch({ type: 'CLEAR-ERROR' });

                    // redirect
                    history.replace('/');
                  }}
                  classes={{ root: classes.errorButtons }}
                >
                  click to close
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={3} />
      </Grid>
    </Backdrop>
  );
}

// Styles
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

  errorContent: {
    '& h2': {
      textAlign: 'center',
    },
    maxHeight: '80vh',
    overflow: 'auto',
    padding: '1em',
  },

  errorContainer: {
    height: '100%',
  },

  errorButtons: {
    textAlign: 'center',
    margin: '0 auto',
  },
}));
