import _ from 'lodash';

/**
 * Given a list of resourceIds and a dictionary of access conditions for those,
 * resources, build and return an array of read `permissions` that can be
 * applied to a role through its resource graph.
 * @param {string[]} resourceIds The ids of the resources for which to build the permissions
 * @param {object} readConditions The read conditions for the resources
 * @returns {object[]} An array of permissions
 */
export function buildReadPermissionGraph(resourceIds, readConditions) {
  return _.flatMap(resourceIds, (r) =>
    ['find', 'get'].map((a) => ({ id: r, access: a, conditions: readConditions[r] ?? {} }))
  );
}

/**
 * Given a list of resourceIds, this will return an array of write `permissions`
 * that can be applied to a role through its resource graph.
 * @param {string[]} resourceIds The ids of the resources for which to build the permissions
 * @returns {object[]} An array of permissions
 */
export function buildWritePermissionGraph(resourceIds) {
  return _.flatMap(resourceIds, (r) =>
    ['create', 'update', 'patch', 'remove'].map((a) => ({ id: r, access: a, conditions: {} }))
  );
}

/**
 * Given a list of resourceIds, this will return an array of `permissions` that
 * can with be applied to a role through its resource graph, with the necessary
 * scoping 'conditions' to limit access to a given set of stores.
 * @param {string[]} resourceIds The ids of the resources for which to build the permissions
 * @param {*} storeIds The ids of the stores for which to build the permissions
 * @returns {object[]} An array of permissions
 */
export function buildStorePermissionGraph(resourceIds, storeIds) {
  let conditions = {};
  if (storeIds.includes('*')) {
    conditions = { id: { $exists: true } };
  }
  if (storeIds.length > 0 && !storeIds.includes('*')) {
    conditions = { id: { $in: storeIds } };
  }
  if (storeIds.length === 0) {
    conditions = { id: { $exists: false } };
  }

  return _.flatMap(resourceIds, (r) => ['access'].map((a) => ({ id: r, access: a, conditions })));
}
