import React, { useReducer, useMemo } from 'react';
import _ from 'lodash';

function reducer(state, action) {
  switch (action.type) {
    case 'SET-CUSTOMER':
      let customer = {
        ...action.customer,
        latest_session: _.head(_.get(action.customer, 'sessions', [])),
      };
      return { ...state, customer };

    case 'EDIT-CONTACT':
      return { ...state, editContactModalOpen: true, edit_contact: action.contact };

    case 'CLOSE-EDIT-CONTACT-MODAL':
      return { ...state, editContactModalOpen: false };

    case 'SAVE-CONTACT':
      let newState = {
        ...state,
        customer: {
          ...state.customer,
          contacts: [
            ..._.filter(state.customer.contacts, (c) => c.id !== action.contact.id),
            action.contact,
          ],
        },
        editContactModalOpen: false,
      };
      return newState;

    default:
      throw new Error(`Dispatch ${action.type} not handled`);
  }
}

const CustomerContext = React.createContext();

function CustomerProvider(props) {
  const [state, dispatch] = useReducer(reducer, { editContactModalOpen: false });
  const value = useMemo(() => [state, dispatch], [state]);
  return <CustomerContext.Provider value={value} {...props} />;
}

function useCustomer() {
  const context = React.useContext(CustomerContext);
  if (!context) {
    throw new Error('useCustomer must be used within an CustomerProvider');
  }

  const [state, dispatch] = context;

  return {
    state,
    dispatch,
  };
}

export { CustomerProvider, useCustomer };
