import { formatDateTime } from '../lib/helpers';

// Components
import { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import GridRow from 'components/GridRow';

// Hooks
import { makeStyles } from '@material-ui/core/styles';

export default function SessionGrid({ agent, session, dispatch, columns = 1, ...props }) {
  const classes = useStyles();

  const state = { columnWidth: columns < 5 ? Math.floor(12 / columns) : 12 };

  // TODO: Add support for rendering session 'meta' columns
  const theGrid = () => {
    return (
      <Grid container item xs={12} spacing={1}>
        <Grid container item xs={state.columnWidth} spacing={1} className={classes.section}>
          <GridRow title="Agent" data={agent?.name} />
          <GridRow title="ID" data={session.id} />
          <GridRow title="Created" data={formatDateTime(session.created_at)} />
          <GridRow title="Updated At" data={formatDateTime(session.updated_at)} />
        </Grid>
        <Grid container item xs={state.columnWidth} spacing={1}>
          <GridRow title="IP Address" data={session.ip_address} />
          <GridRow title="User Agent" data={session.user_agent} />
          <GridRow title="Referrer" data={session.referrer} />
        </Grid>

        <Grid container item xs={state.columnWidth} spacing={1}>
          <GridRow title="AffId" data={session.aff_id} />
          <GridRow title="SubId 1" data={session.subid_1} />
          <GridRow title="SubId 2" data={session.subid_2} />
          <GridRow title="SubId 3" data={session.subid_3} />
          <GridRow title="SubId 4" data={session.subid_4} />
          <GridRow title="SubId 5" data={session.subid_5} />
        </Grid>
        <Grid container item xs={state.columnWidth} spacing={1}>
          <GridRow title="UTM Source" data={session.utm_source} />
          <GridRow title="UTM Campaign" data={session.utm_campaign} />
          <GridRow title="UTM Medium" data={session.utm_medium} />
          <GridRow title="UTM Term" data={session.utm_term} />
          <GridRow title="UTM Content" data={session.utm_content} />
        </Grid>
      </Grid>
    );
  };

  return session ? theGrid() : <Fragment>Not Found</Fragment>;
}

const useStyles = makeStyles((theme) => ({
  section: {
    // verticalAlign: 'top',
  },
  line: {},
  title: {
    backgroundColor: '#f6f6f6',
    padding: '3px 5px',
    textAlign: 'right',
    fontWeight: 'bold',
  },
  data: {
    padding: '3px 5px',
    wordBreak: 'break-all',
  },
}));
