import { Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    marginBottom: '2px',
  },
});

export default function OTextField(props) {
  const classes = useStyles();
  return <Field component={TextField} variant="outlined" {...props} classes={{ root: classes.root }} />;
}
