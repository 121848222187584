import React, { Fragment, useEffect } from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';

import { Backdrop, CircularProgress } from '@material-ui/core';
import NotFoundPage from 'pages/NotFoundPage';
import ProcessorIndexPage from 'pages/Processors/ProcessorIndexPage';
import PrivateRoute from 'components/PrivateRoute';
import CustomersIndexPage from '../pages/Customers/CustomersIndexPage';
import CustomersShowPage from '../pages/Customers/CustomersShowPage';
import LoginPage from '../pages/LoginPage';
import PaymentGroupsNewPage from '../pages/PaymentGroups/PaymentGroupsNewPage';
import PaymentGroupsIndexPage from '../pages/PaymentGroups/PaymentGroupsIndexPage';
import PaymentGroupsEditPage from '../pages/PaymentGroups/PaymentGroupsEditPage';
import OrderSummaryPage from 'pages/Reports/OrderSummaryPage';
import TransactionSummaryPage from 'pages/Reports/TransactionSummaryPage';
import ProductSummaryPage from 'pages/Reports/ProductSummaryPage';
import OrderDetailReportPage from 'pages/Reports/OrderDetailReportPage';
import DiscountCodePage from 'pages/DiscountCodePage';
import NewDiscountCodePage from 'pages/NewDiscountCodePage';
import DiscountCodeEditPage from 'pages/DiscountCodeEditPage';
import OrdersIndexPage from 'pages/Orders/OrdersIndexPage';
import NewOrderPage from 'pages/Orders/NewOrderPage';
import EditOrderPage from 'pages/Orders/EditOrderPage';
import ProductIndexPage from 'pages/Products/ProductIndexPage';
import ProductShowPage from 'pages/Products/ProductShowPage';
import PaymentTransactionShowPage from 'pages/PaymentTransactions/PaymentTransactionShowPage';
import EmailProvidersIndexPage from 'pages/EmailProviders/EmailProvidersIndexPage';
import EmailProvidersNewPage from 'pages/EmailProviders/EmailProvidersNewPage';
import EmailProvidersEditPage from 'pages/EmailProviders/EmailProvidersEditPage';
import FulfillmentProvidersIndexPage from 'pages/FulfillmentProviders/FulfillmentProvidersIndexPage';
import FulfillmentProvidersNewPage from 'pages/FulfillmentProviders/FulfillmentProvidersNewPage';
import FulfillmentProvidersEditPage from 'pages/FulfillmentProviders/FulfillmentProvidersEditPage';
import StoresEditPage from 'pages/Stores/StoresEditPage';
import StoresIndexPage from 'pages/Stores/StoresIndexPage';
import StoresNewPage from 'pages/Stores/StoresNewPage';
import UsersIndexPage from 'pages/Users/UsersIndexPage';
import UsersNewPage from 'pages/Users/UsersNewPage';
import UsersEditPage from 'pages/Users/UsersEditPage';
import DashboardPage from 'pages/DashboardPage';
import RolesIndexPage from 'pages/Roles/RolesIndexPage';
import RolesNewPage from 'pages/Roles/RolesNewPage';
import RolesEditPage from 'pages/Roles/RolesEditPage';
import CatalogsIndexPage from 'pages/Catalogs/CatalogsIndexPage';
import CatalogsNewPage from 'pages/Catalogs/CatalogsNewPage';
import CatalogsEditPage from 'pages/Catalogs/CatalogsEditPage';
import SentryTestPage from 'pages/SentryTestPage';
import ErrorDisplay from './ErrorDisplay';

// hooks & providers
import { CustomerProvider } from 'contexts/customer.context';
import { OrderProvider } from 'contexts/order.context';
import { makeStyles } from '@material-ui/core/styles';
import { useApplication } from 'contexts/application.context';
import { useAuth } from 'hooks/useAuth';

function Routes() {
  const classes = useStyles();
  const { state } = useApplication();
  const history = useHistory();

  useEffect(() => {
    if (state.redirect) {
      history.push(state.redirect);
    }
  }, [state.redirect, history]);

  return (
    <Fragment>
      <Switch>
        <PrivateRoute path="/" exact>
          <DashboardPage />
        </PrivateRoute>
        <Route path="/login" component={LoginPage} />

        {/* routes that require a store scope */}
        <Route path="/store/:store_id">
          <StoreRoutes />
        </Route>

        {/* TODO: everything now requires a store scope, but leaving this for now since it might be temporary */}
        <PrivateRoute path="/discount_codes" exact>
          <DiscountCodePage />
        </PrivateRoute>
        <PrivateRoute path="/discount_codes/new" exact>
          <NewDiscountCodePage />
        </PrivateRoute>
        <PrivateRoute path="/discount_codes/:id">
          <DiscountCodeEditPage />
        </PrivateRoute>

        {/* Products */}
        <PrivateRoute path="/products" exact authorize={{ subject: 'products', access: 'access' }}>
          <ProductIndexPage />
        </PrivateRoute>
        <PrivateRoute path="/products/new" authorize={{ access: 'access', subject: 'products' }} exact>
          <ProductShowPage />
        </PrivateRoute>
        <PrivateRoute path="/products/:productId" authorize={{ access: 'access', subject: 'products' }}>
          <ProductShowPage />
        </PrivateRoute>

        {/* Payment Transactions */}
        <PrivateRoute
          path="/payment_transactions/:id"
          authorize={{ access: 'access', subject: 'payments' }}
        >
          <PaymentTransactionShowPage />
        </PrivateRoute>

        {/* Stores */}
        <PrivateRoute path="/stores" exact authorize={{ access: 'access', subject: 'stores' }}>
          <StoresIndexPage />
        </PrivateRoute>
        <PrivateRoute path="/stores/new" authorize={{ access: 'access', subject: 'stores' }}>
          <StoresNewPage />
        </PrivateRoute>
        <PrivateRoute path="/stores/:store_id" authorize={{ access: 'access', subject: 'stores' }}>
          <StoresEditPage />
        </PrivateRoute>

        {/* Users & Permissions */}
        <PrivateRoute path="/admin/users" exact authorize={{ access: 'access', subject: 'users' }}>
          <UsersIndexPage />
        </PrivateRoute>
        <PrivateRoute path="/admin/users/new" authorize={{ access: 'access', subject: 'users' }}>
          <UsersNewPage />
        </PrivateRoute>
        <PrivateRoute path="/admin/users/:user_id" authorize={{ access: 'access', subject: 'users' }}>
          <UsersEditPage />
        </PrivateRoute>

        <PrivateRoute path="/admin/roles" exact authorize={{ access: 'access', subject: 'permissions' }}>
          <RolesIndexPage />
        </PrivateRoute>
        <PrivateRoute path="/admin/roles/new" authorize={{ access: 'access', subject: 'permissions' }}>
          <RolesNewPage />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/roles/:role_id"
          authorize={{ access: 'access', subject: 'permissions' }}
        >
          <RolesEditPage />
        </PrivateRoute>

        {/* Sentry Test */}
        <Route path="/sentry_test">
          <SentryTestPage />
        </Route>

        {/* 404 */}
        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>

      {state.error && <ErrorDisplay error={state.error} />}
      <Backdrop className={classes.backdrop} open={state.loading === true}>
        {state.message && <Fragment>{state.message}</Fragment>}
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
}

// NOTE: the ability to access stores will conditionally mount this in the parent component
function StoreRoutes() {
  const { setStore, client } = useAuth();
  const { path, params } = useRouteMatch();
  let { store_id } = params;

  useEffect(() => {
    (async () => {
      if (store_id) {
        let { data } = await client.get(`/admin/stores/${store_id}`);
        setStore(data);
      }
    })();
  }, [store_id, client, setStore]);

  return (
    <Switch>
      {/* Customers */}
      <PrivateRoute
        path={`${path}/customers`}
        exact
        authorize={{ access: 'access', subject: 'customers' }}
      >
        <CustomerProvider>
          <CustomersIndexPage />
        </CustomerProvider>
      </PrivateRoute>
      <PrivateRoute
        path={`${path}/customers/:id`}
        authorize={{ access: 'access', subject: 'customers' }}
      >
        <CustomerProvider>
          <CustomersShowPage />
        </CustomerProvider>
      </PrivateRoute>

      {/* Reports */}
      <PrivateRoute
        path={`${path}/reports/order_summary`}
        authorize={{ access: 'access', subject: 'reports' }}
      >
        <OrderSummaryPage />
      </PrivateRoute>

      <PrivateRoute
        path={`${path}/reports/transaction_summary`}
        authorize={{ access: 'access', subject: 'reports' }}
      >
        <TransactionSummaryPage />
      </PrivateRoute>

      <PrivateRoute
        path={`${path}/reports/product_summary`}
        authorize={{ access: 'access', subject: 'reports' }}
      >
        <ProductSummaryPage />
      </PrivateRoute>

      <PrivateRoute
        path={`${path}/reports/order_details`}
        authorize={{ access: 'access', subject: 'reports' }}
      >
        <OrderDetailReportPage />
      </PrivateRoute>

      {/* Payment Groups */}
      <PrivateRoute
        path={`${path}/payment_groups`}
        exact
        authorize={{ access: 'access', subject: 'payment_groups' }}
      >
        <PaymentGroupsIndexPage />
      </PrivateRoute>
      <PrivateRoute
        path={`${path}/payment_groups/new`}
        authorize={{ access: 'access', subject: 'payment_groups' }}
      >
        <PaymentGroupsNewPage />
      </PrivateRoute>
      <PrivateRoute
        path={`${path}/payment_groups/:id`}
        authorize={{ access: 'access', subject: 'payment_groups' }}
      >
        <PaymentGroupsEditPage />
      </PrivateRoute>

      {/* Email Providers */}
      <PrivateRoute
        path={`${path}/email_providers`}
        exact
        authorize={{ access: 'access', subject: 'providers' }}
      >
        <EmailProvidersIndexPage />
      </PrivateRoute>

      <PrivateRoute
        path={`${path}/email_providers/new`}
        authorize={{ access: 'access', subject: 'providers' }}
      >
        <EmailProvidersNewPage />
      </PrivateRoute>

      <PrivateRoute
        path={`${path}/email_providers/:email_provider_id`}
        authorize={{ access: 'access', subject: 'providers' }}
      >
        <EmailProvidersEditPage />
      </PrivateRoute>

      {/* Fullfillment Providers */}
      <PrivateRoute
        path={`${path}/fulfillment_providers`}
        exact
        authorize={{ access: 'access', subject: 'providers' }}
      >
        <FulfillmentProvidersIndexPage />
      </PrivateRoute>
      <PrivateRoute
        path={`${path}/fulfillment_providers/new`}
        authorize={{ access: 'access', subject: 'providers' }}
      >
        <FulfillmentProvidersNewPage />
      </PrivateRoute>
      <PrivateRoute
        path={`${path}/fulfillment_providers/:fulfillment_provider_id`}
        authorize={{ access: 'access', subject: 'providers' }}
      >
        <FulfillmentProvidersEditPage />
      </PrivateRoute>

      {/* Processors */}
      <PrivateRoute
        path={`${path}/processors`}
        exact
        authorize={{ access: 'access', subject: 'processors' }}
      >
        <ProcessorIndexPage />
      </PrivateRoute>

      {/* Catalogs */}
      <PrivateRoute
        path={`${path}/catalogs`}
        exact
        authorize={{ access: 'access', subject: 'catalogs' }}
      >
        <CatalogsIndexPage />
      </PrivateRoute>
      <PrivateRoute path={`${path}/catalogs/new`} authorize={{ access: 'access', subject: 'catalogs' }}>
        <CatalogsNewPage />
      </PrivateRoute>
      <PrivateRoute path={`${path}/catalogs/:id`} authorize={{ access: 'access', subject: 'catalogs' }}>
        <CatalogsEditPage />
      </PrivateRoute>

      {/* Orders */}
      <PrivateRoute path={`${path}/orders`} exact authorize={{ access: 'access', subject: 'orders' }}>
        <OrderProvider>
          <OrdersIndexPage />
        </OrderProvider>
      </PrivateRoute>
      <PrivateRoute path={`${path}/orders/new`} authorize={{ access: 'access', subject: 'orders' }}>
        <OrderProvider>
          <NewOrderPage />
        </OrderProvider>
      </PrivateRoute>
      <PrivateRoute path={`${path}/orders/:orderId`} authorize={{ access: 'access', subject: 'orders' }}>
        <OrderProvider>
          <EditOrderPage />
        </OrderProvider>
      </PrivateRoute>
    </Switch>
  );
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

  errorContent: {
    '& h2': {
      textAlign: 'center',
    },
    height: '70%',
    padding: '1em',
  },
  errorContainer: {
    height: '100%',
  },
  errorButtons: {
    textAlign: 'center',
    margin: '0 auto',
  },
}));

export default Routes;
