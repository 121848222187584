import React, { useEffect, useState } from 'react';
import { buildReadPermissionGraph, buildWritePermissionGraph } from 'lib/graph_helpers';

import * as yup from 'yup';
import S from 'lib/stringomatic';
import qs from 'qs';

// Components
import { Button, Grid } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { Switch } from 'formik-material-ui';
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
} from '@syncfusion/ej2-react-grids';
import Header from 'components/Header';
import OTextField from 'components/form/OTextField';
import PageSection from 'components/PageSection';

// Hooks
import { useHistory } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

// exists to format the input name to a friendlier string
const requiredMessage = ({ path }) => {
  return `${S.humanize(path)} is Required`;
};

const formSchema = yup.object().shape({
  name: yup.string().required(requiredMessage),
  readAccess: yup.array().default([]),
  writeAccess: yup.array().default([]),
});

const pageSettings = {
  pageSize: 10,
  // pageSizes: [5, 10, 20, 50, 100, 200, 'All'],
};

const gridProps = {
  pageSettings,
  allowPaging: true,
};

export default function RolesNewPage() {
  const { client } = useAuth();
  const history = useHistory();

  const [availableResources, setAvailableResources] = useState([]);

  useEffect(() => {
    (async () => {
      let { data: resources } = await client.get('/admin/resources', {
        params: {
          $sort: {
            name: 1,
          },
        },
        paramsSerializer: (params) => qs.stringify(params, { encodeValuesOnly: true }),
      });

      setAvailableResources(resources);
    })();
  }, [client]);

  return (
    <Grid container>
      <Header heading="Create a new Role" />
      <PageSection>
        <Grid item container>
          <Formik
            initialValues={{
              name: '',
              readAccess: [],
              writeAccess: [],
            }}
            onSubmit={async (values) => {
              let { name, readAccess, writeAccess } = values;

              let reqData = {
                name,
                $graph: {
                  $relate: {
                    resources: {
                      relation: 'resources',
                      replace: true,
                      data: [
                        ...buildReadPermissionGraph(readAccess),
                        ...buildWritePermissionGraph(writeAccess),
                      ],
                    },
                  },
                },
              };

              await client.post(`/admin/roles`, reqData);
              history.push('/admin/roles');
            }}
            validationSchema={formSchema}
          >
            {({ isSubmitting }) => {
              return (
                <Form>
                  <Grid container>
                    <Grid item xs={12}>
                      <OTextField name="name" label="Name" />
                    </Grid>
                  </Grid>

                  <Grid container item xs={12}>
                    <h3>Permissions</h3>

                    <GridComponent dataSource={availableResources} {...gridProps}>
                      <Inject services={[Page]} />
                      <ColumnsDirective>
                        <ColumnDirective field="name" headerText="Name" />

                        <ColumnDirective
                          headerText="Read"
                          template={(props) => (
                            <Field
                              key={`${props.id}-read`}
                              component={Switch}
                              name="readAccess"
                              type="checkbox"
                              value={props.id}
                            />
                          )}
                        />
                        <ColumnDirective
                          headerText="Write"
                          template={(props) => (
                            <Field
                              key={`${props.id}-write`}
                              component={Switch}
                              name="writeAccess"
                              type="checkbox"
                              value={props.id}
                            />
                          )}
                        />
                      </ColumnsDirective>
                    </GridComponent>
                  </Grid>

                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                    Save
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </PageSection>
    </Grid>
  );
}
