import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default function OrderTotalLine({ label, value }) {
  const classes = useStyles();

  return (
    <Grid container classes={{ root: classes.orderTotalLine }}>
      <Grid item xs={8}>
        {label}
      </Grid>
      <Grid item xs={4} classes={{ root: classes.value }}>
        {value}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({
  orderTotalLine: {
    padding: '2px 0 5px 1px',
    textAlign: 'right',
  },

  value: {
    fontWeight: 'bold',
  },
});
