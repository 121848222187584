import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

// Hooks
import { makeStyles } from '@material-ui/core/styles';

function GridRow({ title, data, titleSize = 4, titleProps = {}, dataProps = {}, ...props }) {
  const classes = useStyles();

  return (
    <Grid container item xs={12} className={classes.line} {...props}>
      <Grid item xs={titleSize} className={classes.title} {...titleProps}>
        {title}
      </Grid>
      <Grid item xs={12 - titleSize} className={classes.data} {...dataProps}>
        {data}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: '#f6f6f6',
    padding: '3px 5px',
    textAlign: 'right',
    fontWeight: 'bold',
  },
  data: {
    padding: '3px 5px',
    wordBreak: 'break-all',
  },
}));

GridRow.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.any,
  titleSize: PropTypes.number,
  titleProps: PropTypes.object,
  dataProps: PropTypes.object,
};

export default GridRow;
