import React, { useState, useEffect, useCallback } from 'react';

// components
import Header from 'components/Header';
import PageSection from 'components/PageSection';
import DataGrid from 'components/DataGrid';
import Currency from 'components/Currency';
import ProductsFilterForm from 'components/form/ProductsFilterForm';
import { Grid } from '@material-ui/core';
import AppBarButton from 'components/AppBarButton';

// hooks
import { useHistory, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from 'hooks/useAuth';

export default function ProductIndexPage() {
  const history = useHistory();
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState({
    search: '',
  });
  const auth = useAuth();
  const { client } = auth;

  function handleAddProduct(event) {
    event.preventDefault();
    history.push('/products/new');
  }

  const fetchProducts = useCallback(
    async function (params = {}, history) {
      let { data } = await client.get(`/products`, { params });
      return data;
    },
    [client]
  );

  useEffect(() => {
    (async () => {
      let params = {};
      if (filters.search) {
        params['$or[0][name][$ilike]'] = `%${filters.search}%`;
        params['$or[1][sku][$ilike]'] = `%${filters.search}%`;
        params['$and[][is_archived]'] = false;
      }
      params.$limit = 50;
      let newProducts = await fetchProducts(params, history);
      newProducts = gridProductsData(newProducts);
      setProducts(newProducts);
    })();
  }, [filters, fetchProducts, history]);

  const gridProductsData = function (products) {
    return products.map((product) => {
      return {
        id: product.id,
        name: <Link to={`/products/${product.id}`}>{product.name}</Link>,
        sku: product.sku,
        description: product.description,
        shipping_price: <Currency amount={product.shipping_price} />,
      };
    });
  };

  const classes = useStyles();

  function handleFilterQuery(filters) {
    setFilters(filters);
  }

  return (
    <Grid container item xs={12} className={classes.pageRoot} alignContent="flex-start">
      <Header heading="Products">
        <AppBarButton onClick={handleAddProduct}>Add Product</AppBarButton>
      </Header>

      <Grid container item xs={12}>
        <PageSection>
          <ProductsFilterForm filterFn={handleFilterQuery}></ProductsFilterForm>
        </PageSection>
        <PageSection className={classes.productSection}>
          <DataGrid
            data={products}
            columns={['Name', 'SKU', 'Description', 'Price']}
            name="productsGrid"
          />
        </PageSection>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({
  pageRoot: {
    height: '95vh',
    overflow: 'auto',
  },
  productSection: {
    flexGrow: 1,
    minHeight: 0,
    overflow: 'auto',
  },
});
