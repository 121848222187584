import React from 'react';
import { formatCurrency } from '../lib/helpers';

/**
 *
 * @param {object} props
 * @param {number} props.amount The amount to format
 * @param {string} props.currencyCodeISO The ISO currency code to use
 */
export default function Currency({ amount, currencyCodeISO = 'USD' }) {
  const formattedAmount = formatCurrency(amount, currencyCodeISO);
  return <>{formattedAmount}</>;
}
