import Button from 'components/Button';
import GeneralModal from './GeneralModal';

import { makeStyles } from '@material-ui/core/styles';

export default function ConfirmModal({ handleConfirm, handleClose, dispatch, ...props }) {
  const classes = useStyles();

  return (
    <GeneralModal dispatch={dispatch} handleClose={handleClose} {...props}>
      <div className={classes.content}>
        <Button color="secondary" onClick={handleClose} classes={{ root: classes.button }}>
          Cancel
        </Button>
        <Button onClick={handleConfirm} classes={{ root: classes.button }}>
          Confirm
        </Button>
      </div>
    </GeneralModal>
  );
}

const useStyles = makeStyles({
  content: {
    textAlign: 'right',
  },

  button: {
    marginRight: '4px',
  },
});
