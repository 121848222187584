import React, { useEffect, useState } from 'react';

// libs
import _ from 'lodash';
import moment from 'moment-timezone';
import { getQueryParamsFromDataState } from 'lib/datagrid_helpers';
import { getQueryParamsFromFilterFormData } from 'lib/form_helpers';

// components
import { Grid, AppBar, Tabs, Tab } from '@material-ui/core';
import Header from 'components/Header';
import BarChart from 'components/BarChart';
import PageSection from 'components/PageSection';
import TransactionSummaryFiltersFormik from 'components/form/TransactionSummaryFiltersFormik';
import TransactionSummaryDG from 'components/DataGrids/TransactionSummaryDG';

// hooks
import { useAuth } from 'hooks/useAuth';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TransactionSummaryPage() {
  const { client } = useAuth();
  const { store_id } = useParams();
  const classes = useStyles();

  const [filters, setFilters] = useState({
    'payment_transactions.created_at[$gte]': moment().startOf('month').toDate(),
    'payment_transactions.created_at[$lte]': moment().endOf('day').toDate(),
  });
  const [tableFilters, setTableFilters] = useState({
    $skip: 0,
    $limit: 10,
  });
  const [reportGroupBy, setReportGroupBy] = useState('payment_configuration.name');
  const [transactionRollup, setTransactionRollup] = useState(0);
  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      const { data } = await client.get(`/reports/transaction_summary`, {
        params: { store_id: store_id, ...filters, ...tableFilters, $groupBy: reportGroupBy.split(',') },
        signal: controller.signal,
      });

      setReportData(data);

      // TODO@transaction-summary: Implement proper server side rollup behavior so
      // that this chart can work correctly. For now it only shows the results
      // for the current page.
      const chartData = _.flatMap(data.data, (row) => {
        return [
          { status: 'Sales', count: row.sales_amount },
          { status: 'Declines', count: row.declined_amount },
          { status: 'Refunds', count: row.refund_amount },
          { status: 'Gross', count: row.gross_revenue },
          { status: 'Net', count: row.net_revenue },
        ];
      });

      const formattedChartData = [
        {
          x: chartData.map((d) => d.status),
          y: chartData.map((d) => d.count),
        },
      ];
      setTransactionRollup(formattedChartData);
    })();

    return () => {
      controller.abort();
    };
  }, [filters, tableFilters, reportGroupBy, client, store_id]);

  async function handleFilterQuery(filters) {
    setFilters(getQueryParamsFromFilterFormData(filters));
  }

  async function handleDataState(dataState) {
    setTableFilters(getQueryParamsFromDataState(dataState, '$sortBy'));
  }

  function handleDetailReportChange(event, newValue) {
    setReportGroupBy(newValue);
    setTableFilters(_.pick(tableFilters, ['$skip', '$limit']));
  }

  return (
    <Grid item container xs={12} className={classes.pageRoot} alignContent="flex-start">
      <Header heading="Transaction Summary"></Header>

      <PageSection header="Filters">
        <TransactionSummaryFiltersFormik filterFn={handleFilterQuery} />
      </PageSection>

      <PageSection header="Charts" className={classes.chartSection}>
        <BarChart title="Revenue Rollup" id="transaction-chart" data={transactionRollup} />
      </PageSection>

      <PageSection header="Summary" className={classes.summarySection}>
        <AppBar position="static">
          <Tabs value={reportGroupBy} onChange={handleDetailReportChange}>
            <Tab label="gateway" value="payment_configuration.name" {...a11yProps(0)} />
            <Tab label="affiliate" value="session.aff_id" {...a11yProps(1)} />
            <Tab label="sub-affiliate" value="session.aff_id,session.subid_1" {...a11yProps(2)} />
            <Tab label="campaign" value="session.utm_campaign" {...a11yProps(3)} />
            <Tab label="source" value="session.utm_source" {...a11yProps(4)} />
            <Tab label="currency" value="order.currency_iso" {...a11yProps(5)} />
            <Tab label="payment-method" value="order.payment_method" {...a11yProps(6)} />
          </Tabs>
        </AppBar>
        <TransactionSummaryDG
          rows={reportData}
          handleDataState={handleDataState}
          groupBy={reportGroupBy}
        />
      </PageSection>
    </Grid>
  );
}

const useStyles = makeStyles({
  pageRoot: {
    height: '95vh',
    overflow: 'auto',
  },
  summarySection: {
    flexGrow: 1,
    minHeight: 0,
    overflow: 'auto',
    width: '80vh',
    padding: '4px',
  },
  chartSection: {
    flexGrow: 1,
    minHeight: 300,
    overflow: 'auto',
    // NOTE@charts: This fixes the issue with Plotly not scaling correctly to fill a flex container
    '& .js-plotly-plot, .plot-container': {
      height: '30vh',
      minHeight: 200,
    },
    padding: '4px',
  },
});
