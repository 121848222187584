// Components
import { Grid } from '@material-ui/core';
import Button from 'components/Button';
import FilterFormHOC from './FilterFormHOC';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';

export default function ProductsFilterForm({ filterFn }) {
  return (
    <FilterFormHOC>
      <Formik
        initialValues={{ search: '' }}
        onSubmit={(values) => {
          filterFn(values);
        }}
        validateOnChange={true}
      >
        {({ isSubmitting, dirty, resetForm }) => {
          return (
            <Form>
              <Grid container item xs={12} spacing={1}>
                <Grid container item xs={9} md={4} lg={3} spacing={1}>
                  <Field name="search" component={TextField} label="search" id="search" />
                </Grid>

                <Grid container item xs={9} md={4} lg={2} alignContent="flex-end" alignItems="flex-end">
                  <Button type="submit" disabled={!dirty || isSubmitting}>
                    apply
                  </Button>
                  <Button type="reset" color="secondary" onClick={resetForm}>
                    reset
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </FilterFormHOC>
  );
}
