import React from 'react';
import _ from 'lodash';
import DateFnsUtils from '@date-io/date-fns';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

export default function DatePicker({ value, setDate, label, ...props }) {
  const allowedProps = ['disableToolbar', 'variant', 'margin', 'format', 'label', 'KeyboardButtonProps'];
  const overrides = _.pick(props, allowedProps);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        label={label}
        value={value}
        onChange={setDate}
        KeyboardButtonProps={{
          'aria-label': label,
        }}
        {...overrides}
      />
    </MuiPickersUtilsProvider>
  );
}
