import _ from 'lodash';
import * as yup from 'yup';
import { requiredMessage } from 'lib/form_helpers';

// components
import { Formik, Form } from 'formik';
import { Grid, Typography } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import OTextField from './form/OTextField';
import { FormikSimpleSelect } from './form/SimpleSelect';
import FormButtons from './form/FormButtons';
import JsonEditorField from './form/JsonEditorField';

// Hooks
import { useState } from 'react';

const formSchema = yup.object().shape({
  name: yup.string().required(requiredMessage),
  slug: yup.string().required(requiredMessage),
  configuration: yup.object().shape({
    select_configuration_strategy: yup
      .object()
      .shape({
        type: yup.string().oneOf(['aggregation', 'most', 'least', 'weight']).required(),

        aggregate_steps: yup.array().when('type', (type, schema) => {
          if (type !== 'aggregation') {
            return schema.notRequired();
          }
        }),
        sort_column: yup.string().when('type', (type, schema) => {
          if (['most', 'least'].includes(type)) {
            return schema.required();
          }
        }),
        range: yup.string().when('type', (type, schema) => {
          if (['most', 'least'].includes(type)) {
            return schema.required();
          }
        }),

        weights: yup.object().when('type', {
          is: 'weight',
          then: yup.object(),
          else: yup.object().optional(),
        }),
      })
      .optional(),
  }),
});

export default function PaymentGroupsForm({
  inputChange,
  paymentGroup,
  processors,
  availableProcessors,
  handleSavePaymentGroup,
  ...props
}) {
  const [showHelp, setShowHelp] = useState(false);

  let initialValuesPrep = _.pick(paymentGroup, 'name', 'slug', 'configuration');

  const initialValues = _.isObject(initialValuesPrep)
    ? formSchema.cast(initialValuesPrep)
    : { name: '', slug: '', configuration: {} };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        handleSavePaymentGroup(values);
      }}
      validationSchema={formSchema}
      enableReinitialize={true}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form {...props}>
            <Grid item spacing={1} container xs={12}>
              <Grid item md={6} sm={12}>
                <OTextField name="name" label="name" />
              </Grid>
              <Grid item md={6} sm={12}>
                <OTextField name="slug" label="slug" />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormikSimpleSelect
                  name="configuration.select_configuration_strategy.type"
                  id="select_configuration_strategy"
                  label="Payment Config Select Strategy"
                  items={[
                    { label: 'weight', value: 'weight', key: 'select_strategy-weight' },
                    { label: 'aggregation', value: 'aggregation', key: 'select_strategy-aggregation' },
                    { label: 'most', value: 'most', key: 'select_strategy-most' },
                    { label: 'least', value: 'least', key: 'select_strategy-least' },
                  ]}
                  onChange={({ target: { name, value } }) => {
                    setFieldValue(name, value);

                    // we must setup defaults to avoid errors trying to access undefined values when switching strategy to weight
                    // since the configuration.select_configuration_strategy.weights object is not initialized
                    if (value === 'weight') {
                      setFieldValue('configuration.select_configuration_strategy.weights', {});
                    }
                  }}
                />
                {values.configuration.select_configuration_strategy.type === 'weight' && (
                  <Grid item md={6} xs={12}>
                    <h3>Weights</h3>
                    <p>Weights are relative to each other after filtering by rules.</p>
                    {paymentGroup.payment_configurations.map((pc) => (
                      <OTextField
                        name={`configuration.select_configuration_strategy.weights.${pc.id}`}
                        value={values.configuration.select_configuration_strategy?.weights[pc.id] ?? 0}
                        label={pc.name}
                        key={`pc-weight-${pc.id}`}
                        type="number"
                      />
                    ))}
                    weight configurator
                  </Grid>
                )}
                {/* NOTE: the value property allows us to override conditional values to avoid uncontrolled input errors*/}
                {['most', 'least'].includes(values.configuration.select_configuration_strategy.type) && (
                  <Grid item md={6} xs={12}>
                    <FormikSimpleSelect
                      id="range_select"
                      name="configuration.select_configuration_strategy.range"
                      value={values.configuration.select_configuration_strategy.range ?? ''}
                      label="Range"
                      items={['day', 'week', 'month'].map((range) => {
                        return {
                          label: range,
                          value: range,
                          key: `range_select-${range}`,
                        };
                      })}
                    />
                  </Grid>
                )}
                {['most', 'least'].includes(values.configuration.select_configuration_strategy.type) && (
                  <Grid item md={6} xs={12}>
                    <FormikSimpleSelect
                      id="sort_column_select"
                      name="configuration.select_configuration_strategy.sort_column"
                      value={values.configuration.select_configuration_strategy.sort_column ?? ''}
                      label="Sort Column"
                      items={[
                        'chargeback_amount',
                        'chargeback_count',
                        'declined_amount',
                        'declined_count',
                        'monthly_cap_percentage',
                        'pending_amount',
                        'pending_count',
                        'process_amount',
                        'process_count',
                        'processor_monthly_cap',
                        'refund_amount',
                        'refund_count',
                      ].map((report_column) => {
                        return {
                          label: report_column,
                          value: report_column,
                          key: `sort_column_select-${report_column}`,
                        };
                      })}
                    />
                  </Grid>
                )}
              </Grid>
              {values.configuration.select_configuration_strategy.type === 'aggregation' && (
                <>
                  <Grid item xs={12}>
                    <span
                      onClick={() => {
                        setShowHelp((value) => !value);
                      }}
                      title="show/hide help"
                      style={{ cursor: 'pointer' }}
                    >
                      <HelpOutline />
                    </span>
                    {/* TODO: make the help section look better */}
                    <div style={{ display: showHelp ? 'block' : 'none' }}>
                      <p>
                        Aggregate Steps is an array of objects. These objects consist of mongodb
                        aggregation queries.
                      </p>
                      <pre>
                        <code>
                          {`[
                            { "$sort": { "chargeback_amount": -1 } },
                          ]`}

                          {`\n// the rules are mongo based query operators on the following fields`}

                          {`{
                            processor_id: {type: 'string' },
                            processor_monthly_cap: numberPrecisionTwo,
                            chargeback_amount: numberPrecisionTwo,
                            chargeback_count: { type: 'number' },
                            process_amount: numberPrecisionTwo,
                            process_count: { type: 'number' },
                            refund_amount: numberPrecisionTwo,
                            refund_count: { type: 'number' },
                            range: { type: 'string' },
                            monthly_cap_percentage: { type: 'number', precision: 4 },
                            declined_amount: numberPrecisionTwo,
                            declined_count: numberPrecisionTwo,
                            pending_amount: numberPrecisionTwo,
                            pending_count: numberPrecisionTwo
                          }`}
                        </code>
                      </pre>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h3">Aggregate Steps</Typography>
                    <JsonEditorField
                      name="configuration.select_configuration_strategy.aggregate_steps"
                      value={values.configuration.select_configuration_strategy.aggregate_steps ?? []}
                      label="Aggregate Steps"
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormButtons />
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}
let configuration_example = {
  select_configuration_strategy: {
    type: 'aggregation',
    aggregate_steps: [
      {
        $sort: {
          latest_payment_transaction_created_at: -1,
        },
      },
    ],
  },
};
