//components
import FormikSimpleSelect from './form/FormikSimpleSelect';

// hooks
import { useState, useEffect } from 'react';
import { useAuth } from 'hooks/useAuth';
import { useHistory } from 'react-router-dom';

export default function CountrySelector({ onChange, defaultValue = 'US', ...props }) {
  const history = useHistory();
  const { client } = useAuth();

  const [state, setState] = useState({
    geoCountries: [],
    selectedCountry: { code_iso2: '', name: '' },
    isLoaded: false,
  });

  if (!state.isLoaded) {
    setState({ ...state, isLoaded: true });
  }

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      let { data } = await client.get(`/admin/geo_countries`, {
        params: {
          $select: ['code_iso2', 'name'],
        },
        signal: controller.signal,
      });
      const selectedCountry = selectDefault(data, defaultValue);
      setState((s) => ({ ...s, geoCountries: data, selectedCountry }));
    })();

    return () => {
      controller.abort();
    };
  }, [state.isLoaded, client, history, defaultValue]);

  function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
  }

  function selectDefault(geoCountries, code_iso2) {
    return geoCountries.find((i) => i.code_iso2 === code_iso2) || { code_iso2: '', name: '' };
  }

  return (
    <FormikSimpleSelect
      {...props}
      items={state.geoCountries.map((country) => ({
        label: `${countryToFlag(country.code_iso2)} ${country.code_iso2} ${country.name}`,
        value: country.code_iso2,
      }))}
    />
  );
}
