import { useState, useDebugValue } from 'react';
import moment from 'moment-timezone';

/**
 * parseCurrency
 * turns a string into a 2 decimal precision float
 *
 * @param {string|number} amount
 * @returns number
 */
export function parseCurrency(amount) {
  return parseFloat(parseFloat(amount).toFixed(2));
}

/**
 * Format and return a number as a currency string with the appropriate currency
 * symbol and decimal separator.
 *
 * @param {string|number} amount
 * @returns string formatted to 2 decimal places
 */
export function formatCurrency(amount, currencyCodeISO = 'USD') {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCodeISO,
  }).format(parseCurrency(amount));
}

/**
 * Format and return a number with major and minor separators and fixed to a
 * given number of decimal places.
 * @param {string|number} amount
 * @param {*} radix The precision of the number
 * @returns string returns a formatted number with major and minor separators
 */
export function formatNumber(amount, radix = 0) {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: radix,
  }).format(+parseFloat(amount).toFixed(radix));
}

export function useStateWithLabel(initialValue, name) {
  const [value, setValue] = useState(initialValue);
  useDebugValue(`${name}: ${value}`);
  return [value, setValue];
}

export function formatDate(date) {
  return moment(date).format('MM/DD/YY');
}

export function formatDateTime(date, dateFormat = 'MM/DD/YY HH:mm') {
  return moment(date).format(dateFormat);
}

/**
 * sortDateDesc
 * Sorts a standard record with created_at, updated_at fields by date in desc order
 *
 * @param {object} - record "a" to compare
 * @param {object} - record "b" to compare
 */
export function sortDateDesc(a, b) {
  return new Date(b.created_at) - new Date(a.created_at);
}

/**
 * Build an Object key:value pair for an $ilike query
 *
 * @param {string} property
 * @param {string} value
 * @returns {Object} the object
 */
export function formatILike(property, value) {
  return { [`${property}[$ilike]`]: `%${value}%` };
}

/**
 * Formats a ratio as a percentage
 *
 * @param {number} ratio
 * @returns {string} the percentage
 */
export function formatPercentage(ratio) {
  return `${+(100 * ratio).toFixed(2)}%`;
}

// TODO@currency: Load these codes from the server. This is just a temporary list.
export const currencyCodes = [
  'USD',
  'EUR',
  'GBP',
  'AUD',
  'CAD',
  'CHF',
  'CNY',
  'DKK',
  'HKD',
  'JPY',
  'NZD',
  'SEK',
  'SGD',
  'THB',
  'ZAR',
];
