import PropTypes from 'prop-types';
import * as yup from 'yup';
import { requiredMessage } from 'lib/form_helpers';

// Components
import { Formik, Form } from 'formik';
import { Grid } from '@material-ui/core';
import OTextField from 'components/form/OTextField';
import GeneralModal from 'components/modals/GeneralModal';
import Button from 'components/Button';

// Hooks
import { useAuth } from 'hooks/useAuth';

const formSchema = yup.object().shape({
  name: yup.string().required(requiredMessage),
  email: yup.string().email(`Must be valid email`).required(requiredMessage),
});

export default function CreateNewCustomerModal({ handleSave, ...props }) {
  const { client } = useAuth();

  return (
    <GeneralModal title="Create New Customer" maxWidth="sm" {...props}>
      <Formik
        initialValues={{
          name: '',
          email: '',
        }}
        validationSchema={formSchema}
        onSubmit={async (values) => {
          let { data } = await client.post(`/admin/customers`, {
            name: values.name,
            email: values.email,
            type: 'prospect',
          });
          handleSave(data);
        }}
      >
        {({ isSubmitting, dirty, resetForm }) => {
          return (
            <Form>
              <Grid container spacing={1}>
                <Grid item sm xs={12}>
                  <OTextField name="name" label="name" />
                </Grid>
                <Grid item sm xs={12}>
                  <OTextField name="email" label="email" />
                </Grid>

                <Grid container item xs={12} justifyContent="center">
                  <Button type="submit" disabled={isSubmitting || !dirty}>
                    save
                  </Button>
                  <Button
                    color="secondary"
                    type="reset"
                    disabled={isSubmitting || !dirty}
                    onClick={resetForm}
                  >
                    reset
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </GeneralModal>
  );
}

CreateNewCustomerModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};
