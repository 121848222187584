import React, { createContext, useState, useCallback, useContext, useMemo, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import moment from 'moment-timezone';
import { AbilityBuilder, Ability } from '@casl/ability';
import _ from 'lodash';

import { useApplication } from 'contexts/application.context';
import { useSnackbar } from 'notistack';

const authContext = createContext();
const apiUrl = process.env.REACT_APP_API_URL;

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const { dispatch } = useApplication();

  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user') ?? '{}'));
  const [token, setToken] = useState(localStorage.getItem('token') ?? null);
  const [store, setStore] = useState(null);
  const [stores, setStores] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const setStoreWithPersistence = useCallback((store) => {
    localStorage.setItem('store_id', store.id);
    setStore(store);
  }, []);

  const signin = async (email, password) => {
    try {
      let { data } = await client.post(`${apiUrl}/user_authentication`, {
        strategy: 'local',
        email,
        password,
      });

      let { users, accessToken } = data;
      setUser(users);
      localStorage.setItem('user', JSON.stringify(users));
      setToken(accessToken);
      localStorage.setItem('token', accessToken);

      return { user: users, accessToken };
    } catch (error) {
      console.error('found error with sigin:', error);
      throw error;
    }
  };

  const signout = async () => {
    setToken(null);
    localStorage.setItem('token', null);

    setUser({});
    localStorage.setItem('user', null);

    dispatch({ type: 'UNSET-STORE' });
  };

  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(Ability);
    if (!user) return build();

    let { roles } = user;
    if (_.map(roles, 'name').includes('admin')) {
      can('access', 'all');
    }
    if (_.map(roles, 'name').includes('csrep')) {
      can('access', 'orders');
      can('access', 'customers');
    }
    return build();
  }, [user]);

  const client = useMemo(() => {
    const axiosClient = axios.create({
      baseURL: apiUrl,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    axiosClient.interceptors.request.use(
      function (config) {
        dispatch({ type: 'LOADING-START' });
        return config;
      },
      function (error) {
        dispatch({ type: 'LOADING-DONE' });
        dispatch({ type: 'DISPLAY-ERROR', error });
        return Promise.reject(error);
      }
    );

    axiosClient.interceptors.response.use(
      function (response) {
        dispatch({ type: 'LOADING-DONE' });
        return response;
      },
      function (error) {
        // clear the global loading overlay
        dispatch({ type: 'LOADING-DONE' });

        // determine how we handle the error
        if (error?.response) {
          switch (error.response?.status) {
            case 401:
              enqueueSnackbar('Your login has expired. Please login again');
              break;

            case 400:
              if (error?.response?.data?.message) {
                enqueueSnackbar(error.response?.data?.message);
              } else {
                enqueueSnackbar(
                  `An unknown error has occured while fetching data. Please make sure you are connected to the internet.`
                );
              }
              break;

            default:
              dispatch({ type: 'DISPLAY-ERROR', error });
          }
        } else {
          // this will happen if the server is down or network error occurs
          dispatch({
            type: 'DISPLAY-ERROR',
            error,
            redirect: '/login',
            message:
              'There was an error communicating with the server. Please check your internet connection and try again.',
          });

          enqueueSnackbar(
            'There was an error communicating with the server. Please check your internet connection and try again.'
          );
        }

        // the request never completed
        return Promise.reject(error);
      }
    );

    return axiosClient;
  }, [token, dispatch, enqueueSnackbar]);

  const validateToken = useCallback(
    function validateToken() {
      if (!token) return false;
      let decoded;
      try {
        decoded = jwt_decode(token);
        let is_expired = moment().isAfter(new Date(+decoded.exp * 1000));

        return !is_expired && decoded;
      } catch (error) {
        return false;
      }
    },
    [token]
  );

  const fetchStores = useCallback(
    async function fetchStores() {
      if (!validateToken()) {
        return true;
      }

      let { data } = await client.get(`/admin/stores`);
      setStores(data);
    },
    [validateToken, client]
  );

  useEffect(() => {
    // set the default store if not set
    if (!store) {
      if (localStorage.getItem('store_id')) {
        setStore(_.find(stores, { id: localStorage.getItem('store_id') }));
      } else {
        let defaultStore = _.find(stores, { is_default: true });
        if (defaultStore) {
          setStoreWithPersistence(defaultStore);
        }
      }
    }
  }, [stores, store, setStoreWithPersistence]);

  // TODO: figure out a better way to do this when not pressured for time
  let jsonApiContentType = 'application/vnd.api+json'; //
  const clientJsonApi = axios.create({
    baseURL: apiUrl,
    headers: {
      Authorization: `Bearer ${token}`,
      'content-type': jsonApiContentType,
      'x-content-type': jsonApiContentType,
    },
  });

  return {
    ability,
    client,
    clientJsonApi,
    fetchStores,
    setStore: setStoreWithPersistence,
    signin,
    signout,
    store,
    stores,
    token,
    user,
    validateToken,
  };
}
