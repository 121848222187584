import React from 'react';
import * as yup from 'yup';
import { requiredMessage } from 'lib/form_helpers';

// Components
import { Grid } from '@material-ui/core';
import Header from 'components/Header';
import OTextField from 'components/form/OTextField';
import PageSection from 'components/PageSection';
import { Formik, Form } from 'formik';
import FormButtons from 'components/form/FormButtons';

// Hooks
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { useTheme } from '@material-ui/core/styles';

const formSchema = yup.object().shape({
  name: yup.string().required(requiredMessage),
  slug: yup.string().required(requiredMessage),
});
export default function PaymentGroupsNewPage(props) {
  const { client } = useAuth();
  const history = useHistory();

  const { url } = useRouteMatch(`/store/:store_id`);
  const { store_id } = useParams();
  const theme = useTheme();

  return (
    <Grid container>
      <Header heading="New Payment Group"></Header>
      <PageSection>
        <Formik
          initialValues={{
            name: '',
            slug: '',
          }}
          validationSchema={formSchema}
          onSubmit={async (values) => {
            let paymentGroupData = { ...values, store_id };
            let { data } = await client.post(`/admin/payment_groups`, paymentGroupData);
            history.push(`${url}/payment_groups/${data.id}`);
          }}
        >
          {() => {
            return (
              <Form>
                <Grid container spacing={theme.spacing(1)}>
                  <Grid item sm={6} xs={12}>
                    <OTextField name="name" label="Name" />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <OTextField name="slug" label="Slug" />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormButtons />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </PageSection>
    </Grid>
  );
}
