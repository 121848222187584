import _ from 'lodash';
import * as yup from 'yup';
import { requiredMessage } from 'lib/form_helpers';

// Components
import { Grid, MenuItem, FormHelperText } from '@material-ui/core';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { OFormikCheckBox } from 'components/form/OCheckBox';
import OTextField from 'components/form/OTextField';
import { Select } from 'formik-material-ui';
import PageLoading from 'components/Loading/PageLoading';
import FormButtons from 'components/form/FormButtons';

// Hooks
import { useEffect, useState } from 'react';
import { useAuth } from 'hooks/useAuth';
import { useLocation } from 'react-router-dom';

const nmiCredentialsSchema = yup.object().shape({
  security_key: yup.string().required(requiredMessage),
});

const stripeCredentialsSchema = yup.object().shape({
  api_key: yup.string().required(requiredMessage),
});

const paypalCredentialsSchema = yup.object().shape({
  client_id: yup.string().required(requiredMessage),
  client_secret: yup.string().required(requiredMessage),
});

const formSpacing = 1;

export default function ProcessorForm({ id, onSave }) {
  const { client } = useAuth();
  const location = useLocation();

  const initialValues = {
    accepted_payment_types: [],
    chargeback_fee: 0,
    chargeback_phone_number: '',
    configuration: {},
    credentials: {
      api_key: '',
      username: '',
      password: '',
      client_id: '',
      client_secret: '',
    },
    descriptor: '',
    discount_rate: 0,
    is_enabled: true,
    is_sandbox: false,
    merchant_id: '',
    monthly_cap: 0,
    name: '',
    phone_number: '',
    reserve_rate: 0,
    support_email: '',
    transaction_fee: 0,
    type: '',
    update_credentials: id ? false : true,
    website_url: '',
  };
  const [processor, setProcessor] = useState(initialValues);

  useEffect(() => {
    if (!id) {
      return;
    }
    const controller = new AbortController();
    (async () => {
      let { data } = await client.get(`/admin/processors/${id}`, {
        signal: controller.signal,
      });

      // we destructure the data to remove accepted_payment_types so we can utilize only the `data` portion
      // when we post in the accepted_payment_types, the api will structure the data for a jsonb patch
      let { accepted_payment_types, ...formattedProcessor } = data;
      formattedProcessor.accepted_payment_types = accepted_payment_types.data;

      setProcessor(formattedProcessor);
    })();

    return () => {
      controller.abort();
    };
  }, [id, client, location.key]);

  // Moved into the scope of the component since it relies on the `id` to determine
  // if the credentials are required
  const formSchema = yup.object().shape({
    accepted_payment_types: yup.array().of(yup.string()).min(1).required(),
    chargeback_fee: yup.number().max(99_999_999).min(0.0).required(requiredMessage),
    chargeback_phone_number: yup.string(),
    credentials: yup.mixed().when(['type', 'update_credentials'], (type, update_credentials) => {
      if (!update_credentials && id) {
        return yup.mixed().nullable();
      }

      switch (type) {
        case 'nmi':
          return nmiCredentialsSchema;
        case 'paypal':
          return paypalCredentialsSchema;
        case 'stripe':
          return stripeCredentialsSchema;
        default:
        // do nothing, we'll unmount the credentials components
      }
    }),
    descriptor: yup.string(),
    discount_rate: yup.number().max(999_999).min(0).required(requiredMessage),
    is_sandbox: yup.bool().required(requiredMessage),
    merchant_id: yup.string().when(['type'], (type) => {
      if (type === 'nmi') {
        return yup.string().required();
      }
      return yup.string();
    }),
    monthly_cap: yup.number().min(0.01).max(999_999_999_999).required(requiredMessage),
    name: yup.string().required(requiredMessage),
    phone_number: yup.string(),
    reserve_rate: yup.number().max(999_999).min(0.0).required(requiredMessage),
    support_email: yup.string(),
    transaction_fee: yup.number().max(99_999_999).min(0.0).required(requiredMessage),
    type: yup.string().required(requiredMessage),
    website_url: yup.string(),
  });

  if (!processor) {
    return <PageLoading />;
  }

  return (
    <Grid container>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Formik
            enableReinitialize
            validateOnChange={true}
            initialValues={processor}
            onSubmit={async ({
              credentials,
              id,
              phone_number,
              chargeback_phone_number,
              update_credentials,
              ...rest
            }) => {
              let { type } = rest;
              let processorData = { ...rest };
              if (_.isString(phone_number)) {
                processorData.phone_number = phone_number.replace(/[^\d]/g, '');
              }
              if (_.isString(chargeback_phone_number)) {
                processorData.chargeback_phone_number = chargeback_phone_number.replace(/[^\d]/g, '');
              }
              let essentialCreds;
              switch (type) {
                case 'nmi':
                  essentialCreds = ['security_key'];
                  break;
                case 'stripe':
                  essentialCreds = ['api_key'];
                  break;
                case 'paypal':
                  essentialCreds = ['client_id', 'client_secret'];
                  break;
                case 'overhub':
                  essentialCreds = [];
                  break;
                default:
                  throw new Error(`A type needs to be assigned`);
              }
              if (update_credentials) {
                processorData.credentials = _.pick(credentials, essentialCreds);
              }

              // determine if this is a new processor or an existing one
              let data;
              if (id) {
                ({ data } = await client.patch(`/admin/processors/${id}`, processorData));
              } else {
                ({ data } = await client.post(`/admin/processors/`, processorData));
              }
              onSave(data);
            }}
            validationSchema={formSchema}
          >
            {({ values, errors }) => (
              <Form>
                <Grid container item xs={12}>
                  <Grid item xs={6}>
                    <OTextField name="name" label="Name" />
                    <OFormikCheckBox name="is_sandbox" label="Is Sandbox?" />
                    <OFormikCheckBox name="is_enabled" label="Is Enabled?" />
                  </Grid>

                  <Grid container item xs={12}>
                    <Grid item xs={6}>
                      <Field component={Select} id="type" name="type" label="Type">
                        <MenuItem value="">Select a type</MenuItem>

                        {['paypal', 'stripe', 'nmi', 'overhub'].map((type) => {
                          return (
                            <MenuItem key={`${type}-type-select-item`} value={type}>
                              {type}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} spacing={formSpacing}>
                    <Grid item xs={12}>
                      <h3>Rates & Fees</h3>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <OTextField name="monthly_cap" id="monthly_cap" label="Monthly Cap" />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <OTextField name="discount_rate" id="discount_rate" label="Discount Rate" />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <OTextField name="reserve_rate" id="reserve_rate" label="Reserve Rate" />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <OTextField name="transaction_fee" id="transaction_fee" label="Transaction Fee" />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <OTextField name="chargeback_fee" id="chargeback_fee" label="Chargeback Fee" />
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} spacing={formSpacing}>
                    <Grid item xs={12}>
                      <h3>Accepted Payment Types</h3>
                    </Grid>
                    <Grid item xs={12}>
                      <ErrorMessage
                        render={(msg) => <FormHelperText error>{msg}</FormHelperText>}
                        name="accepted_payment_types"
                      />
                    </Grid>
                    {[
                      'visa',
                      'mastercard',
                      'american-express',
                      'discover',
                      'echeck',
                      'direct-debit',
                      'paypal',
                    ].map((type) => (
                      <OFormikCheckBox
                        name="accepted_payment_types"
                        id={`accepted_payment_types-${type}`}
                        value={type}
                        label={type}
                        key={`${type}-payment-type-key`}
                      />
                    ))}
                  </Grid>

                  <Grid container item xs={12} spacing={formSpacing}>
                    <Grid item xs={12}>
                      <h3>Support Info</h3>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <OTextField name="phone_number" id="phone_number" label="Phone Number" />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <OTextField
                        name="chargeback_phone_number"
                        id="chargeback_phone_number"
                        label="Chargeback Phone Number"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <OTextField name="support_email" id="support_email" label="Support Email" />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <OTextField name="website_url" id="website_url" label="Website URL" />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <OTextField name="descriptor" id="descriptor" label="Descriptor" />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <OTextField name="merchant_id" id="merchant_id" label="Merchant ID (MID)" />
                    </Grid>
                  </Grid>

                  <Grid item xs={2}>
                    <h3>Credentials</h3>
                    <OFormikCheckBox
                      label="Update Credentials?"
                      name="update_credentials"
                      disabled={!Boolean(values.type)}
                    />
                  </Grid>
                  {values.update_credentials && (
                    <Grid item xs={12}>
                      {values.type === 'stripe' && (
                        <OTextField label="api_key" name="credentials.api_key" />
                      )}

                      {values.type === 'nmi' && (
                        <OTextField name="credentials.security_key" label="Security Key" />
                      )}

                      {values.type === 'paypal' && (
                        <>
                          <OTextField label="Client ID" name="credentials.client_id" />
                          <OTextField label="API Secret" name="credentials.client_secret" />
                        </>
                      )}
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormButtons />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Grid>
  );
}
