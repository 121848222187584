// Components
import { Grid } from '@material-ui/core';
import Button from 'components/Button';
import { Formik, Form } from 'formik';
import OTextField from 'components/form/OTextField';
import FormikSimpleSelect from 'components/form/FormikSimpleSelect';
import CountrySelector from '../CountrySelector';
import StateSelector from '../StateSelector';
import { OFormikCheckBox } from './OCheckBox';

// Hooks
import { forwardRef } from 'react';
import { useAuth } from 'hooks/useAuth';
import { makeStyles } from '@material-ui/core/styles';

const FormikTextField = forwardRef((props, ref) => (
  <OTextField variant="standard" {...props} ref={ref} />
));

export default function NewContactForm({ dispatch, userId, type, contact }) {
  const classes = useStyles();
  const { client } = useAuth();

  const initialContact = contact ?? {
    address_1: '',
    address_2: '',
    city: '',
    country_iso: 'US',
    description: '',
    email: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    postal_code: '',
    service_params: { ss_sync: true },
    state: '',
    type,
  };
  initialContact.service_params = { ss_sync: true };

  const spacing = 2;
  return (
    <Formik
      enableReinitialize
      initialValues={initialContact}
      onSubmit={async (values) => {
        let { data } = await client.patch('/contacts', { ...values, user_id: userId });

        dispatch({ type: 'SAVE-CONTACT', contact: data, contact_type: values.type });
      }}
    >
      {({ dirty, isSubmitting, resetForm, values }) => (
        <Form>
          <Grid container classes={{ root: classes.container }}>
            <Grid container item xs={12} className={classes.formSection} spacing={spacing}>
              <Grid item xs={4}>
                <FormikTextField label="First Name" name="first_name" />
              </Grid>
              <Grid item xs={4}>
                <FormikTextField label="Last Name" name="last_name" />
              </Grid>
              <Grid item xs={4}>
                <FormikTextField label="Email" name="email" />
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={spacing}>
              <Grid item xs={4}>
                <FormikTextField label="Phone Number" name="phone_number" />
              </Grid>
              <Grid item xs={4}>
                <FormikTextField label="Address 1" name="address_1" />
              </Grid>
              <Grid item xs={4}>
                <FormikTextField label="Address 2" name="address_2" />
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={spacing}>
              <Grid item xs={4}>
                <FormikTextField label="City" name="city" />
              </Grid>

              <Grid item xs={4}>
                <FormikTextField label="Postal Code" name="postal_code" />
              </Grid>

              <Grid item xs={4}>
                <FormikSimpleSelect
                  items={[
                    { value: 'shipping', label: 'Shipping' },
                    { value: 'billing', label: 'Billing' },
                  ]}
                  name="type"
                  label="Contact Type"
                  classes={{ root: classes.typeSelectorFormControl }}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={2}>
              <Grid item xs={6}>
                <CountrySelector
                  name="country_iso"
                  label="Select Country"
                  classes={{ root: classes.countrySelector }}
                  autowidth={true}
                ></CountrySelector>
              </Grid>
              <Grid item xs={6}>
                <StateSelector
                  name="state"
                  label="Select State"
                  countryIso2={values.country_iso}
                  classes={{ root: classes.stateSelector }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormikTextField label="Description" name="description" />
            </Grid>

            <Grid item xs={12}>
              <OFormikCheckBox label="Update Associated Fulfillments" name="service_params.ss_sync" />
            </Grid>
            <Grid item xs={12}>
              <Button color="secondary" onClick={resetForm} disable={!dirty}>
                Reset
              </Button>
              <Button type="submit" disabled={!dirty || isSubmitting}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

const useStyles = makeStyles({
  formRoot: {
    '& .MuiFormControl-root': {
      marginRight: '5px',
    },

    '& .MuiGrid-root': {
      marginBottom: '5px',
    },
  },

  '& .MuiSelect-root': {
    width: '15rem',
  },
  typeSelectorFormControl: {
    width: '15rem',
  },

  descriptionField: {
    width: '60rem',
  },

  container: {
    maxWidth: '600px',
  },

  countrySelector: {
    minWidth: '100%',
  },

  stateSelector: {
    minWidth: '100%',
  },
});
