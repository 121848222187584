import {
  buildReadPermissionGraph,
  buildWritePermissionGraph,
  buildStorePermissionGraph,
} from 'lib/graph_helpers';

import * as yup from 'yup';
import S from 'lib/stringomatic';
import qs from 'qs';
import _ from 'lodash';

// Components
import { Button, Grid } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { Switch } from 'formik-material-ui';
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
} from '@syncfusion/ej2-react-grids';
import Header from 'components/Header';
import OTextField from 'components/form/OTextField';
import PageSection from 'components/PageSection';
import PageLoading from 'components/Loading/PageLoading';
import SwitchGroup from 'components/form/SwitchGroup';
import EditAccessConditionsModal from 'components/modals/EditAccessConditionsModal';

// Hooks
import { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

// exists to format the input name to a friendlier string
const requiredMessage = ({ path }) => {
  return `${S.humanize(path)} is Required`;
};

const formSchema = yup.object().shape({
  name: yup.string().required(requiredMessage),
  readAccess: yup.array().default([]),
  writeAccess: yup.array().default([]),
  storeAccess: yup.array().default([]),
  forceDirty: yup.boolean().default(false),
});

const pageSettings = {
  pageSize: 10,
  // pageSizes: [5, 10, 20, 50, 100, 200, 'All'],
};

const gridProps = {
  pageSettings,
  allowPaging: true,
};

function ConditionsCell({ id, handleEdit, readAccess = false }) {
  if (!readAccess) return null;
  return (
    <div>
      <span className="link" onClick={handleEdit}>
        Edit
      </span>
    </div>
  );
}

export default function RolesEditPage() {
  const { client } = useAuth();
  const { role_id } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [role, setRole] = useState(null);
  const [activeResource, setActiveResource] = useState(null);

  useEffect(() => {
    (async () => {
      let { data } = await client.get(`/admin/roles/${role_id}`, {
        params: {
          $eager: '[resources]',
        },
        paramsSerializer: (params) => qs.stringify(params, { encodeValuesOnly: true }),
      });

      let { data: resources } = await client.get('/admin/resources', {
        params: {
          $sort: {
            name: 1,
          },
        },
        paramsSerializer: (params) => qs.stringify(params, { encodeValuesOnly: true }),
      });

      let { data: stores } = await client.get('/admin/stores', {
        params: {
          $sort: {
            name: 1,
          },
        },
        paramsSerializer: (params) => qs.stringify(params, { encodeValuesOnly: true }),
      });

      data = formSchema.cast(data);

      data.availableStores = stores;
      // exract the read and write access ids
      data.readAccess = _.uniq(
        data.resources
          .filter((r) => {
            return ['find', 'get'].includes(r.access) ? r.id : null;
          })
          .map((r) => r.id)
      );
      data.writeAccess = _.uniq(
        data.resources
          .filter((r) => {
            return ['create', 'update', 'patch', 'remove'].includes(r.access) ? r.id : null;
          })
          .map((r) => r.id)
      );
      data.readConditions = data.resources
        .filter((r) => r.access === 'find')
        .reduce((acc, r) => {
          acc[r.id] = r.conditions;
          return acc;
        }, {});

      data.availableResources = resources.map((r) => {
        return {
          ...r,
          readAccess: data.readAccess.includes(r.id),
          writeAccess: data.writeAccess.includes(r.id),
          readConditions: data.resources.find((rp) => rp.id === r.id && rp.access === 'find')
            ?.conditions,
        };
      });

      const storePermissions =
        _.find(data.resources, { name: 'stores', access: 'access' })?.conditions?.id || {};
      data.storeAccess = storePermissions?.$exists === true ? ['*'] : storePermissions?.$in || [];

      setRole(data);
    })();
  }, [client, role_id, location.key]);

  if (!role) {
    return <PageLoading />;
  }

  return (
    <Grid container>
      <Header heading="Edit Role" />
      <PageSection>
        <Grid item container>
          <Formik
            initialValues={role}
            onSubmit={async (values) => {
              let { id, name, readAccess, writeAccess, storeAccess, availableResources } = values;

              const storeResources = _.filter(availableResources, { name: 'stores' }).map((r) => r.id);

              let reqData = {
                name,
                $graph: {
                  $relate: {
                    resources: {
                      relation: 'resources',
                      replace: true,
                      data: [
                        ...buildReadPermissionGraph(readAccess, role.readConditions),
                        ...buildWritePermissionGraph(writeAccess),
                        ...buildStorePermissionGraph(storeResources, storeAccess),
                      ],
                    },
                  },
                },
              };

              await client.patch(`/admin/roles/${id}`, reqData);
              history.push(`/admin/roles/${id}`);
            }}
            validationSchema={formSchema}
          >
            {({ values, isSubmitting, dirty, setFieldValue }) => {
              return (
                <Form>
                  <Grid container>
                    <Grid item xs={12}>
                      <OTextField name="name" label="Name" />
                    </Grid>
                  </Grid>

                  <h3>Access</h3>
                  <Grid container item xs={12}>
                    <SwitchGroup
                      dataName="storeAccess"
                      label="Store Access"
                      items={role.availableStores}
                      values={values.storeAccess}
                      allSwitch={true}
                    />
                  </Grid>

                  <h3>Permissions</h3>
                  <Grid container item xs={12}>
                    <GridComponent dataSource={role.availableResources} {...gridProps}>
                      <Inject services={[Page]} />
                      <ColumnsDirective>
                        <ColumnDirective field="name" headerText="Name" />

                        <ColumnDirective
                          headerText="Read"
                          template={(props) => (
                            <Field
                              key={`${props.id}-read`}
                              component={Switch}
                              name="readAccess"
                              type="checkbox"
                              value={props.id}
                              color="primary"
                            />
                          )}
                        />
                        <ColumnDirective
                          headerText="Write"
                          template={(props) => (
                            <Field
                              key={`${props.id}-write`}
                              component={Switch}
                              name="writeAccess"
                              type="checkbox"
                              value={props.id}
                              color="primary"
                            />
                          )}
                        />

                        <ColumnDirective
                          headerText="Read Conditions"
                          template={(props) => (
                            <ConditionsCell
                              id={props.id}
                              handleEdit={() => {
                                // NOTE: Since we are not directly editing the form data, we want to mark the form as dirty
                                // so that we can save it.
                                setFieldValue('forceDirty', true);
                                setActiveResource({ id: props.id });
                              }}
                              readAccess={props.readAccess}
                            />
                          )}
                        />
                      </ColumnsDirective>
                    </GridComponent>
                  </Grid>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || !dirty}
                  >
                    Save
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </PageSection>
      {activeResource && (
        <EditAccessConditionsModal
          open={Boolean(activeResource)}
          handleClose={() => setActiveResource(null)}
          id={activeResource?.id}
          readConditions={role.readConditions}
          onApply={(data) => {
            // REVIEW: So we are essentially getting the value back from the
            // JsonEditor in the modal, and then we are applying the conditions
            // to the state object for the role. It will then be referenced when
            // building the graph for the patch request. I'm not happy with this
            // approach, but it will have to do for now until there is a larger
            // refactor around how we manage permissions.
            const { id, conditions } = data;
            setRole((role) => {
              return {
                ...role,
                readConditions: {
                  ...role.readConditions,
                  [id]: conditions,
                },
              };
            });

            setActiveResource(null);
          }}
        />
      )}
    </Grid>
  );
}
