import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    padding: '10px 0 10px 0',
    textAlign: 'center',
  },
});

export default function EmptyStateDG() {
  const classes = useStyles();

  return (
    <Paper classes={{ root: classes.container }}>
      <Typography variant="h5">Not Available</Typography>
    </Paper>
  );
}
