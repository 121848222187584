import _ from 'lodash';
import { parseCurrency } from 'lib/helpers';

export default class PaymentTransaction {
  constructor(data) {
    if (data instanceof PaymentTransaction) {
      return data;
    }

    this._data = data;

    Object.assign(this, data);

    this.amount = parseCurrency(_.get(data, 'amount', 0));
  }

  get truncatedId() {
    return this.id.substring(0, 4) + '...' + this.id.substring(this.id.length - 4);
  }

  canChargeback() {
    return this.type === 'process' && this.status === 'approved' && !this.chargeback?.id;
  }
}
