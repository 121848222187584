import React from 'react';
import * as yup from 'yup';
import { requiredMessage } from 'lib/form_helpers';

// Components
import { Button, FormControl, Grid, MenuItem } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { Select } from 'formik-material-ui';
import Header from 'components/Header';
import OTextField from 'components/form/OTextField';
import PageSection from 'components/PageSection';
import { OFormikCheckBox } from 'components/form/OCheckBox';
import SecretWarningHint from 'components/SecretWarningHint';

// Hooks
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

const formSchema = yup.object().shape({
  name: yup.string().required(requiredMessage),
  api_key: yup.string().required(requiredMessage),
  refund_template_id: yup.string().required(requiredMessage),
  confirm_template_id: yup.string().required(requiredMessage),
  fulfillment_template_id: yup.string().required(requiredMessage),
  cancel_template_id: yup.string().required(requiredMessage),
  void_template_id: yup.string().required(requiredMessage),
});

export default function EmailProvidersNewPage() {
  const { client } = useAuth();
  const { store_id } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch(`/store/:store_id`);

  return (
    <Grid container>
      <Header heading="Create a new Email Provider" />
      <PageSection>
        <Grid item container>
          <Formik
            initialValues={{
              name: '',
              type: 'sib',
              api_key: '',
              refund_template_id: '',
              confirm_template_id: '',
              fulfillment_template_id: '',
              cancel_template_id: '',
              void_template_id: '',
              is_default: false,
            }}
            onSubmit={async (values) => {
              let emailData = {
                store_id,
                name: values.name,
                is_default: values.is_default,
                type: values.type,
                configuration: {
                  templates: {
                    refund_template_id: values.refund_template_id,
                    void_template_id: values.void_template_id,
                    confirm_template_id: values.confirm_template_id,
                    fulfillment_template_id: values.fulfillment_template_id,
                    cancel_template_id: values.cancel_template_id,
                  },
                },
                configuration_secret: {
                  api_key: values.api_key,
                },
              };
              await client.post(`/admin/email_providers`, emailData);
              history.push(`${url}/email_providers`);
            }}
            validationSchema={formSchema}
          >
            {({ handleSubmit, isSubmitting, errors, touched }) => {
              return (
                <Form>
                  <Grid container>
                    <Grid item xs={12}>
                      <OTextField name="name" label="Name" />
                    </Grid>
                    <Grid item xs={12}>
                      <OFormikCheckBox name="is_default" id="is_default" label="Default?" />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl>
                        <Field component={Select} id="type" name="type" label="Type">
                          <MenuItem value="sib">Send in Blue</MenuItem>
                        </Field>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <h3>SIB Authentication</h3>
                    <SecretWarningHint message="You cannot change your api key after it is created." />
                    <OTextField id="api-key" name="api_key" label="api key" />
                  </Grid>

                  <Grid container item xs={12}>
                    <h3>SIB Template ID's</h3>
                    {['refund', 'confirm', 'fulfillment', 'cancel', 'void'].map((type) => {
                      return (
                        <Grid item xs={12} key={`${type}-template`}>
                          <OTextField name={`${type}_template_id`} label={`${type} template id`} />
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                    Save
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </PageSection>
    </Grid>
  );
}
