import React from 'react';

import { Grid } from '@material-ui/core';

export default function SecretWarningHint({ message }) {
  return (
    <div className="hint">
      <Grid container>
        <Grid item>
          <img
            src="/images/choose-wisely.png"
            style={{ width: '90px', marginRight: '10px' }}
            alt={`Holy Grail knight saying, "choose wisely"`}
          />
        </Grid>
        <Grid item>
          <div>{message}</div>
          <p>Choose Wisely</p>
        </Grid>
      </Grid>
    </div>
  );
}
