import qs from 'qs';

// Components
import Header from 'components/Header';
import PageSection from 'components/PageSection';
import { Grid as DataGrid, GridColumn as Column } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { Grid } from '@material-ui/core';
import AppBarButton from 'components/AppBarButton';
import { LinkCell } from 'lib/datagrid_formatters';

// Hooks
import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useRouteMatch, useParams } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

export default function PaymentGroupsIndexPage(props) {
  const auth = useAuth();
  const { client } = auth;
  const [paymentGroups, setPaymentGroups] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [sort, setSort] = useState([{ field: 'name', dir: 'asc' }]);
  const history = useHistory();
  const location = useLocation();
  const { url } = useRouteMatch();
  const { store_id } = useParams();

  const gridRef = useRef(null);

  function ActionsCell({ dataItem }) {
    let { id } = dataItem;
    return <LinkCell url={`${url}/${id}`}>Edit</LinkCell>;
  }

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      let { data } = await client.get(`/admin/payment_groups`, {
        params: { store_id, $eager: `[store]` },
        signal: controller.signal,
      });
      setPaymentGroups(data);
      setIsLoaded(true);
    })();

    return () => {
      controller.abort();
    };
  }, [location.key, store_id, client, history]);

  useEffect(() => {
    const { current: grid } = gridRef;
    if (grid) {
      grid.fitColumns(grid.columns.map((col) => col.id));
    }
  }, [paymentGroups]);

  if (!isLoaded) {
    return <div>loading</div>;
  }

  const columns = [
    { field: 'name', dbField: 'payment_groups.name', title: 'name' },
    { field: 'slug', dbField: 'payment_groups.slug', title: 'slug' },
    { field: 'store.name', dbField: 'stores.name', title: 'store' },
    { field: 'actions', cell: ActionsCell, title: 'actions' },
  ];

  return (
    <Grid container>
      <PageSection>
        <Header heading="Payment Groups">
          <AppBarButton
            onClick={() => {
              history.push(`${url}/new`);
            }}
            variant="contained"
            color="primary"
          >
            New Payment Group
          </AppBarButton>
        </Header>
        <Grid container item xs={12}>
          <DataGrid
            data={orderBy(paymentGroups, sort)}
            ref={gridRef}
            resizable={true}
            sortable={true}
            sort={sort}
            onSortChange={(e) => {
              setSort(e.sort);
            }}
          >
            {columns.map((column) => {
              return <Column {...column} key={column.field} />;
            })}
          </DataGrid>
        </Grid>
      </PageSection>
    </Grid>
  );
}
