import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { Input, FormControl, InputLabel, InputAdornment, IconButton } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { v4 as uuid } from 'uuid';

const useStyles = makeStyles({
  root: {},
});

// TODO: Add configurable input validation based on props
export default function ConfirmInput({ onConfirm, defaultValue, label, disabled, ...props }) {
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue);
  const hasChanged = () => `${value}` !== `${defaultValue}`;

  const inputRef = useRef(uuid());

  const onChange = (event) => {
    inputRef.current = event.target;
    if (value !== event.target.value) {
      setValue(event.target.value);
    }
  };

  // this exists because the underlying input is not controlled by react
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = defaultValue;
    }

    setValue(defaultValue);
  }, [defaultValue]);

  const missingHandler = () => {
    console.warn('Missing handler method: onConfirm');
    console.log(`ConfirmInput:onConfirm(${value},${defaultValue})`);
  };

  const myConfirm = () => {
    return onConfirm ? onConfirm(value, defaultValue) : missingHandler();
  };

  return (
    <FormControl className={clsx(classes.root)}>
      {label && <InputLabel>{label}</InputLabel>}
      <Input
        {...props}
        onChange={onChange}
        defaultValue={defaultValue}
        ref={inputRef}
        endAdornment={
          !disabled && (
            <InputAdornment position="end">
              <IconButton
                aria-label="confirm changed value"
                size="small"
                onClick={myConfirm}
                disabled={!hasChanged()}
              >
                {hasChanged() ? <Check color="primary" /> : <Check color="disabled" />}
              </IconButton>
            </InputAdornment>
          )
        }
        disabled={disabled}
      ></Input>
    </FormControl>
  );
}
