import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';

export default function TimePicker({ saveTime, ...props }) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        onChange={saveTime}
        label="time picker"
        KeyboardButtonProps={{ 'aria-label': 'change time' }}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
}
