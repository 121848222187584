import moment from 'moment-timezone';
import { flatten as flattenKeys } from 'flat';
import _ from 'lodash';
import { trimDeep } from 'lib/form_helpers';

// Components
import { Grid } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Formik, Form, Field } from 'formik';
import FormikDateRangePicker from 'components/form/FormikDateRangePicker';
import FilterFormHOC from 'components/form/FilterFormHOC';
import Button from 'components/Button';

// Hooks
import { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';

export default function ProductSummaryFiltersFormik({ filterFn }) {
  const classes = useStyles();

  const handleSubmit = useCallback(
    (values) => {
      const { created_at, order_items, product } = values;

      const filterData = {
        ...flattenKeys({ order_items, product }),
      };

      const [dateFrom, dateTo] = created_at;

      if (dateTo) {
        filterData['order.created_at[$lte]'] = dateTo;
      }

      if (dateFrom) {
        filterData['order.created_at[$gte]'] = dateFrom;
      }

      return filterFn(trimDeep(filterData));
    },
    [filterFn]
  );

  return (
    <FilterFormHOC>
      <Formik
        initialValues={{
          created_at: [moment().startOf('month').toDate(), moment().endOf('day').toDate()],
          order_items: {
            name: '',
            sku: '',
          },
          product: {
            name: '',
          },
        }}
        onReset={handleSubmit}
        onSubmit={handleSubmit}
        validateOnChange={true}
      >
        {({ dirty, touched, handleReset, isSubmitting }) => {
          return (
            <Form>
              <Grid container item xs={12}>
                <Grid
                  container
                  item
                  xs={2}
                  direction="column"
                  alignContent="flex-start"
                  style={{ padding: '4px 4px 13px 4px' }}
                >
                  <FormikDateRangePicker fieldName="created_at" />
                </Grid>

                <Grid container item xs={9} direction="column" alignContent="flex-start">
                  <Grid container item xs>
                    <Grid item sm={4} xs={12}>
                      <Field component={TextField} label="product name" name="product.name" />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field component={TextField} label="variant name" name="order_items.name" />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field component={TextField} label="variant sku" name="order_items.sku" />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={1}
                  alignContent="flex-end"
                  alignItems="center"
                  classes={{ root: classes.actionsSection }}
                >
                  <Grid item xs>
                    <Button
                      type="submit"
                      className={classes.filterButton}
                      disabled={isSubmitting || !dirty}
                    >
                      apply
                    </Button>
                  </Grid>
                  <Grid item xs>
                    <Button
                      type="reset"
                      color="secondary"
                      className={classes.filterButton}
                      onClick={handleReset}
                    >
                      reset
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </FilterFormHOC>
  );
}

const useStyles = makeStyles({
  filterButton: {
    margin: '4px',
  },
  actionsSection: {
    paddingBottom: '17px',
  },
});
