import { createTheme } from '@material-ui/core/styles';
import theme from 'themes/main.theme';

const formFilterTheme = createTheme({
  spacing: 0,
  typography: {
    ...theme.typography,
    fontSize: 12,
  },

  props: {
    MuiTextField: {
      // changing this to dense mis-aligns the select boxes. Need to find out how to target those too.
      // margin: 'dense',
      fullWidth: true,
    },
  },

  overrides: {
    ...theme.overrides,

    MuiGrid: {
      item: {
        padding: '0 4px 0 0',
      },
    },

    MuiInputBase: {
      root: {
        fontSize: '12px',
        marginTop: '8px',
      },
    },

    MuiInputLabel: {
      root: {
        fontSize: '12px',
      },
    },
  },
});

export default formFilterTheme;
