import _ from 'lodash';
import { Alert } from '@material-ui/lab';

// Components
import { Field } from 'formik';
import { JsonEditor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import 'components/Editors/css/json-editor-dark-theme.css';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/monokai';

// NOTE: using our custom JsonEditor component results in an infinite loop
// during setting of the value. To work around this and still use as a formik field
// we use the native jsoneditor-react component and utilize the onChange and onValidationError handlers
export default function JsonEditorField({ id, name, ...props }) {
  return (
    <Field name={name} id={id}>
      {({ field, form: { setFieldValue, setFieldError }, meta: { error } }) => {
        let { value } = field;
        return (
          <>
            {!_.isEmpty(error) && <Alert severity="error">{JSON.stringify(error)}</Alert>}

            <JsonEditor
              htmlElementProps={{
                id: id || 'json-editor',
              }}
              value={value ?? {}}
              mode="code"
              ace={ace}
              theme="ace/theme/monokai"
              enableSort={false}
              enableTransform={false}
              onChange={(data) => {
                setFieldValue(field.name, data);
              }}
              onValidationError={(error) => {
                setFieldError(field.name, error);
              }}
              {...props}
            />
          </>
        );
      }}
    </Field>
  );
}
