import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import mainTheme from 'themes/main.theme';
import './index.css';
import { createBrowserHistory } from 'history';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const history = createBrowserHistory();

Sentry.init({
  dsn: 'https://28c462df329d4ea4a818f47fcc38b21f@o520915.ingest.sentry.io/6137889',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],

  // TODO@productionize: set this sample to something lower like 0.2 - 0.5
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const render = () => {
  const App = require('./App').default;

  /* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */
  ReactDOM.render(
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <App />
    </ThemeProvider>,
    document.getElementById('root')
  );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render);
}
