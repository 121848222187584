// libs
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import Order from 'lib/order';

// components
import { Divider, Grid, Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import Button from 'components/Button';
import { Edit, LocalShipping, AttachMoney, Comment } from '@material-ui/icons';
import CatalogItemSearch from 'components/search/CatalogItemSearch';
import ChargebackModal from 'components/modals/ChargebackModal';
import ConfirmModal from 'components/modals/ConfirmModal';
import ContactAddress from 'components/ContactAddress';
import ContactSelector from 'components/ContactSelector';
import CustomerDetail from 'components/CustomerDetail';
import CustomerSearch from 'components/search/CustomerSearch';
import DialogConfirm from 'components/DialogConfirm';
import DropDownButton from 'components/Buttons/DropDownButton';
import FulfillmentsDG2 from 'components/DataGrids/FulfillmentsDG2';
import FundModal from 'components/modals/FundModal';
import Header from 'components/Header';
import HeaderSubsection from 'components/HeaderSubsection';
import NoteModal from 'components/modals/NoteModal';
import NotesSection from 'components/NotesSection';
import OrderTotalSummary from 'components/OrderTotalSummary';
import PageSection from 'components/PageSection';
import PaymentTransactionsDG2 from 'components/DataGrids/PaymentTransactionsDG2';
import RefundOrderModal from 'components/modals/RefundOrderModal';
import SessionGrid from 'components/SessionGrid';
import CreateNewCustomerModal from 'components/modals/CreateNewCustomerModal';

// hooks
import { makeStyles } from '@material-ui/core/styles';
import { useApplication } from 'contexts/application.context';
import { useAuth } from 'hooks/useAuth';
import { useOrder } from 'contexts/order.context';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import OrderStatusBar from 'components/Toolbars/OrderStatusBar';

let markAsPaidIdempotentKey = `mark-as-paid-${uuid()}`;

export default function EditOrderPage() {
  const { client } = useAuth();
  const { state, dispatch } = useOrder();
  const { dispatch: app_dispatch } = useApplication();
  const classes = useStyles();
  const { store_id, orderId } = useParams();

  const [billingSameAsShipping, setBillingSameAsShipping] = useState(false);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(true);
  const [displayCancel, setDisplayCancel] = useState(false);
  const [noteFormState, setNoteFormState] = useState('none');
  const [captureKey, setCapturekey] = useState(`capture-${uuid()}`);
  const [showResendModal, setShowResendModal] = useState(false);
  const [shipstationConfirmOpen, setShipstationConfirmOpen] = useState(false);
  const [isNewCustomerModalOpen, setIsNewCustomerModalOpen] = useState(false);

  function showLoading(message) {
    app_dispatch({ type: 'LOADING-START', message });
  }

  function hideLoading() {
    app_dispatch({ type: 'LOADING-DONE' });
  }

  const selectBillingContact = (contact) => {
    dispatch({ type: 'SET-BILLING-CONTACT', contact });
  };
  const selectShippingContact = (contact) => {
    dispatch({ type: 'SET-SHIPPING-CONTACT', contact });
    if (billingSameAsShipping) {
      dispatch({ type: 'SET-BILLING-CONTACT', contact });
    }
  };

  // Simplified Complex States for UI
  const isCancelled = state.order.status === 'cancelled';
  const isNotCancelled = !isCancelled;
  const isFullyRefunded = state.order.status === 'sale' && +state.order.credit_balance_amount <= 0;
  const isOrderDirty = state.old_order && state.old_order !== state.order;
  const canEdit = state.ui_state.state === 'EDIT';
  const cannotEdit = !canEdit;
  const canCapture = ['awaiting-funding-details', 'awaiting-capture'].includes(state.funding_state);
  const isLocked = isCancelled || isFullyRefunded;
  const canMarkAsPaid = state.order.id && +state.order.outstanding_balance_amount > 0 && !isLocked;
  const canRefund =
    state.order?.id && state.order.credit_balance_amount > 0 && !isLocked && !state.order.has_chargeback;
  const isSale = state.order.status === 'sale';
  const isViewOnly = state.ui_state.state === 'VIEW';
  const canEditCustomer = canEdit && (!state.order.status || state.order.status === 'partial');
  const canClose =
    isNotCancelled && isViewOnly && !state.order.is_closed && state.order.status === 'sale';
  const canCancel = canRefund && state.order.ship_status === 'none' && !state.order.has_chargeback;

  useEffect(() => {
    (async () => {
      let { data } = await client.get(`/admin/orders/${orderId}`, {
        params: {
          $eager: `[
                agent,
                billing_contact,
                customer.[
                  contacts
                ],
                fulfillments.[
                  order,
                  order_items.[
                    variant
                  ],
                  shipping_contact
                ],
                notes.[
                  user
                ],
                order_items.[
                  variant,
                  fulfillment,
                  funding_transaction
                ],
                payment_transactions.[
                  chargeback
                ],
                session,
                shipping_contact
              ]`,
        },
      });
      dispatch({ type: 'SET-ORDER', order: data });
    })();

    return function cleanup() {
      dispatch({ type: 'CLOSE-CHARGEBACK-MODAL' });
      dispatch({ type: 'CC-CLEAR' });
      dispatch({ type: 'RESET-ORDER' });
    };
  }, [orderId, forceRefresh, state.refresh, client, dispatch]);

  // cleans up the order state when this component is unmounted
  useEffect(() => {
    return () => {
      dispatch({ type: 'RESET-ORDER-STATE' });
    };
  }, [state?.order?.id, dispatch]);

  async function cancelOrder() {
    let { id, store_id } = state.order;
    showLoading('canceling the order');
    let response;

    try {
      response = await client.post(`/orders/cancel`, {
        id,
        idempotent_key: `cancel-order-${idempotentKey}`,
        store_id,
      });
      let { data } = response;
      hideLoading();
      setDisplayCancel(false);
      setForceRefresh((val) => !val);
      return data;
    } catch (error) {
      setDisplayCancel(false);
      app_dispatch({ type: 'DISPLAY-ERROR', error, message: error.message });
    }
  }

  async function markAsPaid(orderId) {
    try {
      let captureRequest = {
        intent: 'capture',
        order_items: state.order.order_items.map((item) => {
          return _.pick(item, ['id', 'variant_id', 'quantity', 'price']);
        }),
        payment_group_id: 'overhub-internal',
        idempotent_key: markAsPaidIdempotentKey,
        currency_iso: state.order.currency_iso,
        card: {},
        store_id: store_id,
      };

      let { data } = await client.patch(`/orders/${orderId}`, captureRequest);
      return data;
    } catch (error) {
      console.error('mark as paid error', error);
    } finally {
      markAsPaidIdempotentKey = uuid();
    }
  }

  /**
   *
   * @param {object} order - order object
   * @param {string} intent - build | capture
   * @param {string} idempotent_key - unique key for this transaction to prevent duplicate requests
   */
  async function saveOrder(order, intent = 'build', idempotent_key) {
    let response;
    let orderData = Object.assign(order.export(intent), { idempotent_key });
    if (order.id) {
      response = client.patch(`/orders/${order.id}`, orderData);
    }

    let { data } = await response;
    dispatch({ type: 'ORDER-STATE-TRANSITION', state: 'VIEW' });

    return data;
  }

  /**
   * refreshOrder
   *
   * syntactic sugar to toggle the value of forceRefresh state which useEffect() uses
   * to determine if it should fetch the order and set the state of this component
   */
  const refreshOrder = () => {
    setForceRefresh(!forceRefresh);
  };

  // REVIEW: might want to move this closer to the place that it's actually needed
  // we shouldn't need to rely on order anymore for display, but we still currently use it
  // to export a req.body that is friendly to the api
  // check if card is empty, if not, add it in
  let missingOrderContext = {};
  if (!_.every(state.card, _.isEmpty)) {
    missingOrderContext.card = state.card;
  }
  const order = new Order({
    ...state.order,
    ...missingOrderContext,
    billing_same_as_shipping: billingSameAsShipping,
    payment_group_id: state.payment_group.slug,
  });
  const idempotentKey = uuid();

  let userId = order?.customer?.id;

  function handleBillingSameAsShipping(event) {
    let { checked } = event.target;

    setBillingSameAsShipping(checked);
  }

  async function handleSaveOrder(event) {
    event.preventDefault();

    showLoading('saving application');
    let idempotent_key = `admin-draft-${uuid()}`;
    await saveOrder(order, 'build', idempotent_key);
    setForceRefresh(!forceRefresh);
    hideLoading();
  }

  /**
   * This is the selection handler for the customer search input
   * @param {*} customer The selected customer
   */
  function handleCustomerChange(customer) {
    dispatch({ type: 'SET-CUSTOMER', customer });
  }

  /**
   * This is the selection handler for the catalog item search input
   * @param {*} catalogItem The selected catalog item
   */
  function handleAddCatalogItem(catalogItem) {
    let order_item = Order.catalogItemToOrderCartItem(catalogItem);
    dispatch({ type: 'ADD-ORDER-ITEM', order_item });
  }

  function handleRemoveOrderItem(orderItem) {
    return (event) => {
      event.preventDefault();

      dispatch({ type: 'REMOVE-ORDER-ITEM', order_item: orderItem });
    };
  }

  const activeOrderItems = state.order.order_items.filter((oi) => oi.action !== 'remove');

  let orderTitle = 'Order';
  if (!_.isEmpty(state.order.customer)) {
    orderTitle = `${state.order.customer.name}'s Order`;
  }

  const showOrderHeaderStatus =
    isFullyRefunded || _.includes(['cancelled', 'declined', 'partial'], state.order.status);

  // render
  return (
    <Grid container item xs={12}>
      <Header heading={orderTitle}>
        <OrderStatusBar />
      </Header>
      <Grid container item xs={12} spacing={2}>
        {showOrderHeaderStatus && (
          <Grid item xs={12}>
            <div className={classes.orderStatusHeader}>
              <div className="status">
                {state.order.status === 'declined' && 'Order Declined'}
                {isFullyRefunded && `Fully Refunded`}
                {state.order.status === 'cancelled' && `Order Cancelled`}
                {state.order.status === 'partial' && `Draft Order`}
              </div>
            </div>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} alignItems="flex-start" spacing={2}>
        {/* Main Column */}
        <Grid container item xs={8} classes={{ root: 'main-column' }}>
          {canEdit && (
            <PageSection>
              <CatalogItemSearch
                currencyCodeISO={state.order.currency_iso}
                storeId={store_id}
                onSelect={handleAddCatalogItem}
                timeoutDuration={600}
              />
            </PageSection>
          )}
          <Grid item container xs={12}>
            <OrderTotalSummary
              order={state.order}
              activeOrderItems={activeOrderItems}
              handleRemoveOrderItem={handleRemoveOrderItem}
            />
            <FundModal
              open={canCapture}
              state={state}
              dispatch={dispatch}
              id="fund-form"
              saveOrder={saveOrder}
              refreshOrder={refreshOrder}
              order={order}
              captureKey={captureKey}
              setCapturekey={setCapturekey}
            />

            <Grid item xs={12} className={classes.saveOrderButtonContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.order.is_test}
                    onClick={(event) => {
                      let { checked } = event.target;
                      dispatch({ type: 'SET-ORDER', order: { ...state.order, is_test: checked } });
                    }}
                  />
                }
                label="Test?"
                disabled={isSale || cannotEdit}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.order.tax_is_included}
                    onClick={(event) => {
                      let { checked } = event.target;
                      dispatch({
                        type: 'SET-ORDER',
                        order: { ...state.order, tax_is_included: checked },
                      });
                    }}
                  />
                }
                label="Tax included"
                disabled={isSale || cannotEdit}
              />
              {canEdit && (
                <>
                  <Button
                    color="secondary"
                    onClick={() => {
                      dispatch({
                        type: 'ORDER-STATE-TRANSITION',
                        state: 'VIEW',
                        test: true,
                        order: state.old_order,
                      });
                    }}
                  >
                    Cancel Changes
                  </Button>

                  <Button
                    onClick={handleSaveOrder}
                    className={classes.saveOrderButton}
                    disabled={!isOrderDirty}
                  >
                    Save Order
                  </Button>
                </>
              )}
              {isViewOnly && (
                <DropDownButton
                  onClick={() => {
                    dispatch({ type: 'ORDER-STATE-TRANSITION', state: 'EDIT' });
                  }}
                  disabled={isLocked}
                  menuItems={[
                    {
                      text: 'Attach/Detach Payment Transactions',
                      onClick: () => {
                        dispatch({
                          type: 'ORDER-STATE-TRANSITION',
                          state: 'EDIT-PAYMENT-TRANSACTION-ATTACHMENT',
                        });
                      },
                      id: 1,
                    },
                  ]}
                >
                  Edit
                  <Edit />
                </DropDownButton>
              )}
              {state.ui_state.state === 'EDIT-PAYMENT-TRANSACTION-ATTACHMENT' && (
                <Button
                  color="secondary"
                  onClick={() => {
                    dispatch({
                      type: 'ORDER-STATE-TRANSITION',
                      state: 'VIEW',
                      test: true,
                      order: state.old_order,
                    });
                  }}
                >
                  Cancel Toggle Funding Attachment
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <PageSection header="Transactions">
              <Grid item xs={12}>
                <ChargebackModal
                  chargebackTransaction={state.chargebackTransaction}
                  dispatch={dispatch}
                  refresh={() => {
                    setForceRefresh(!forceRefresh);
                  }}
                />
              </Grid>
              <PageSection noPaper={true}>
                <Grid item xs={12}>
                  <PaymentTransactionsDG2
                    selectedTransactions={selectedTransactions}
                    rows={state.order.payment_transactions}
                    dispatch={dispatch}
                    setSelectedTransactions={setSelectedTransactions}
                    autoFit={true}
                    allowPaging={false}
                    allowGrouping={false}
                  />
                </Grid>
              </PageSection>
            </PageSection>
          </Grid>
          {/* // TODO: implement timeline */}
          {/* <Grid item xs={12}>
            <Paper classes={{ root: classes.orderSections }}>
              <Typography variant="h6">Timeline</Typography>
            </Paper>
          </Grid> */}
          <PageSection header="Fulfillments">
            <FulfillmentsDG2
              rows={state.order.fulfillments.map((f) => ({
                ...f,
                customer: state.order.customer,
                order_slug: state.order.slug,
              }))}
              hideColumns={['Order Id', 'Name']}
              autoFit={true}
              allowPaging={false}
            />
          </PageSection>

          <NotesSection notes={order.notes} />
        </Grid>

        {/* Right column */}
        <Grid item xs={4} classes={{ root: classes.rightColumnContainer }}>
          <PageSection>
            <>
              {canEditCustomer && (
                <>
                  <Typography variant="h6">Select Customer</Typography>
                  <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={8}>
                      <CustomerSearch onSelect={handleCustomerChange} timeoutDuration={600} />
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        onClick={() => {
                          setIsNewCustomerModalOpen(true);
                        }}
                        classes={{ root: classes.addNewUserButton }}
                      >
                        Add New
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
              <CustomerDetail customer={state.order.customer} />
              {canEdit && !_.isEmpty(state.order.customer) ? (
                <>
                  <Divider />
                  <Typography variant="h6">Select Shipping</Typography>
                  <ContactSelector
                    handleSelect={selectShippingContact}
                    id="contact-selector"
                    value={state.order.shipping_contact ?? {}}
                    contacts={state.order.customer.contacts}
                    userId={userId}
                    dispatch={dispatch}
                    type="shipping"
                    modal_display={state.display_shipping_modal}
                  />
                </>
              ) : (
                <div className={classes.contact}>
                  <HeaderSubsection>Shipping Contact</HeaderSubsection>
                  <ContactAddress contact={state.order.shipping_contact ?? {}} />
                </div>
              )}
              {canEdit && state.order.shipping_contact && (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleBillingSameAsShipping}
                      name="billing-same-as-shipping"
                      value={billingSameAsShipping}
                    />
                  }
                  label="Billing same as shipping?"
                />
              )}

              {canEdit && !_.isEmpty(state.order.customer) && !billingSameAsShipping ? (
                <>
                  <hr />
                  <Typography variant="h6">Select Billing</Typography>
                  <ContactSelector
                    handleSelect={selectBillingContact}
                    id="contact-selector"
                    value={state.order.billing_contact ?? {}}
                    userId={userId}
                    contacts={state.order.customer.contacts}
                    dispatch={dispatch}
                    type="billing"
                    modal_display={state.display_billing_modal}
                  />
                </>
              ) : (
                !billingSameAsShipping && (
                  <div className={classes.contact}>
                    <HeaderSubsection>Billing Contact</HeaderSubsection>
                    <ContactAddress contact={state.order.billing_contact ?? {}} />
                  </div>
                )
              )}
            </>
          </PageSection>

          <PageSection header="Actions">
            <Grid container>
              <Grid item xs={12} classes={{ root: classes.sidebarActions }}>
                <Button
                  onClick={async function closeOrderHandler() {
                    await client.post('/orders/close', { order_id: state.order.id });
                    refreshOrder();
                  }}
                  disabled={!canClose}
                >
                  Close Order
                </Button>
                <Button
                  onClick={async () => {
                    client
                      .get('/admin/payment_groups', { params: { store_id: store_id } })
                      .then(({ data }) => {
                        dispatch({ type: 'SET-PAYMENT-GROUPS', payment_groups: data });
                      });
                    dispatch({
                      type: 'SET-FUNDING-STATE',
                      funding_state: 'awaiting-funding-details',
                    });
                  }}
                  className={classes.saveOrderButton}
                  // TODO: clean up these states. we can start to compartmentalize soon
                  disabled={
                    [
                      'awaiting-payment',
                      'awaiting-funding-details',
                      'capture-in-flight',
                      'capture-in-flight',
                    ].includes(state.funding_state) || !canMarkAsPaid
                  }
                >
                  <AttachMoney classes={{ root: classes.buttonIcon }} />
                  Fund Order
                </Button>

                <Button
                  onClick={(event) => {
                    event.preventDefault();
                    showLoading('Marking order as paid');
                    markAsPaid(order.id).then((data) => {
                      hideLoading();
                      refreshOrder();
                    });
                  }}
                  disabled={!canMarkAsPaid}
                >
                  Mark as paid
                </Button>
                <Button
                  onClick={() => {
                    dispatch({ type: 'OPEN-REFUND-MODAL' });
                  }}
                  disabled={!canRefund}
                  className="refund-button"
                >
                  Refund
                </Button>

                <RefundOrderModal
                  handleRefundSuccess={(refundResult) => {
                    dispatch({ type: 'CLOSE-REFUND-MODAL' });
                    setForceRefresh(!forceRefresh);
                  }}
                  order={order}
                  formProps={{
                    default_strategy: state.default_strategy,
                    initialValues: state.initialValues,
                  }}
                  open={state.display_refund_modal}
                  handleClose={() => {
                    dispatch({ type: 'CLOSE-REFUND-MODAL' });
                  }}
                />
                <Button
                  onClick={() => {
                    setDisplayCancel(true);
                  }}
                  disabled={!canCancel}
                >
                  Cancel
                </Button>
                <DialogConfirm
                  confirmFn={async () => {
                    return await cancelOrder();
                  }}
                  question="You're about to cancel this order. Are you sure?"
                  open={displayCancel}
                  cancelFn={() => {
                    setDisplayCancel(false);
                  }}
                  confirmText="Yes, Cancel the order"
                  cancelText="Don't Cancel the order"
                  inFlightText="Cancelling the order"
                />

                <Button
                  onClick={() => {
                    setNoteFormState('open');
                  }}
                >
                  <Comment classes={{ root: classes.buttonIcon }} />
                  Add Note
                </Button>

                <Button
                  onClick={() => {
                    setShipstationConfirmOpen(true);
                  }}
                >
                  <LocalShipping classes={{ root: classes.buttonIcon }} />
                  Sync Shipstation
                </Button>
                <DialogConfirm
                  open={shipstationConfirmOpen}
                  confirmFn={async () => {
                    await client.post(`queues/shipstation_sync_order`, {
                      order_id: state.order.id,
                      idempotent_key: uuid(),
                    });
                    setShipstationConfirmOpen(false);
                  }}
                  cancelFn={() => {
                    setShipstationConfirmOpen(false);
                  }}
                  question="Sync this order to Shipstation?"
                  confirmText="Sync Now"
                />

                <NoteModal
                  open={noteFormState === 'open'}
                  cancelCb={() => {
                    setNoteFormState('none');
                  }}
                  saveCb={() => {
                    setNoteFormState('none');
                    setForceRefresh(!forceRefresh);
                  }}
                  order_id={order.id}
                />

                <Button
                  onClick={() => {
                    // trigger confirm modal
                    setShowResendModal(true);
                  }}
                >
                  Resend Confirmation Email
                </Button>
                <ConfirmModal
                  open={showResendModal}
                  handleConfirm={async () => {
                    // add to queue here
                    try {
                      await client.post(`/queues/emails/send_confirmation_email`, {
                        order_id: state.order.id,
                      });
                    } catch (error) {
                      // REVIEW@error: may want to handle errors differently here
                    } finally {
                      setShowResendModal(false);
                    }
                  }}
                  handleClose={() => {
                    setShowResendModal(false);
                  }}
                  dispatch={() => {}}
                  title="Resend customer order confirmation email?"
                  maxWidth="xs"
                />
              </Grid>
            </Grid>
          </PageSection>

          {state.order.session && (
            <PageSection header="Session">
              <SessionGrid
                agent={order.agent}
                session={state.order.session}
                dispatch={dispatch}
              ></SessionGrid>
            </PageSection>
          )}
        </Grid>
      </Grid>
      <CreateNewCustomerModal
        handleSave={(customer) => {
          dispatch({ type: 'SET-CUSTOMER', customer });
          setIsNewCustomerModalOpen(false);
        }}
        handleClose={() => {
          setIsNewCustomerModalOpen(false);
        }}
        open={isNewCustomerModalOpen}
      />
    </Grid>
  );
}

// Styles
const useStyles = makeStyles((theme) => ({
  // NOTE: The markup that uses this class is currently commented out
  orderSections: {
    margin: '0 2px 5px 0',
    padding: '1.5rem',
  },

  saveOrderButtonContainer: {
    textAlign: 'right',
    padding: '7px',
    '& > button': {
      marginLeft: '6px',
    },
  },

  sidebarActions: {
    '& > button': {
      display: 'block',
      marginBottom: '4px',
      width: '100%',
    },
  },

  buttonIcon: {
    position: 'relative',
    top: '4px',
    height: '18px',
    margin: '0 4px',
  },

  button: {
    marginRight: '5px',
  },

  contact: {
    marginBottom: '1rem',

    '& > div:nth-of-type(1)': {
      fontWeight: 'bold',
    },
  },

  addNewUserButton: {
    marginLeft: '8px',
  },

  orderStatusHeader: {
    backgroundColor: theme.palette.subHeader.background.primary,
    padding: '.5rem',
    fontWeight: 'bold',
    borderRadius: '3px',
    textAlign: 'center',
  },

  rightColumnContainer: {
    paddingTop: '35px !important',
  },
}));
