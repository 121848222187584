// libs
import _ from 'lodash';

//components
import FormikSimpleSelect from './form/FormikSimpleSelect';

// hooks
import { useState, useEffect } from 'react';
import { useAuth } from 'hooks/useAuth';
import { useHistory } from 'react-router-dom';

export default function StateSelector({ onChange, countryIso2 = 'US', defaultValue, ...props }) {
  const history = useHistory();
  const { client } = useAuth();

  const [state, setState] = useState({
    geoStates: [],
    selectedState: { code_iso3: '', name: '' },
    isLoaded: false,
  });

  if (!state.isLoaded) {
    setState({ ...state, isLoaded: true });
  }

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      let { data } = await client.get(`/admin/geo_countries`, {
        params: {
          $eager: '[states(forSelect)]',
          $select: ['geo_countries.code_iso2', 'geo_countries.name'],
          'geo_countries.code_iso2': countryIso2,
        },
        signal: controller.signal,
      });
      data = _.head(data);
      const selectedState = selectDefault(data.states, defaultValue);
      setState((s) => ({ ...s, geoStates: data.states, selectedState }));
    })();

    return () => {
      controller.abort();
    };
  }, [state.isLoaded, countryIso2, client, defaultValue, history]);

  function selectDefault(geoCountries, code_iso3) {
    return geoCountries.find((i) => i.code_iso3 === code_iso3) || { code_iso3: '', name: '' };
  }

  return (
    <FormikSimpleSelect
      {...props}
      items={state.geoStates.map((state) => ({
        value: state.code_iso3,
        label: `${state.code_iso3}  ${state.name}`,
      }))}
    />
  );
}
