import * as yup from 'yup';
import S from 'lib/stringomatic';

// Components
import { Button, FormControl, Grid, MenuItem } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { Select } from 'formik-material-ui';
import Header from 'components/Header';
import OTextField from 'components/form/OTextField';
import PageSection from 'components/PageSection';
import { OFormikCheckBox } from 'components/form/OCheckBox';
import PageLoading from 'components/Loading/PageLoading';

// Hooks
import { useEffect, useState } from 'react';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

// exists to format the input name to a friendlier string
const requiredMessage = ({ path }) => {
  return `${S.humanize(path)} is Required`;
};

const formSchema = yup.object().shape({
  name: yup.string().required(requiredMessage),
  type: yup.string().required(requiredMessage),
  // api_key: yup.string().required(requiredMessage),
  configuration: yup.object().shape({
    templates: yup.object().shape({
      refund_template_id: yup.string().required(requiredMessage).default(''),
      confirm_template_id: yup.string().required(requiredMessage).default(''),
      fulfillment_template_id: yup.string().required(requiredMessage).default(''),
      cancel_template_id: yup.string().required(requiredMessage).default(''),
      void_template_id: yup.string().required(requiredMessage).default(''),
    }),
  }),
});

export default function EmailProvidersNewPage() {
  const { client } = useAuth();
  const { store_id, email_provider_id } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch(`/store/:store_id`);

  const [emailProvider, setEmailProvider] = useState(null);

  useEffect(() => {
    (async () => {
      let { data } = await client.get(`/admin/email_providers/${email_provider_id}`);
      setEmailProvider(data);
    })();
  }, [email_provider_id, client]);

  if (!emailProvider) {
    return <PageLoading />;
  }

  return (
    <Grid container>
      <Header heading="Edit Email Provider" />
      <PageSection>
        <Grid item container>
          <Formik
            initialValues={formSchema.cast(emailProvider)}
            onSubmit={async (values) => {
              let { id, name, is_default, type, configuration } = values;
              let emailData = {
                store_id,
                name,
                is_default,
                type,
                configuration,
              };
              await client.patch(`/admin/email_providers/${id}`, emailData);
              history.push(`${url}/email_providers`);
            }}
            validationSchema={formSchema}
          >
            {({ isSubmitting }) => {
              return (
                <Form>
                  <Grid container>
                    <Grid item xs={12}>
                      <OTextField name="name" label="Name" />
                    </Grid>
                    <Grid item xs={12}>
                      <OFormikCheckBox name="is_default" id="is_default" label="Default?" />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl>
                        <Field component={Select} id="type" name="type" label="Type">
                          <MenuItem value="sib">Send in Blue</MenuItem>
                        </Field>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <h3>SIB Authentication</h3>
                    <div className="hint"> You cannot change your api key after it is created. </div>
                  </Grid>

                  <Grid container item xs={12}>
                    <h3>SIB Template ID's</h3>
                    {['refund', 'confirm', 'fulfillment', 'cancel', 'void'].map((type) => {
                      return (
                        <Grid item xs={12} key={`${type}-template`}>
                          <OTextField
                            name={`configuration.templates.${type}_template_id`}
                            label={`${type} template id`}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                    Save
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </PageSection>
    </Grid>
  );
}
