import React from 'react';
import classnames from 'classnames';

// components
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default function PageSection({ children, header = '', noPaper = false, ...props }) {
  const classes = useStyles();

  return (
    // NOTE: In-progress UI
    // <Grid container classes={{ root: classnames(classes.container, classes.paperContainer) }} {...props}>
    //   {/* <Grid item xs={12}></Grid> */}
    //   <Grid item xs={12}>
    //     {!noPaper ? (
    //       <Paper classes={{ root: classes.paperRoot }}>
    //         {header && (
    //           <div className="page-section-header">
    //             <Typography variant="h6">{header}</Typography>
    //           </div>
    //         )}
    //         {children}
    //       </Paper>
    //     ) : (
    //       <>
    //         {header && (
    //           <div className="page-section-header">
    //             <Typography variant="h6">{header}</Typography>
    //           </div>
    //         )}
    //         {children}
    //       </>
    //     )}
    //   </Grid>
    // </Grid>

    // OLD: leaving in case we find weirdness
    <Grid container classes={{ root: classnames(classes.container, classes.paperContainer) }} {...props}>
      <Grid item xs={12}>
        {header && <Typography variant="h6">{header}</Typography>}
      </Grid>
      <Grid item xs={12}>
        {!noPaper ? <Paper classes={{ root: classes.paperRoot }}>{children}</Paper> : children}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({
  container: {
    marginBottom: '5px',
    padding: '0 4px',
  },

  paperRoot: {
    padding: '5px',
  },

  paperContainer: {
    flexGrow: 1,
  },

  orderSections: {},
});
