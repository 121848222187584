import React from 'react';
// import { useParams } from 'react-router-dom';
// import { useAuth } from 'hooks/useAuth';

export default function PaymentTransactionShowPage() {
  // const [transaction, setTransaction] = useState({});
  // const auth = useAuth();
  // const { client } = auth;

  // useEffect(() => {
  //   (async () => {
  //     let paymentTransaction = await client.get(`/payment_transactions`);
  //   })();
  // });

  return (
    <div>
      payment transaction placeholder
      {/* <div>{JSON.stringify(transaction)}</div> */}
    </div>
  );
}
