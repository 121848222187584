import _ from 'lodash';

// COMPONENTS
import { FormControl, InputLabel, MenuItem, Select, Tooltip, Typography } from '@material-ui/core';
import { Cancel, CheckCircle, Help } from '@material-ui/icons';

// selector support
import { createMachine, assign } from 'xstate';

// Hooks
import { useEffect, Fragment } from 'react';
import { useMachine } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

export default function StoreSelector({ items }) {
  const { store, setStore } = useAuth();

  // determine an initial state for when we have an explicit state that is [usually] built from the url
  const initial_state = store ? 'selected' : 'not_loaded';

  const storeSelectorMachine = createMachine({
    id: 'store_selector',
    initial: initial_state,
    states: {
      not_loaded: {
        on: {
          LOADED: 'waiting_for_selection',
          SELECTED: 'selected',
          INIT: 'selected',
        },
      },
      waiting_for_selection: {
        on: {
          SELECTED: {
            target: 'selected',
            actions: ['updateCurrent', 'redirectToStoreUrl'],
          },
          INIT: 'selected',
        },
      },
      selected: {
        id: 'root_selected',
        on: {
          EDIT: 'edit',
        },
      },

      edit: {
        on: {
          SELECTED: { target: 'edit.waiting_for_confirm', actions: ['handleSelect'] },
          CANCEL: { target: '#root_selected', actions: ['resetStore'] },
        },

        states: {
          waiting_for_confirm: {
            on: {
              CONFIRM: { target: '#root_selected', actions: ['updateCurrent', 'redirectToStoreUrl'] },
            },
          },
        },
      },
    },
  });

  const history = useHistory();
  const classes = useStyles();

  const dynamicMachine = store ? storeSelectorMachine.withContext({ store }) : storeSelectorMachine;
  const [current, send] = useMachine(dynamicMachine, {
    services: {},

    actions: {
      // save the selected store for reference during a cancel
      updateCurrent: assign((context, { data: store }) => {
        // NOTE: there is probably a better place to do this action. Not 100% what it is yet.
        history.push(`/store/${store.id}`);
        setStore(store);
        return { store };
      }),

      redirectToStoreUrl: (context, event) => {
        // NOTE: there is probably a better place to do this action. Not 100% what it is yet.
        history.push(`/store/${context.store.id}`);
      },

      // use the last known selected store state
      resetStore: assign((context, event) => {
        setStore(event.data);
        return { store: event.data };
      }),

      handleSelect: assign((context, event) => {
        return { store: event.data };
      }),
    },
  });

  // load the context and prepare for
  useEffect(() => {
    send('LOADED', items);
  }, [items, send]);

  useEffect(() => {
    if (store) {
      // explicitly send an INIT event to put the storeselector into the selected state without a redirect
      // not totally satisfied with this solution, happy to see a "better", more idiomatic solution
      send({ type: 'INIT', data: store });
    }
  }, [store, send]);

  return (
    <>
      {current.matches('selected') && (
        <Tooltip title="click to change store" aria-label="click to change store">
          <Typography
            variant="h5"
            classes={{ root: `selected_store_label`, h5: classes.storeTitle }}
            onClick={() => send('EDIT')}
          >
            {store.name}

            <Help classes={{ root: classes.helpIcon }} />
          </Typography>
          {/* <h4
            className="selected_store_label"
            onClick={() => {
              send('EDIT');
            }}
            style={{ cursor: 'pointer' }}
          >
            {store.name}
            <Help classes={{ root: classes.helpIcon }} />
          </h4> */}
        </Tooltip>
      )}

      {/* previously selected, but now being edited */}
      {current.matches('edit') && (
        <Fragment>
          {!_.isEmpty(items) && (
            <FormControl classes={{ root: classes.select }}>
              <InputLabel id="store-selector" name="store_selector">
                Select Store
              </InputLabel>
              <Select
                onChange={(event) => {
                  let store = event.target.value;
                  send({ type: 'SELECTED', data: store });
                }}
                value={current?.context?.store ?? store ?? ''}
              >
                {items.map((store) => (
                  <MenuItem value={store} key={store.id}>
                    {store.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <div style={{ marginTop: '3px' }}>
            <Tooltip title="cancel store change" aria-label="cancel store change">
              <span
                onClick={() => {
                  send({ type: 'CANCEL', data: store });
                }}
                style={{ marginRight: '5px', color: '#f06565', cursor: 'pointer' }}
              >
                <Cancel />
              </span>
            </Tooltip>
            {current.matches('edit.waiting_for_confirm') && (
              <Tooltip title="confirm store change" aria-label="confirm store change">
                <span
                  onClick={() => {
                    send({ type: 'CONFIRM', data: current.context.store });
                  }}
                  style={{ color: '#53b068', cursor: 'pointer' }}
                >
                  <CheckCircle />
                </span>
              </Tooltip>
            )}
          </div>
        </Fragment>
      )}

      {/*  first time being selected */}
      {current.matches('waiting_for_selection') && (
        <Fragment>
          {!_.isEmpty(items) && (
            <FormControl classes={{ root: classes.select }}>
              <InputLabel id="store-selector" name="store_selector">
                Select Store
              </InputLabel>
              <Select
                onChange={(event) => {
                  let store = event.target.value;
                  send({ type: 'SELECTED', data: store });
                }}
                value={store ?? ''}
              >
                {items.map((store) => (
                  <MenuItem value={store} key={store.id}>
                    {store.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Fragment>
      )}
    </>
  );
}

const useStyles = makeStyles({
  helpIcon: {
    fontSize: '1.7rem',
    marginLeft: '3px',
    color: '#8db0f7',
  },

  select: {
    width: '150px',
  },

  storeTitle: {
    fontSize: '1.4rem',
    display: 'flex',
  },
});
