import PropTypes from 'prop-types';

// Components
import GeneralModal from 'components/modals/GeneralModal';
import JsonEditor from 'components/Editors/JsonEditor';
import { Grid } from '@material-ui/core';
import Button from 'components/Button';

// Hooks
import { useCallback, useEffect, useState } from 'react';

/**
 * A modal with an embedded JSON editor for editing the access conditions of
 * resource permissions.
 * @param {object} params
 * @param {string} params.id The resource id
 * @param {function} [params.onApply] The function to call when the user clicks the apply button
 * @param {object} [params.readConditions] The dictionary of read access conditions associated with the role
 * @returns {JSX.Element} A modal with an embedded JSON editor
 */
export default function EditAccessConditionsModal({ id, onApply = () => {}, readConditions, ...props }) {
  const [conditionsData, setConditionsData] = useState(readConditions[id]);
  const [isValid, setIsValid] = useState(true);

  const handleValidate = useCallback((validation) => {
    if (validation.isValid) {
      setIsValid(true);
      setConditionsData(validation.data);
    } else {
      setIsValid(false);
    }
  }, []);

  const handleApply = useCallback(() => {
    onApply({ id, conditions: { ...conditionsData } });
  }, [id, conditionsData, onApply]);

  useEffect(() => {
    setConditionsData(readConditions[id]);
    return () => {
      setConditionsData({});
    };
  }, [props.open, readConditions, id]);

  return (
    <GeneralModal id="edit-access-conditions-modal" {...props}>
      <JsonEditor id="access-conditions-editor" value={conditionsData} onValidate={handleValidate} />
      <Grid container direction="column" alignItems="flex-end">
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid}
            onClick={handleApply}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </GeneralModal>
  );
}

EditAccessConditionsModal.propTypes = {
  id: PropTypes.string.isRequired,
  onApply: PropTypes.func,
  open: PropTypes.bool.isRequired,
  readConditions: PropTypes.object.isRequired,
};
