import { useState, useEffect } from 'react';
import moment from 'moment';
import { getQueryParamsFromDataState } from 'lib/datagrid_helpers';
import { getQueryParamsFromFilterFormData } from 'lib/form_helpers';
import _ from 'lodash';

// components
import { Grid } from '@material-ui/core';
import Header from '../../components/Header';
import OrdersDG3 from 'components/DataGrids/OrdersDG3';
import OrderFilterFormik from 'components/form/OrderFiltersFormik';
import BarChart from '../../components/BarChart';
import { useParams } from 'react-router-dom';
import PageSection from 'components/PageSection';

// hooks
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from 'hooks/useAuth';

export default function OrderDetailReportPage() {
  const auth = useAuth();
  const { client } = auth;
  const classes = useStyles();
  const { store_id } = useParams();

  const [chartData, setChartData] = useState(0);
  const [orders, setOrders] = useState([]);
  const [orderFilters, setOrderFilters] = useState({
    'orders.created_at[$gte]': moment().startOf('month').toDate(),
    'orders.created_at[$lte]': moment().endOf('month').toDate(),
  });
  const [tableFilters, setTableFilters] = useState({
    $skip: 0,
    $limit: 10,
    '$sort[created_at]': -1,
  });

  useEffect(() => {
    (async () => {
      let params = {
        $joinEager: '[customer,session,shipping_contact]',
        store_id,
        ...orderFilters,
        ...tableFilters,
      };

      let { data } = await client.get('/admin/orders', {
        params,
      });

      setOrders(data);
      // FIXME@reports: This is just temporary until I build out all the
      // correct charting endpoints.
      const chartData = _.countBy(data.data, 'status');

      let formattedChartData = [
        {
          y: _.values(chartData),
          x: _.keys(chartData),
        },
      ];

      setChartData(formattedChartData);
    })();
  }, [orderFilters, tableFilters, store_id, client]);

  async function handleFilterQuery(filters) {
    setOrderFilters(getQueryParamsFromFilterFormData(filters));
  }

  async function handleDataState(dataState) {
    setTableFilters(getQueryParamsFromDataState(dataState));
  }

  return (
    <Grid item container xs={12} className={classes.pageRoot} alignContent="flex-start">
      <Header heading="Order Details" />
      <PageSection header="Filters">
        <OrderFilterFormik filterFn={handleFilterQuery} />
      </PageSection>

      <PageSection header="Charts" className={classes.chartSection}>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <BarChart title="Order Details" id="leads-sales-declines-chart" data={chartData} />
          </Grid>
        </Grid>
      </PageSection>

      <PageSection header="Orders" className={classes.ordersSection}>
        <OrdersDG3 rows={orders} handleDataState={handleDataState} />
      </PageSection>
    </Grid>
  );
}

const useStyles = makeStyles({
  pageRoot: {
    height: '95vh',
    overflow: 'auto',
  },
  ordersSection: {
    flexGrow: 1,
    minHeight: 0,
    overflow: 'auto',
    width: '80vh',
    padding: '4px',
  },
  chartSection: {
    flexGrow: 1,
    minHeight: 300,
    overflow: 'auto',
    // NOTE@charts: This fixes the issue with Plotly not scaling correctly to fill a flex container
    '& .js-plotly-plot, .plot-container': {
      height: '30vh',
      minHeight: 200,
    },
    padding: '4px',
  },
});
