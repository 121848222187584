import _ from 'lodash';

export default function inputChange(object, setter, property) {
  return function (event) {
    let { value } = event.target;
    let newObjectState = { ...object, [property]: value };
    if (_.isEqual(newObjectState, object)) {
      return;
    }
    setter(newObjectState);
  };
}
