import React from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

/**
 * GeneralModal
 * a general modal to compose or use directly
 *
 * @param {object} param0
 * @returns
 */
export default function GeneralModal({
  open,
  dispatch,
  title,
  children,
  content_text,
  handleClose,
  id = 'GENERAL',
  contentProps = {},
  ...props
}) {
  const classes = useStyles();

  // NOTE: we check if handleClose was passed in, otherwise we fallback to the legacy built-in function
  const closeDialog =
    handleClose ??
    function () {
      dispatch({ type: `CLOSE-${id}-MODAL` });
    };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="lg" {...props} onClose={closeDialog}>
      <DialogTitle className={classes.titleBar}>
        {title}
        <Cancel onClick={closeDialog} className={classes.closeButton} />
      </DialogTitle>

      <DialogContent {...contentProps}>
        {content_text && <DialogContentText>{content_text}</DialogContentText>}
        <Grid container>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles({
  closeButton: {
    position: 'absolute',
    top: '2px',
    right: '2px',
    cursor: 'pointer',
  },
  titleBar: {
    position: 'relative',
  },
});
