import React, { useState, useEffect } from 'react';
import _ from 'lodash';

// Components
import { Grid } from '@material-ui/core';
import Header from 'components/Header';
import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import { Link } from 'react-router-dom';
import ConfirmModal from 'components/modals/ConfirmModal';
import AppBarButton from 'components/AppBarButton';

// Hooks
import { useAuth } from 'hooks/useAuth';
import { useHistory, useRouteMatch } from 'react-router';

function ActionsCell({ className = 'actions-cell', id, handleDelete, ...props }) {
  const { url } = useRouteMatch();

  return (
    <div className={className}>
      <Link to={`${url}/${id}`}>Edit</Link>
      <span className="link" onClick={handleDelete}>
        Delete
      </span>
    </div>
  );
}

export default function UsersIndexPage() {
  const { client } = useAuth();
  const history = useHistory();
  const { url } = useRouteMatch();

  const [users, setUsers] = useState([]);
  const [activeDelete, setActiveDelete] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);

  const refresh = () => setForceRefresh(!forceRefresh);

  useEffect(() => {
    (async () => {
      let { data } = await client.get(`/admin/users`, {
        params: { 'users.type[$in]': ['admin', 'user', 'application'], '$sort[users.name]': 1 },
      });
      setUsers(data);
    })();
  }, [forceRefresh, client]);

  return (
    <Grid container item xs={12}>
      <Header heading="Users">
        <AppBarButton
          variant="contained"
          color="primary"
          onClick={() => {
            history.push(`${url}/new`);
          }}
        >
          Add User
        </AppBarButton>
      </Header>
      <Grid container item xs={12}>
        <GridComponent dataSource={users}>
          <ColumnsDirective>
            <ColumnDirective field="name" headerText="Name" />
            <ColumnDirective field="email" headerText="Email" />
            <ColumnDirective field="type" headerText="Type" />
            <ColumnDirective
              headerText="Actions"
              template={(props) => (
                <ActionsCell
                  handleDelete={(event) => {
                    event.preventDefault();

                    setActiveDelete(props);
                  }}
                  {...props}
                />
              )}
            />
          </ColumnsDirective>
        </GridComponent>
      </Grid>
      <ConfirmModal
        open={Boolean(activeDelete)}
        handleConfirm={async () => {
          await client.delete(`/admin/users/${activeDelete?.id}`);
          refresh();
          setActiveDelete(null);
        }}
        handleClose={() => {
          setActiveDelete(null);
        }}
        dispatch={_.noop}
        title={activeDelete && `Delete User, "${activeDelete?.name}"?`}
        maxWidth="xs"
      />
    </Grid>
  );
}
