// uncomment for configuration css, dark theme
// import 'react-json-pretty/themes/monikai.css';

import qs from 'qs';
import _ from 'lodash';

import { Grid } from '@material-ui/core';
// TODO@datagrid: refactor to use kendo
import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import Button from 'components/Button';
import Header from 'components/Header';
import JSONPretty from 'react-json-pretty';
import Loader from 'components/Loader';
import PageSection from 'components/PageSection';
import PaymentGroupsForm from 'components/PaymentGroupsForm';
import SavePaymentConfigurationModal from 'components/modals/SavePaymentConfigurationModal';

// hooks
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

function PrettyConfigCell({ data, ...rest }) {
  return <JSONPretty data={data} />;
}

export default function PaymentGroupsEditPage(props) {
  let { id, store_id } = useParams();
  const { client } = useAuth();

  // states
  const [forceRefresh, setForceRefresh] = useState(false);
  const [paymentGroup, setPaymentGroup] = useState({
    name: '',
    slug: '',
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const [availableProcessors, setAvailableProcessors] = useState([]);
  const [activePaymentConfiguration, setActivePaymentConfiguration] = useState(null);

  const refresh = () => setForceRefresh(!forceRefresh);

  // Fetch the processors
  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      let { data } = await client.get('/admin/processors', { signal: controller.signal });
      setAvailableProcessors(data);
    })();

    // make sure we abort the request when unmounting
    return () => {
      controller.abort();
    };
  }, [id, client, store_id, forceRefresh]);

  // Fetch the payment groups with associated payment configurations
  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      let { data } = await client.get(`/admin/payment_groups/${id}`, {
        params: {
          $eager: `[
            payment_configurations.[processor]
          ]`,
        },
        paramsSerializer: (params) => qs.stringify(params, { encodeValuesOnly: true }),
        signal: controller.signal,
      });
      setPaymentGroup(data);
      setIsLoaded(true);
    })();

    // make sure we abort the request when unmounting
    return () => {
      controller.abort();
      setActivePaymentConfiguration(null);
    };
  }, [id, client, forceRefresh]);

  async function handleSavePaymentGroup(values) {
    await client.patch(`/admin/payment_groups/${id}`, values);
    refresh();
  }

  const classes = useStyles();

  function ActionsCell(props) {
    let { id } = props;
    return (
      <>
        <span
          className="link"
          onClick={() => {
            let paymentConfiguration = _.find(paymentGroup.payment_configurations, { id });
            setActivePaymentConfiguration(paymentConfiguration);
          }}
        >
          Edit
        </span>
      </>
    );
  }

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <Grid container>
      <Header heading={`Edit ${paymentGroup?.slug}`}></Header>
      <PageSection>
        <PaymentGroupsForm
          className={classes.formRoot}
          paymentGroup={paymentGroup}
          handleSavePaymentGroup={handleSavePaymentGroup}
        />
      </PageSection>

      <Grid container item xs={12}>
        <PageSection header="Payment Configurations">
          <GridComponent dataSource={paymentGroup.payment_configurations}>
            <ColumnsDirective>
              <ColumnDirective field="payment_configuration_name" headerText="payment config name" />
              <ColumnDirective field="name" headerText="processor" />
              <ColumnDirective
                field="processor.configuration"
                template={({ processor, ...props }) => (
                  <PrettyConfigCell data={processor.configuration} {...props} />
                )}
                headerText="processor configuration"
              />
              <ColumnDirective
                field="configuration"
                template={({ configuration, ...props }) => (
                  <PrettyConfigCell data={configuration} {...props} />
                )}
                headerText="payment configuration"
              />
              <ColumnDirective field="id" template={ActionsCell} headerText="Actions" />
            </ColumnsDirective>
          </GridComponent>

          <Button
            type="submit"
            onClick={() => {
              setActivePaymentConfiguration({
                name: '',
                configuration: {},
                processor: {},
                processor_id: '',
                payment_group_id: id,
              });
            }}
          >
            Add Processor to group
          </Button>
        </PageSection>
      </Grid>

      {activePaymentConfiguration && (
        <SavePaymentConfigurationModal
          open={Boolean(activePaymentConfiguration)}
          paymentConfiguration={activePaymentConfiguration}
          onSave={(newPaymentConfiguration) => {
            setActivePaymentConfiguration(null);
            refresh();
          }}
          store_id={store_id}
          availableProcessors={availableProcessors}
          dispatch={({ type }) => {
            // NOTE: we only handle one event type. This just mimicks other areas where we used the modal with a dispatcher from a reducer
            if (type === `CLOSE-payment-configuration-modal-MODAL`) {
              setActivePaymentConfiguration(null);
            } else {
              throw new Error(`${type} not implemented`);
            }
          }}
        />
      )}
    </Grid>
  );
}

// Component contained styles
const useStyles = makeStyles((theme) => ({
  selectProcessor: { width: '10em' },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  sectionHeader: {
    fontSize: '14px',
    clear: 'both',
  },

  formRoot: {
    '& .MuiFormControl-root': {
      margin: '0 4px 2px 0',
    },
  },
}));
