// Components
import { ListItem } from '@material-ui/core';
// import { Link } from 'react-router-dom';
import NavLink from 'components/NavLink';

// Hooks
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from 'hooks/useAuth';

export default function StoreLink(props) {
  let { title, showInactive, route } = props;
  const classes = useStyles();

  const { store } = useAuth();
  const url = `/store/${store?.id}`;

  if (store?.id) {
    return <NavLink to={`${url}${route}`}>{title}</NavLink>;
  } else if (showInactive) {
    return (
      <ListItem
        button
        classes={{ root: `disabled store-link ${classes.listItemRoot}` }}
        m={2}
        disableGutters={true}
      >
        {title}
      </ListItem>
    );
  }

  return null;
}

const useStyles = makeStyles((theme) => ({
  listItemRoot: {
    paddingLeft: '0.5rem',
  },
}));
