import React, { Component } from 'react';
import * as Sentry from '@sentry/react';

// Components
import { Grid } from '@material-ui/core';
import JSONPretty from 'react-json-pretty';
import Button from 'components/Button';

// Hooks
import { useHistory } from 'react-router-dom';
import { withSnackbar } from 'notistack';

// JSON pretty css
import 'react-json-pretty/themes/monikai.css';

function Fallback({ error, componentStack }) {
  const history = useHistory();

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={3} />
      <Grid item xs={6}>
        <Grid item xs={12}>
          <div className="fallback-error" style={{ textAlign: 'center' }}>
            <h2>Error Message: {error?.message}</h2>
          </div>
        </Grid>
        {typeof error?.data === 'object' && (
          <Grid item xs={12}>
            <h3>Error Data</h3>
            <JSONPretty data={error.data} />
          </Grid>
        )}
        <Grid item xs={12}>
          {error?.stack && <JSONPretty data={error.stack} />}
        </Grid>
        <Grid item xs={12}>
          <div style={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.replace('/');
              }}
            >
              Take me to the Dashboard
            </Button>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={3} />
    </Grid>
  );
}

// NOTE: this seemed to only work as a class component after integrating sentry. Not sure why.
/**
 * GeneralErrorBoundary
 * Adds a way to contain an error to any wrapped element.
 * Also displays the snackbar/toast style notification with an error message
 */
class GeneralErrorBoundary extends Component {
  render() {
    let { children, ...props } = this.props;
    return (
      <Sentry.ErrorBoundary fallback={(props) => <Fallback {...props} />} showDialog {...props}>
        {children}
      </Sentry.ErrorBoundary>
    );
  }
}

export default withSnackbar(GeneralErrorBoundary);
