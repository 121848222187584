import * as Yup from 'yup';
import { formatCurrency } from 'lib/helpers';

// Components
import { Grid, DialogContent } from '@material-ui/core';
import GeneralModal from 'components/modals/GeneralModal';
import { Formik, Form } from 'formik';
import FormButtons from 'components/form/FormButtons';
import OTextField from 'components/form/OTextField';
import TextArea from 'components/form/TextArea';

import { useAuth } from 'hooks/useAuth';

const formSchema = Yup.object().shape({
  chargeback_reason: Yup.string(),
  chargeback_reference_id: Yup.string(),
});

export default function ChargebackModal({ chargebackTransaction, refresh, dispatch, ...props }) {
  const auth = useAuth();
  const { client } = auth;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        payment_transaction_id: chargebackTransaction?.id,
        chargeback_reason: '',
        chargeback_reference_id: '',
      }}
      validationSchema={formSchema}
      onSubmit={async (values) => {
        await client.post(`/chargebacks`, values);
        refresh();
        dispatch({ type: 'CLOSE-CHARGEBACK-MODAL' });
      }}
    >
      {({ isSubmitting }) => {
        let title;
        if (isSubmitting) {
          title = `Creating chargeback`;
        } else {
          title = (
            <>
              File a {formatCurrency(chargebackTransaction?.amount)} chargeback against payment
              transaction "{chargebackTransaction?.processor_transaction_id}"{' '}
              {chargebackTransaction?.overhub_response?.processor?.name &&
                `for "${chargebackTransaction?.overhub_response?.processor?.name}"`}
            </>
          );
        }

        return (
          <GeneralModal
            title={title}
            open={Boolean(chargebackTransaction)}
            fullWidth={true}
            maxWidth="sm"
            id="CHARGEBACK"
            dispatch={dispatch}
            handleClose={() => {
              dispatch({ type: 'CLOSE-CHARGEBACK-MODAL' });
            }}
            {...props}
          >
            <DialogContent>
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <OTextField label="Reference ID" name="chargeback_reference_id" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextArea label="Chargeback Reason" name="chargeback_reason" />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <FormButtons submit_children="Save Chargeback" reset_children="Reset" />
                </Grid>
                <Grid item xs={12}></Grid>
              </Form>
            </DialogContent>
          </GeneralModal>
        );
      }}
    </Formik>
  );
}
