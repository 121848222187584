import React from 'react';

import Routes from 'components/Routes';

import { useAuth } from 'hooks/useAuth';
import { makeStyles } from '@material-ui/core/styles';
import { useApplication } from 'contexts/application.context';

export default function MainContent() {
  const { store } = useAuth();
  const { state } = useApplication();

  const drawerWidth = 200;
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      padding: '10px',
      width: drawerWidth,
      alignItems: 'center',
    },
    // necessary for content to be below app bar
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
    },
  }));
  const classes = useStyles();
  return (
    <main className={classes.content}>
      {/* // NOTE: This feature is controlled in the application.context */}
      {state.features.breadcrumbs && store && (
        <div
          style={{
            background: '#fff',
            color: '#000',
            boxShadow: '0 2px 4px rgba(0,0,0, .3)',
            padding: '5px 10px',
            margin: '5px',
            opacity: 0.8,
          }}
          className="store-context-header"
        >
          <span className="store-name-header-indicator" style={{ fontWeight: 'bold' }}>
            <span>Store: {store.name}</span>
            {state.pageTitle && (
              <>
                <span className="breadcrumb-separator">{String.fromCharCode(187)}</span>
                <span className="title-breadcrumb">{state.pageTitle}</span>
              </>
            )}
          </span>
        </div>
      )}
      <div className={classes.contentContainer}>
        <Routes />
      </div>
    </main>
  );
}
