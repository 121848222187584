import React, { useState } from 'react';
import PropTypes from 'prop-types';

// libs
import { makeStyles } from '@material-ui/core/styles';
import qs from 'qs';

// components
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

// hooks
import { useAuth } from 'hooks/useAuth';

/**
 * This is an auto-complete search component for prospects and customers.
 * @param {object} props
 * @param {function} props.onSelect The item selection callback
 * @param {number} [props.timeoutDuration] The duration in milliseconds to wait before executing the search
 */
export default function CustomerSearch({ onSelect, timeoutDuration = 600, ...props }) {
  const classes = useStyles();
  const { client } = useAuth();
  // Stores the search results from the server
  const [customers, setCustomers] = useState([]);
  // Stores the search term
  const [searchInputValue, setSearchInputValue] = useState('');
  // Stores the selected item from the search results
  const [selectedValue, setSelectedValue] = useState(null);

  // The timeout is used to debounce the search
  let timeoutHandle;

  /**
   * This is the lookup handler for the customer auto-complete input
   * @param {*} event The AutoComplete event
   * @param {*} searchValue The search term in the auto-complete input
   */
  function handleSearch(event, searchValue) {
    setSearchInputValue(searchValue);
    if (timeoutHandle) {
      clearTimeout(timeoutHandle);
    }

    timeoutHandle = setTimeout(async () => {
      // TODO@customers: Use a modifier on the Customers model to search by name, email, etc.
      const { data } = await client.get('/admin/customers', {
        params: {
          $or: [{ name: { $ilike: `%${searchValue}%` } }, { email: { $ilike: `%${searchValue}%` } }],
          type: { $in: ['customer', 'prospect'] },
          $limit: 15,
          $eager: '[contacts]',
        },
        paramsSerializer: (params) => qs.stringify(params, { encodeValuesOnly: true }),
      });

      setCustomers(data.data);
    }, timeoutDuration);
  }

  /**
   * This is the selection handler for the customer auto-complete input
   * @param {*} event The AutoComplete event
   * @param {*} selectedItem The selected customer
   */
  function handleSelection(event, selectedItem) {
    // clear the inputs and selected value of the auto-complete component
    setSearchInputValue('');
    setSelectedValue(null);

    // call the onSelect handler passed in via the props
    onSelect(selectedItem);
  }

  return (
    <Autocomplete
      id="customer-search"
      onChange={handleSelection}
      onInputChange={handleSearch}
      options={customers}
      getOptionLabel={(customer) => customer.name}
      filterOptions={(options, state) => {
        return options.filter((option) =>
          new RegExp(state.inputValue, 'im').test(`${option.name}${option.email}`)
        );
      }}
      renderOption={(customer) => {
        return (
          <>
            <div>
              <span className={classes.customerName}>{customer.name}</span>
              {customer?.email && <span className={classes.customerEmail}>{`, ${customer.email}`}</span>}
            </div>
          </>
        );
      }}
      getOptionSelected={(customer) => customer.id}
      inputValue={searchInputValue}
      value={selectedValue}
      renderInput={(params) => <TextField {...params} label="find customer" variant="outlined" />}
      clearOnEscape={true}
    />
  );
}

CustomerSearch.propTypes = {
  onSelect: PropTypes.func.isRequired,
  timeoutDuration: PropTypes.number,
};

const useStyles = makeStyles((theme) => ({
  customerName: {
    fontWeight: 'bold',
  },

  customerEmail: {
    color: '#0C2D48',
  },
}));
