import _ from 'lodash';

// Components
// TODO@syncfusion: refactor datagrid to use kendo
import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import { Grid, Paper } from '@material-ui/core';
import AppBarButton from 'components/AppBarButton';
import ConfirmModal from 'components/modals/ConfirmModal';
import Header from 'components/Header';
import SaveProcessorModal from 'components/modals/SaveProcessorModal';

// Hooks
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

function ActionsCell({ id, handleDelete, handleEdit }) {
  return (
    <div>
      <span className="link" onClick={handleEdit}>
        Edit
      </span>
      <span className="link" onClick={handleDelete}>
        Delete
      </span>
    </div>
  );
}

export default function ProcessorIndexPage(props) {
  const [processors, setProcessors] = useState([]);
  const [activeDelete, setActiveDelete] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [activeProcessor, setActiveProcessor] = useState(null);

  const location = useLocation();
  const auth = useAuth();
  const { client } = auth;

  const refresh = () => {
    setForceRefresh((value) => !value);
  };

  useEffect(() => {
    const controller = new AbortController();
    client
      .get('/admin/processors', { params: { '$sort[type]': 1 }, signal: controller.signal })
      .then(({ data }) => {
        setProcessors(data);
      });

    return () => {
      controller.abort();
    };
  }, [location.key, client, forceRefresh]);

  return (
    <Grid container>
      <Header heading="Processors">
        <AppBarButton
          variant="contained"
          color="primary"
          onClick={(event) => {
            event.preventDefault();
            setActiveProcessor(true);
          }}
        >
          New Processor
        </AppBarButton>
      </Header>
      <Grid item xs={12}>
        <Paper>
          {/* TODO@datagrid: refactor to kendo */}
          <GridComponent dataSource={processors}>
            <ColumnsDirective>
              <ColumnDirective field="name" headerText="Name" />
              <ColumnDirective field="type" headerText="Type" />
              <ColumnDirective
                headerText="Actions"
                template={(props) => (
                  <ActionsCell
                    handleDelete={() => {
                      setActiveDelete(props);
                    }}
                    handleEdit={() => {
                      setActiveProcessor(props);
                    }}
                    {...props}
                  />
                )}
              />
            </ColumnsDirective>
          </GridComponent>
        </Paper>
      </Grid>
      <ConfirmModal
        open={Boolean(activeDelete)}
        handleConfirm={async () => {
          await client.delete(`/admin/processors/${activeDelete?.id}`);
          refresh();
          setActiveDelete(null);
        }}
        handleClose={() => {
          setActiveDelete(null);
        }}
        dispatch={_.noop}
        title={activeDelete && `Delete Processor, "${activeDelete?.name}"?`}
        maxWidth="xs"
      />
      <SaveProcessorModal
        open={Boolean(activeProcessor)}
        handleClose={() => setActiveProcessor(null)}
        id={activeProcessor?.id}
        onSave={() => {
          setActiveProcessor(null);
          refresh();
        }}
      />
    </Grid>
  );
}
