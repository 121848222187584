import GeneralModal from 'components/modals/GeneralModal';
import ProcessorForm from 'pages/Processors/ProcessorForm';
import PropTypes from 'prop-types';

export default function SaveProcessorModal({ onSave = () => {}, id, ...props }) {
  return (
    <GeneralModal id="save-processor-modal" {...props}>
      <ProcessorForm id={id} onSave={onSave} />
    </GeneralModal>
  );
}

SaveProcessorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func,
};
