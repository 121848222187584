import React, { useState } from 'react';
import _ from 'lodash';
import { parseCurrency, formatCurrency, formatDateTime } from 'lib/helpers';
import classnames from 'classnames';

import { ListItem, Grid, ListItemText, List, Popover } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import ConfirmInput from 'components/ConfirmInput';
import { MoneyOff, LocalShipping } from '@material-ui/icons';
import DialogConfirm from './DialogConfirm';
import SelectFundingTransactionModal from './modals/SelectFundingTransactionModal';
import OrderItemToggleFundingLink from './OrderItemToggleFundingLink';
import Currency from 'components/Currency';

import { makeStyles } from '@material-ui/core/styles';
import { useOrder } from 'contexts/order.context';
import { useAuth } from 'hooks/useAuth';

function sortByRefundAndDate(a, b) {
  // ensures that the newly created items sort to the top
  if (_.isUndefined(a.created_at) || _.isUndefined(b.created_at)) {
    // default to now for items that do not yet have a created_at because they have not been saved
    let b_date = b.created_at ? new Date(b.created_at) : new Date();
    let a_date = a.createt_at ? new Date(a.created_at) : new Date();

    return b_date - a_date;
  }

  // refunded items sort to the bottom, then by created_at
  if (a.is_refunded === b.is_refunded) {
    return new Date(b.created_at) - new Date(a.created_at);
  }

  return !a.is_refunded ? -1 : 1;
}

export default function ProductList({ handleRemoveOrderItem, order_items, classOverrides = {} }) {
  const classes = useStyles();
  // TODO@orders: Decouple this component from the order state and refactor away
  // from directly dispatching actions. This component is nested two levels below
  // the page. It should not be responsible for dispatching actions, but instead
  // should be responsible for managing its internal state and exposing that
  // state via specific props.
  const { dispatch, state } = useOrder();
  const { client } = useAuth();

  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [detachPopoverAnchor, setDetachPopoverAnchor] = useState(null);
  const [activeOrderItem, setActiveOrderItem] = useState(null);
  const [targetDetachOrderItem, setTargetDetachOrderItem] = useState(null);
  const fundOrderItemModalOpen = Boolean(activeOrderItem);

  const handlePopover = (event) => {
    setPopoverAnchor(event.target);
  };
  const handlePopoverClose = (event) => {
    setPopoverAnchor(null);
  };

  const handleDetachPopover = (event) => {
    setDetachPopoverAnchor(event.target);
  };
  const handleDetachPopoverClose = () => {
    setDetachPopoverAnchor(null);
  };

  let ui_state = state.ui_state.state;

  const handleConfirmQty = (item, newValue) => {
    dispatch({ type: 'SET-ORDER-ITEM-QUANTITY', quantity: newValue, order_item: item });
  };
  const handleConfirmPrice = (item, newValue) => {
    dispatch({ type: 'SET-ORDER-ITEM-PRICE', order_item: item, price: newValue });
  };

  const canEdit = (order_item) => {
    return (
      ui_state === 'EDIT' && order_item?.fulfillment?.status !== 'shipped' && !order_item.is_removed
    );
  };

  const cannotEdit = (order_item) => !canEdit(order_item);
  const canTogglePaymentAttachment = state.ui_state.state === 'EDIT-PAYMENT-TRANSACTION-ATTACHMENT';

  return (
    <Grid item xs={12}>
      <List
        aria-label="draft order products"
        classes={Object.assign({ root: `${classes.fixPaperRoot}` }, classOverrides)}
      >
        {order_items.sort(sortByRefundAndDate).map((order_item, i) => {
          return (
            // FIXME: create a stable temp id for the key
            <ListItem
              disableGutters={true}
              key={`order-item-${order_item.id ?? i}`}
              classes={{
                root: classnames({
                  [classes.refunded]: order_item.is_refunded,
                  [classes.shipped]: order_item?.fulfillment?.status === 'shipped',
                  highlighted: !order_item.id,
                }),
              }}
            >
              <ListItemText>
                <Grid container item xs={12} spacing={1} alignItems="center">
                  <Grid item xs={3}>
                    {order_item.is_refunded && (
                      <span title="Refunded">
                        <MoneyOff classes={{ root: classes.rowAdornment }} />
                      </span>
                    )}
                    <span className={classes.productName}>
                      {order_item.name || order_item.variant.name}
                    </span>
                  </Grid>
                  <Grid item xs={2}>
                    <ConfirmInput
                      defaultValue={order_item.quantity}
                      type="number"
                      onConfirm={_.partial(handleConfirmQty, order_item)}
                      disabled={cannotEdit(order_item)}
                    ></ConfirmInput>
                  </Grid>
                  <Grid item xs={2}>
                    <ConfirmInput
                      defaultValue={parseCurrency(order_item.price)}
                      type="number"
                      onConfirm={_.partial(handleConfirmPrice, order_item)}
                      disabled={cannotEdit(order_item)}
                    ></ConfirmInput>
                  </Grid>
                  <Grid item xs={2}>
                    <span className={classes.productPrice}>
                      <Currency
                        amount={+order_item.price * order_item.quantity}
                        currencyCodeISO={state.order.currency_iso}
                      />
                    </span>
                  </Grid>
                  <Grid item xs={1}>
                    {order_item?.fulfillment?.status === 'shipped' && (
                      <span title={`shipped at ${formatDateTime(order_item.fulfillment.shipped_at)}`}>
                        <LocalShipping />
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={1}>
                    {canEdit(order_item) && (
                      <Delete
                        className={classes.removeProductIcon}
                        onClick={handleRemoveOrderItem(order_item)}
                      />
                    )}
                  </Grid>
                  <Grid item xs={1}>
                    <OrderItemToggleFundingLink
                      order_item={order_item}
                      handlePopover={handlePopover}
                      handlePopoverClose={handlePopoverClose}
                      handleDetachPopover={handleDetachPopover}
                      handleDetachPopoverClose={handleDetachPopoverClose}
                      setTargetDetachOrderItem={setTargetDetachOrderItem}
                      setActiveOrderItem={setActiveOrderItem}
                      canEdit={canTogglePaymentAttachment}
                    />
                  </Grid>
                </Grid>
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
      {/* Detach funding confirmation */}
      <DialogConfirm
        cancelFn={() => {
          setTargetDetachOrderItem(null);
        }}
        confirmFn={async () => {
          await client.delete(`/admin/order_items/funding_transaction/${targetDetachOrderItem.id}`);
          dispatch({ type: 'REFRESH' });
          setTargetDetachOrderItem(null);

          // NOTE: we take it out of the edit state to avoid extra button clicks, but
          // more importantly, so that we don't end up with a bad state if we detach then cancel the edit
          dispatch({ type: 'ORDER-STATE-TRANSITION', state: 'VIEW' });
        }}
        open={Boolean(targetDetachOrderItem)}
        question={`Are you sure you want to detach funding from order item "${formatCurrency(
          targetDetachOrderItem?.total_amount,
          state.order.currency_iso
        )} ${targetDetachOrderItem?.variant?.name}"?`}
        confirmText="Yes, Detach Funding"
      />
      {/* popover for attaching funding */}
      <Popover
        id="popover-helper-text-no-funding"
        anchorEl={popoverAnchor}
        onClose={handlePopoverClose}
        open={Boolean(popoverAnchor)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{ paper: classes.popoverPaper }}
        className={classes.popover}
        disableRestoreFocus
      >
        This order item is not yet attached to a funding transaction. Click to attach a transaction.
      </Popover>
      {/* Popover for detaching funding */}
      <Popover
        id="popover-helper-text-no-funding"
        anchorEl={detachPopoverAnchor}
        onClose={handlePopoverClose}
        open={Boolean(detachPopoverAnchor)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{ paper: classes.popoverPaper }}
        className={classes.popover}
        disableRestoreFocus
      >
        Detach the current funding source from this order item.
      </Popover>
      <SelectFundingTransactionModal
        open={Boolean(fundOrderItemModalOpen)}
        closeModal={() => setActiveOrderItem(null)}
        order_item={activeOrderItem}
        order_id={state.order.id}
      />
    </Grid>
  );
}

const useStyles = makeStyles({
  fixPaperRoot: {
    flexGrow: 1,
  },
  productName: {},

  removeProductIcon: {
    cursor: 'pointer',
    color: '#5A7A94',

    '&:hover': {
      color: '#346a97',
    },
  },

  refunded: {
    background: '#f7e8df',
  },

  rowAdornment: {
    fontSize: '1.8rem',
    paddingTop: '4px',
  },

  popover: {
    pointerEvents: 'none',
  },
  popoverPaper: {
    padding: '1em',
  },

  clickable: {
    cursor: 'pointer',
  },

  highlighted: {
    background: '#f9ffbf',
  },
});
