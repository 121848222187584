import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import SideBar from './components/SideBar';
import MainContent from 'components/MainContent';
import GeneralErrorBoundary from 'components/GeneralErrorBoundary';

import { ProvideAuth } from 'hooks/useAuth';
import { SnackbarProvider } from 'notistack';
import { ApplicationProvider } from 'contexts/application.context';
import { makeStyles } from '@material-ui/core/styles';
import '@progress/kendo-theme-material/dist/all.css';

import 'App.css';

const useSnackStyles = makeStyles((theme) => ({}));

const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    padding: '10px',
    width: drawerWidth,
    alignItems: 'center',
  },
}));

function App() {
  const snackStyles = useSnackStyles();
  const classes = useStyles();

  return (
    <ApplicationProvider>
      <SnackbarProvider
        maxSnack={10}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={snackStyles}
      >
        <ProvideAuth>
          <GeneralErrorBoundary>
            <div className={classes.root}>
              <Router>
                <SideBar className={classes.drawer} classes={{ paper: classes.drawerPaper }}></SideBar>
                <MainContent />
              </Router>
            </div>
          </GeneralErrorBoundary>
        </ProvideAuth>
      </SnackbarProvider>
    </ApplicationProvider>
  );
}

export default App;
