import _ from 'lodash';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { requiredMessage } from 'lib/form_helpers';

// Components
import { Formik, Form } from 'formik';
import { Grid } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import Button from 'components/Button';
import FormikSimpleSelect from 'components/form/FormikSimpleSelect';
import GeneralModal from './GeneralModal';
import JsonEditorField from 'components/form/JsonEditorField';
import OTextField from 'components/form/OTextField';
import PageSection from 'components/PageSection';

// Hooks
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from 'hooks/useAuth';

const ruleSchema = yup.object().shape({
  rule: yup.object(),
});
const ruleGroupSchema = yup.object().shape({
  type: yup.string().required(requiredMessage),
  combinator: yup.string().oneOf(['and', 'or']).required(requiredMessage),
  rules: yup.array().of(
    yup.lazy((schema) => {
      switch (schema.type) {
        case 'rule_group':
          return ruleGroupSchema;
        case 'rule':
          return ruleSchema;
        default:
          // TODO: figure out how to invalidate this
          return ruleGroupSchema;
      }
    }),
    ruleSchema
  ),
});
const processorConfigurationSchema = yup.object().shape({
  name: yup.string().required(requiredMessage),
  processor_id: yup.string().required(requiredMessage),
  configuration: yup.object().shape({
    rules: ruleGroupSchema.optional(),
  }),
});

export default function SavePaymentConfigurationModal({
  availableProcessors,
  onSave,
  paymentConfiguration,
  store_id,
  ...props
}) {
  const classes = useStyles();
  const { client } = useAuth();

  const [showHelp, setShowHelp] = useState(false);

  const initialValues = { ...paymentConfiguration };
  if (!_.isObject(paymentConfiguration.configuration.rules)) {
    initialValues.configuration.rules = {
      type: 'rule_group',
      combinator: 'and',
      rules: [],
    };
  }

  return (
    <GeneralModal
      id="payment-configuration-modal"
      content_text="Edit Payment Configuration"
      maxWidth="md"
      {...props}
    >
      {paymentConfiguration && (
        <PageSection header="Add Processors to Payment Group" noPaper={true}>
          <Formik
            initialValues={processorConfigurationSchema.cast(initialValues)}
            validationSchema={processorConfigurationSchema}
            onSubmit={async ({ id, processor, ...values }) => {
              let paymentConfigurationData = {
                ...values,
                store_id,
              };
              let data;
              if (id) {
                // remove payment_group_id since it shouldn't be changed
                paymentConfigurationData = _.omit(paymentConfigurationData, 'payment_group_id');
                ({ data } = await client.patch(
                  `/admin/payment_configurations/${id}`,
                  paymentConfigurationData
                ));
              } else {
                ({ data } = await client.post(
                  `/admin/payment_configurations`,
                  paymentConfigurationData
                ));
              }
              onSave(data);
            }}
            enableReinitialize={true}
          >
            {({ isSubmitting, values, resetForm, dirty, isValid, errors }) => {
              return (
                <Form>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={12}>
                      <h3>General</h3>
                    </Grid>
                    <Grid item xs={12}>
                      <OTextField name="name" label="name" placeholder="name" />
                    </Grid>
                    <Grid item>
                      <FormikSimpleSelect
                        id="processor_id"
                        name="processor_id"
                        label="processor"
                        items={availableProcessors.map((processor) => ({
                          label: processor.name,
                          value: processor.id,
                        }))}
                        classes={{ root: classes.selectProcessor }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container item xs={12}>
                    <Grid item xs={12}>
                      <h3>Configuration</h3>
                    </Grid>
                    <Grid item xs={12}>
                      <span
                        onClick={() => {
                          setShowHelp((value) => !value);
                        }}
                        title="show/hide help"
                        style={{ cursor: 'pointer' }}
                      >
                        <HelpOutline />
                      </span>
                      {/* TODO: make the help section look better */}
                      <div style={{ display: showHelp ? 'block' : 'none' }}>
                        Rules must have a type. The outermost rule must be a rule group.
                        <pre>
                          <code>
                            {`{
                            "type": "rule_group",
                            "combinator": "and", // also takes "or"
                            "rules": [ // this is an array of rules or rule groups
                          {
                              "type": "rule",
                              // this is only used for a rule
                              "rule": {}
                              }
                            ]
                          }`}

                            {`\n// the rules are mongo based query operators on the following fields`}

                            {`{
                            processor_id: {type: 'string' },
                            processor_monthly_cap: numberPrecisionTwo,
                            chargeback_amount: numberPrecisionTwo,
                            chargeback_count: { type: 'number' },
                            process_amount: numberPrecisionTwo,
                            process_count: { type: 'number' },
                            refund_amount: numberPrecisionTwo,
                            refund_count: { type: 'number' },
                            range: { type: 'string' },
                            monthly_cap_percentage: { type: 'number', precision: 4 },
                            declined_amount: numberPrecisionTwo,
                            declined_count: numberPrecisionTwo,
                            pending_amount: numberPrecisionTwo,
                            pending_count: numberPrecisionTwo
                          }`}
                          </code>
                        </pre>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <h3>Rules</h3>
                      <JsonEditorField id="payment_configuration_rules" name="configuration.rules" />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      color="secondary"
                      onClick={(event) => {
                        event.preventDefault();
                        resetForm();
                      }}
                      disabled={!dirty || isSubmitting}
                    >
                      Reset
                    </Button>
                    <Button type="submit" disabled={!dirty || isSubmitting || !isValid}>
                      Save Configuration
                    </Button>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </PageSection>
      )}
    </GeneralModal>
  );
}

SavePaymentConfigurationModal.propTypes = {
  availableProcessors: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  paymentConfiguration: PropTypes.object,
};

// Component contained styles
const useStyles = makeStyles((theme) => ({
  formRoot: {
    '& .MuiFormControl-root': {
      margin: '0 4px 2px 0',
    },
  },
}));
