import { createTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import blueGrey from '@material-ui/core/colors/blueGrey';

const defaultHeaderStyle = {
  fontWeight: 600,
};

// A custom theme for this app
const theme = createTheme({
  spacing: 2,
  palette: {
    primary: blue,
    //   // primary: {
    //   //   main: '#1DA57A',
    //   // },
    //   background: {
    //     default: '#f0f2f5',
    //   },
    listItem: {
      background: '#f7f7f7',
    },

    subHeader: {
      background: {
        primary: blueGrey['50'],
      },
    },
  },
  typography: {
    button: {
      fontSize: '12px',
    },
    h1: { ...defaultHeaderStyle },
    h2: { ...defaultHeaderStyle },
    h3: { ...defaultHeaderStyle },
    h4: { ...defaultHeaderStyle },
    h5: { ...defaultHeaderStyle },
    h6: { ...defaultHeaderStyle },
    htmlFontSize: 10,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 450,
    fontWeightBold: 600,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Noto Sans"',
      '"Droid Sans"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ].join(','),
  },

  props: {
    MuiTextField: {
      // NOTE: cannot use dense until we fix the issue with selects not lining up
      // margin: 'dense',
      fullWidth: true,
    },

    MuiSelect: {
      autoWidth: true,
    },
  },

  overrides: {
    // the header component's title bar
    MuiToolbar: {
      dense: {
        minHeight: '28px',
      },

      root: {
        backgroundColor: '#c0d5e4',
        color: '#191f23',
      },
    },

    // NOTE: this affects switches too
    MuiButtonBase: {
      root: {
        margin: '4px 1px',
      },
    },

    // NOTE: this overrides the MuiButtonBase margin to fix the style of the switch
    MuiSwitch: {
      switchBase: {
        margin: 0,
      },
    },

    // enable these for smaller inputs
    MuiInputBase: {
      root: {
        fontSize: '12px',
        padding: '2px',
      },
    },

    MuiInput: {
      root: {
        padding: '2px',
      },
    },

    MuiTypography: {
      overline: {
        fontWeight: 'bold',
      },
    },
  },
});

export default theme;
