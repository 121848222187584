// Components
import { Grid, Typography } from '@material-ui/core';

// Hooks
import { makeStyles } from '@material-ui/core/styles';

export default function CustomerDetail({ customer }) {
  const classes = useStyles();
  if (!customer) {
    return '';
  }

  return (
    <Grid container item xs={12}>
      <Typography variant="h6">{customer.name}</Typography>
      <div className={classes.customerProp}>
        <label className="label email">Email</label>
        <span className="value email">{customer.email}</span>
      </div>
    </Grid>
  );
}

const useStyles = makeStyles({
  customerProp: {
    marginLeft: '20px',
    paddingTop: '8px',

    '& label': {
      fontWeight: 'bold',
      paddingRight: '1rem',
    },
  },
});
