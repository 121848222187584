import React from 'react';
import * as yup from 'yup';
import S from 'lib/stringomatic';
import _ from 'lodash';

import { Button, Grid } from '@material-ui/core';
import { Formik, Form } from 'formik';
import Header from 'components/Header';
import OTextField from 'components/form/OTextField';
import PageSection from 'components/PageSection';

// Hooks
import { useHistory } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

// exists to format the input name to a friendlier string
const requiredMessage = ({ path }) => {
  return `${S.humanize(path)} is Required`;
};

const formSchema = yup.object().shape({
  name: yup.string().required(requiredMessage),
  post_sale_expiration: yup.number().required(requiredMessage),
  order_confirmation_email_delay: yup.number().required(requiredMessage),
});

export default function StoresNewPage() {
  const { client } = useAuth();
  const history = useHistory();

  return (
    <Grid container>
      <Header heading="Create a new Store" />
      <PageSection>
        <Grid item container>
          <Formik
            initialValues={{
              name: '',
              post_sale_expiration: 20,
              order_confirmation_email_delay: 60000,
            }}
            onSubmit={async ({ name, ...configuration }) => {
              let storeData = {
                name,
                configuration,
              };
              await client.post(`/admin/stores`, storeData);
              history.push(`/stores`);
            }}
            validationSchema={formSchema}
          >
            {({ isSubmitting, touched }) => {
              return (
                <Form>
                  <Grid container>
                    <Grid item xs={12}>
                      <OTextField name="name" label="Name" />
                    </Grid>
                    <h3>Orders</h3>
                    <Grid item xs={12}>
                      <OTextField name="post_sale_expiration" label="Post Sale Expiration Delay" />
                    </Grid>

                    <h3>Notifications</h3>
                    <Grid item xs={12}>
                      <OTextField
                        name="order_confirmation_email_delay"
                        label="Order Confirmation Email Delay"
                      />
                    </Grid>

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting || _.isEmpty(touched)}
                    >
                      Save
                    </Button>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </PageSection>
    </Grid>
  );
}
