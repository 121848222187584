import { useState } from 'react';
import _ from 'lodash';
import inputChange from 'lib/input_change_handler';

// Components
import { Grid, TextField } from '@material-ui/core';
import Button from 'components/Button';

// Hooks
import { useAuth } from 'hooks/useAuth';
import { useHistory, useLocation } from 'react-router-dom';

export default function LoginPage() {
  const [loginForm, setLoginForm] = useState({ password: '', email: '' });
  const auth = useAuth();
  const history = useHistory();
  let location = useLocation();

  async function login({ email, password }) {
    await auth.signin(email, password);

    let { from } = location.state || { from: { pathname: '/' } };
    history.replace(from);
  }

  const handleInput = _.partial(inputChange, loginForm, setLoginForm);

  return (
    <div>
      <h2>Login</h2>
      <Grid container>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            login(loginForm);
          }}
        >
          <Grid item xs={12}>
            <TextField label="email" id="email-login" onChange={handleInput('email')} />
            <TextField
              label="password"
              id="password-login"
              type="password"
              onChange={handleInput('password')}
            />
            <Button color="primary" type="submit" variant="contained" className="login-button">
              Login
            </Button>
          </Grid>
        </form>
      </Grid>
    </div>
  );
}
